import React from 'react';
import moment from 'moment';

import AreaDetail from './AreaDetail';

const Trip = ({
  onSelectPhoto,
  trip
}) => {
  const dateStr = moment(trip.date, 'YYYY-MM-DD').format('MMM D, YYYY');

  return (
    <AreaDetail
      areStationsVisitedNew
      numStationsTotal={trip.numTotal}
      numStationsVisited={trip.numNew}
      onSelectPhoto={onSelectPhoto}
      photos={trip.photos}
      statistics={[
        `${Math.round(trip.distance * 10) / 10} mi`
      ]}
      subtitle={trip.description}
      title={dateStr}
    />
  );
};

export default Trip;
