export const BREAKPOINTS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop'
};

export const COLOR_VISITED = '4db546';
export const COLOR_UNVISITED = '4b96f3';

export const VIEWS = {
  ABOUT: 'about',
  NEIGHBORHOOD: 'neighborhood',
  NEIGHBORHOODS: 'neighborhoods',
  PHOTO: 'photo',
  PHOTOS: 'photos',
  ROUTE: 'route',
  ROUTE_MARKING: 'route-marking',
  STATION: 'station',
  STATS: 'stats',
  TIME_TRAVEL: 'time-travel',
  TRIP: 'trip',
  TRIP_LIST: 'trip-list'
};
