import React, { useState, useRef } from 'react';
import DirectionsBike from '@mui/icons-material/DirectionsBike';
import Drawer from '@mui/material/Drawer';
import Equalizer from '@mui/icons-material/Equalizer';
import History from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import TimelineIcon from '@mui/icons-material/Timeline';
import './InfoPane.css';

import { BREAKPOINTS, VIEWS } from './constants';
import { useBreakpoint } from './helpers';

import AboutContent from './AboutContent';
import AboutDialog from './AboutDialog';
import BackButton from './BackButton';
import CancelButton from './CancelButton';
import MenuButton from './MenuButton';
import Neighborhood from './Neighborhood';
import Neighborhoods from './Neighborhoods';
import Photo from './Photo';
import Photos from './Photos';
import Progress from './Progress';
import RouteMarking from './RouteMarking';
import StationInfo from './StationInfo';
import Stats from './Stats';
import TimeTravel from './TimeTravel';
import TimeTravelPane from './TimeTravelPane';
import Trip from './Trip';
import TripList from './TripList';

const InfoPane = React.forwardRef((
  {
    activeTravelTimestamp,
    isNeighborhoodDataLoading,
    justActiveNeighborhood,
    markedRoute,
    neighborhoods,
    onClearSelectedStation,
    onSelectPhoto,
    onViewActivate,
    onViewDeactivate,
    onViewReplace,
    setActiveTravelTimestamp,
    stations,
    timeline,
    totalNumViableStations,
    trips,
    viewStack,
    visitedStations
  },
  ref
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const activeView = viewStack.length > 0 ? viewStack[viewStack.length - 1] : null;
  const breakpoint = useBreakpoint();

  const handleClickMenuButton = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleClickMenuItem = view => {
    handleCloseMenu();
    setTimeout(() => {
      onViewActivate(view);
    }, 225);
  };

  const handleClickNeighborhood = (id) => {
    onViewReplace(VIEWS.NEIGHBORHOODS, { id });
    setTimeout(() => {
      onViewActivate(VIEWS.NEIGHBORHOOD, { id });
    }, 225);
  };

  const handleClickStation = stationId => {
    onViewActivate(VIEWS.STATION, {
      id: stationId,
      marker: stations.lookup[stationId].marker,
      moveCenter: true
    });
  };

  const handleClickTrip = trip => {
    onViewReplace(VIEWS.TRIP_LIST, trip);
    setTimeout(() => {
      onViewActivate(VIEWS.TRIP, trip);
    }, 225);
  };

  return (
    <>
      {!!activeView && activeView.view === VIEWS.PHOTO ? (
        <div className="InfoPane" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.PHOTO)} />
          </div>
          <div className="InfoPane-content">
            <Photo
              photo={activeView.payload.photo}
              trip={activeView.payload.trip}
            />
          </div>
        </div>
      ) : !!activeView && activeView.view === VIEWS.ROUTE_MARKING ? (
        <>
          <div className="InfoPane" ref={ref}>
            <div className="InfoPane-header">
              <CancelButton onClick={() => onViewDeactivate(VIEWS.ROUTE_MARKING)} />
            </div>
          </div>
          <RouteMarking
            markedRoute={markedRoute}
            stations={stations}
          />
        </>
      ) : !!activeView && activeView.view === VIEWS.STATION ? (
        <div className="InfoPane" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={onClearSelectedStation} />
          </div>
          <div className="InfoPane-content">
            <StationInfo
              station={stations.lookup[activeView.payload.id]}
              trips={trips}
            />
          </div>
        </div>
      ) : !!activeView && activeView.view === VIEWS.TRIP ? (
        <div className="InfoPane" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.TRIP_LIST)} />
          </div>
          <div className="InfoPane-content">
            <Trip
              onSelectPhoto={onSelectPhoto}
              trip={activeView.payload}
              trips={trips}
            />
          </div>
        </div>
      ) : !!activeView && activeView.view === VIEWS.NEIGHBORHOOD ? (
        <div className="InfoPane" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.NEIGHBORHOOD)} />
          </div>
          <div className="InfoPane-content">
            <Neighborhood
              activeNeighborhood={activeView.payload}
              onSelectPhoto={onSelectPhoto}
              neighborhoods={neighborhoods}
            />
          </div>
        </div>
      ) : !!activeView && activeView.view === VIEWS.NEIGHBORHOODS ? (
        <div className="InfoPane InfoPane--scrollable" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.NEIGHBORHOODS)} />
          </div>
          <div className="InfoPane-content">
            <Neighborhoods
              justActiveNeighborhood={justActiveNeighborhood}
              onSelectNeighborhood={handleClickNeighborhood}
              neighborhoods={neighborhoods}
            />
          </div>
        </div>
      ) : breakpoint === BREAKPOINTS.DESKTOP && !!activeView && activeView.view === VIEWS.PHOTOS ? (
        <div className="InfoPane InfoPane--scrollable" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.PHOTOS)} />
          </div>
          <div className="InfoPane-content">
            <Photos
              onClickStation={handleClickStation}
              onClickTrip={handleClickTrip}
              onViewActivate={onViewActivate}
              stations={stations}
              trips={trips}
              {...activeView.payload}
            />
          </div>
        </div>
      ) : breakpoint === BREAKPOINTS.DESKTOP && !!activeView && activeView.view === VIEWS.STATS ? (
        <div className="InfoPane InfoPane--scrollable" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.STATS)} />
          </div>
          <div className="InfoPane-content">
            <Stats
              onClickStation={handleClickStation}
              onClickTrip={handleClickTrip}
              stations={stations}
              trips={trips}
            />
          </div>
        </div>
      ) : !!activeView && activeView.view === VIEWS.TIME_TRAVEL ? (
        <>
          <div className="InfoPane" ref={ref}>
            <div className="InfoPane-header">
              <BackButton onClick={() => onViewDeactivate(VIEWS.TIME_TRAVEL)} />
            </div>
            <div className="InfoPane-content">
              <TimeTravelPane
                activeTravelTimestamp={activeTravelTimestamp}
                timeline={timeline}
              />
            </div>
          </div>
          <TimeTravel
            activeTravelTimestamp={activeTravelTimestamp}
            setActiveTravelTimestamp={setActiveTravelTimestamp}
            stations={stations}
            timeline={timeline}
            trips={trips}
          />
        </>
      ) : breakpoint === BREAKPOINTS.DESKTOP && !!activeView && activeView.view === VIEWS.TRIP_LIST ? (
        <div className="InfoPane InfoPane--scrollable" ref={ref}>
          <div className="InfoPane-header">
            <BackButton onClick={() => onViewDeactivate(VIEWS.TRIP_LIST)} />
          </div>
          <div className="InfoPane-content">
            <TripList
              activeTrip={activeView.payload}
              onClickTrip={handleClickTrip}
              stations={stations}
              trips={trips}
            />
          </div>
        </div>
      ) : (
        <div className="InfoPane" ref={ref}>
          <div className="InfoPane-header">
            <h1>
              Andy and Eric Bike Boston
            </h1>
            {breakpoint === BREAKPOINTS.MOBILE && (
              <MenuButton onClick={handleClickMenuButton} />
            )}
          </div>
          <div className="InfoPane-content">
            <div className="InfoPane-contentMain">
              <Progress
                numStations={totalNumViableStations}
                numVisited={Object.keys(visitedStations).length}
                shouldAnimate
              />
              {breakpoint === BREAKPOINTS.MOBILE && (
                <Drawer anchor="bottom" open={isMenuOpen} onClose={handleCloseMenu}>
                  <MenuList>
                    {/* <MenuItem onClick={() => handleClickMenuItem(VIEWS.ABOUT)}>
                      <ListItemIcon><InfoIcon /></ListItemIcon>
                      About the Journey
                    </MenuItem> */}
                    <MenuItem
                      disabled={isNeighborhoodDataLoading}
                      onClick={() => handleClickMenuItem(VIEWS.NEIGHBORHOODS)}
                    >
                      <ListItemIcon><LocationCityIcon /></ListItemIcon>
                      Neighborhoods
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenuItem(VIEWS.PHOTOS)}>
                      <ListItemIcon><PhotoCamera /></ListItemIcon>
                      Photos
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenuItem(VIEWS.ROUTE_MARKING)}>
                      <ListItemIcon><TimelineIcon /></ListItemIcon>
                      Plan a Route
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenuItem(VIEWS.TRIP_LIST)}>
                      <ListItemIcon><DirectionsBike /></ListItemIcon>
                      Trips
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenuItem(VIEWS.STATS)}>
                      <ListItemIcon><Equalizer /></ListItemIcon>
                      Stats
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenuItem(VIEWS.TIME_TRAVEL)}>
                      <ListItemIcon><History /></ListItemIcon>
                      Time Travel
                    </MenuItem>
                  </MenuList>
                </Drawer>
              )}
              {breakpoint === BREAKPOINTS.DESKTOP && (
                <MenuList className="InfoPane-menu">
                  {/* <MenuItem onClick={() => handleClickMenuItem(VIEWS.ABOUT)}>
                    <ListItemIcon><InfoIcon /></ListItemIcon>
                    About the Journey
                  </MenuItem> */}
                  <MenuItem
                    disabled={isNeighborhoodDataLoading}
                    onClick={() => handleClickMenuItem(VIEWS.NEIGHBORHOODS)}
                  >
                    <ListItemIcon><LocationCityIcon /></ListItemIcon>
                    Neighborhoods
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMenuItem(VIEWS.PHOTOS)}>
                    <ListItemIcon><PhotoCamera /></ListItemIcon>
                    Photos
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMenuItem(VIEWS.ROUTE_MARKING)}>
                    <ListItemIcon><TimelineIcon /></ListItemIcon>
                    Plan a Route
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMenuItem(VIEWS.TRIP_LIST)}>
                    <ListItemIcon><DirectionsBike /></ListItemIcon>
                    Trips
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMenuItem(VIEWS.STATS)}>
                    <ListItemIcon><Equalizer /></ListItemIcon>
                    Stats
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMenuItem(VIEWS.TIME_TRAVEL)}>
                    <ListItemIcon><History /></ListItemIcon>
                    Time Travel
                  </MenuItem>
                </MenuList>
              )}
            </div>
          </div>
        </div>
      )}
      {/* breakpoint === BREAKPOINTS.MOBILE && (
        <Drawer
          anchor="bottom"
          open={!!activeView && activeView.view === VIEWS.ABOUT}
          onClose={() => onViewDeactivate(VIEWS.ABOUT)}
        >
          <AboutContent />
        </Drawer>
      ) */}
      {breakpoint === BREAKPOINTS.MOBILE && (
        <Drawer
          anchor="bottom"
          open={!!activeView && activeView.view === VIEWS.PHOTOS}
          onClose={() => onViewDeactivate(VIEWS.PHOTOS)}
        >
          <div className="InfoPane-header InfoPane-header--inDrawer">
            <CancelButton label="Close" onClick={() => onViewDeactivate(VIEWS.PHOTOS)} />
          </div>
          <Photos
            onClickStation={handleClickStation}
            onClickTrip={handleClickTrip}
            stations={stations}
            trips={trips}
          />
        </Drawer>
      )}
      {breakpoint === BREAKPOINTS.MOBILE && (
        <Drawer
          anchor="bottom"
          open={!!activeView && activeView.view === VIEWS.STATS}
          onClose={() => onViewDeactivate(VIEWS.STATS)}
        >
          <div className="InfoPane-header InfoPane-header--inDrawer">
            <CancelButton label="Close" onClick={() => onViewDeactivate(VIEWS.STATS)} />
          </div>
          <Stats
            onClickStation={handleClickStation}
            onClickTrip={handleClickTrip}
            stations={stations}
            trips={trips}
          />
        </Drawer>
      )}
      {breakpoint === BREAKPOINTS.MOBILE && (
        <Drawer
          anchor="bottom"
          open={!!activeView && activeView.view === VIEWS.TRIP_LIST}
          onClose={() => onViewDeactivate(VIEWS.TRIP_LIST)}
        >
          <div className="InfoPane-header InfoPane-header--inDrawer">
            <CancelButton label="Close" onClick={() => onViewDeactivate(VIEWS.TRIP_LIST)} />
          </div>
          <TripList
            activeTrip={activeView && activeView.payload}
            onClickTrip={handleClickTrip}
            stations={stations}
            trips={trips}
          />
        </Drawer>
      )}
      {/* breakpoint === BREAKPOINTS.DESKTOP && (
        <AboutDialog
          open={!!activeView && activeView.view === VIEWS.ABOUT}
          onClose={() => onViewDeactivate(VIEWS.ABOUT)}
        >
          <AboutContent />
        </AboutDialog>
      ) */}
    </>
  );
});

export default InfoPane;
