import React, { useEffect, useRef } from 'react';
import './Neighborhoods.css';

import NeighborhoodButton from './NeighborhoodButton';

const Neighborhoods = ({
  justActiveNeighborhood,
  onSelectNeighborhood,
  neighborhoods
}) => {
  const scrollRef = useRef(null);

  const neighborhoodsWithStations = neighborhoods.list.filter(neighborhood => neighborhood.numStations > 0);

  const completeNeighborhoods = neighborhoodsWithStations.filter(neighborhood => neighborhood.progress === 100);
  completeNeighborhoods.sort((a, b) => a.name.localeCompare(b.name));

  const incompleteNeighborhoods = neighborhoodsWithStations.filter(neighborhood => neighborhood.progress !== 100);
  incompleteNeighborhoods.sort((a, b) => {
    if (a.numUnvisitedStations > b.numUnvisitedStations) {
      return -1;
    } else if (a.numUnvisitedStations < b.numUnvisitedStations) {
      return 1;
    } else if (a.progress < b.progress) {
      return -1;
    } else if (a.progress > b.progress) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

  useEffect(() => {
    if (justActiveNeighborhood) {
      setTimeout(() => {
        if (scrollRef && scrollRef.current) {
          scrollRef.current.scrollIntoView({
            block: 'start',
          });
        }
      }, 0);
    }
  }, [scrollRef]);

  return (
    <div className="Neighborhoods">
      {!!incompleteNeighborhoods && incompleteNeighborhoods.length > 0 && (
        <div className="Neighborhoods-list">
          {incompleteNeighborhoods.map(neighborhood => {
            const isActive = justActiveNeighborhood && justActiveNeighborhood.id === neighborhood.id;
            return (
              <NeighborhoodButton
                {...neighborhood}
                key={neighborhood.id}
                onClick={() => onSelectNeighborhood(neighborhood.id)}
                ref={isActive ? scrollRef : null}
              />
            );
          })}
        </div>
      )}
      {!!completeNeighborhoods && completeNeighborhoods.length > 0 && (
        <div className="Neighborhoods-list">
          {completeNeighborhoods.map(neighborhood => {
            const isActive = justActiveNeighborhood && justActiveNeighborhood.id === neighborhood.id;
            return (
              <NeighborhoodButton
                {...neighborhood}
                key={neighborhood.id}
                onClick={() => onSelectNeighborhood(neighborhood.id)}
                ref={isActive ? scrollRef : null}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Neighborhoods;
