import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const MenuButton = ({onClick}) => {
  return (
    <IconButton onClick={onClick}>
      <MenuIcon />
    </IconButton>
  );
};

export default MenuButton;
