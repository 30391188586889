import React from 'react';

import AreaDetail from './AreaDetail';

const Neighborhood = ({
  activeNeighborhood,
  onSelectPhoto,
  neighborhoods
}) => {
  const { id } = activeNeighborhood;
  const neighborhood = neighborhoods.lookup[id];

  return (
    <AreaDetail
      numStationsRemaining={neighborhood.numUnvisitedStations ? neighborhood.numUnvisitedStations : null}
      numStationsTotal={neighborhood.numStations}
      numStationsUnviable={neighborhood.numUnviableStations}
      numStationsVisited={neighborhood.numVisitedStations}
      onSelectPhoto={onSelectPhoto}
      photos={neighborhood.photos}
      statistics={[
        `${neighborhood.progress}% complete`
      ]}
      title={neighborhood.name}
    />
  );
};

export default Neighborhood;
