export default [
  {
    "id": "3",
    "name": "Colleges of the Fenway - Fenway at Avenue Louis Pasteur",
    "lat": 42.34011512249236,
    "long": -71.10061883926392,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/colleges-of-the-fenway_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311884100,
    "oldId": "B32006",
    "hashId": "f83464e4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83464e4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "4",
    "name": "Tremont St at E Berkeley St",
    "lat": 42.345392,
    "long": -71.069616,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/tremont-st-at-e-berkeley-st_2018-06-24.jpg"
      }
    ],
    "firstSeen": 1314681780,
    "oldId": "C32000",
    "hashId": "f834658f-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834658f-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "5",
    "name": "Northeastern University - North Parking Lot",
    "lat": 42.341814,
    "long": -71.090179,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/northeastern-north-lot_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1311870300,
    "oldId": "B32012",
    "hashId": "f834662c-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834662c-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "6",
    "name": "Cambridge St at Joy St",
    "lat": 42.36125721646537,
    "long": -71.06528744101524,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/cambridge-st-at-joy-st_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1311883740,
    "oldId": "D32000",
    "hashId": "f83466c6-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83466c6-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "7",
    "name": "Fan Pier",
    "lat": 42.3533905070523,
    "long": -71.04457139968872,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/fan-pier_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1316798040,
    "oldId": "A32000",
    "hashId": "f8346768-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346768-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "8",
    "name": "Union Square - Brighton Ave at Cambridge St",
    "lat": 42.353334,
    "long": -71.137313,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/union-square-allston_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1311878040,
    "oldId": "A32001",
    "hashId": "f8346803-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346803-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "9",
    "name": "Commonwealth Ave at Agganis Way",
    "lat": 42.35169201885973,
    "long": -71.11903488636017,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/commonwealth-ave-at-amory-st_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1311882900,
    "oldId": "A32002",
    "hashId": "f8346894-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346894-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "10",
    "name": "B.U. Central - 725 Comm. Ave.",
    "lat": 42.350406,
    "long": -71.108279,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/bu-central-725-comm-ave_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1311870420,
    "oldId": "A32003",
    "hashId": "f8346a3c-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346a3c-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "11",
    "name": "Longwood Ave at Binney St",
    "lat": 42.338629,
    "long": -71.1065,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/longwood-ave-at-binney-st_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311912720,
    "oldId": "A32004",
    "hashId": "f8346ad7-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346ad7-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "12",
    "name": "Ruggles T Stop - Columbus Ave at Melnea Cass Blvd",
    "lat": 42.33624444796878,
    "long": -71.08798563480377,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/ruggles-t-stop_2018-06-24.jpg"
      }
    ],
    "firstSeen": 1311884460,
    "oldId": "B32002",
    "hashId": "f8346b6f-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346b6f-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "13",
    "name": "Boston Medical Center - E Concord St at Harrison Ave",
    "lat": 42.33639915372498,
    "long": -71.07306711393903,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/e-concord-st-at-harrison-ave_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1311880860,
    "oldId": "C32002",
    "hashId": "f8346c01-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346c01-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "14",
    "name": "HMS/HSPH - Avenue Louis Pasteur at Longwood Ave",
    "lat": 42.3374174845973,
    "long": -71.10286116600037,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/avenue-louis-pasteur-at-longwood-ave_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311873000,
    "oldId": "B32003",
    "hashId": "f8346c96-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346c96-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "15",
    "name": "Brighton Mills - 370 Western Ave",
    "lat": 42.3615457081725,
    "long": -71.13776206970215,
    "photos": [
      {
        "date": "2020-05-17",
        "photo": "photos/brighton-mills_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1311893760,
    "oldId": "A32005",
    "hashId": "f8346d23-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346d23-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "16",
    "name": "Back Bay T Stop - Dartmouth St at Stuart St",
    "lat": 42.34807412317439,
    "long": -71.07657015323639,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/back-bay-t-stop_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311871800,
    "oldId": "C32003",
    "hashId": "f8346dbd-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346dbd-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "17",
    "name": "Soldiers Field Park - 111 Western Ave",
    "lat": 42.364263440638524,
    "long": -71.1182756971175,
    "photos": [
      {
        "date": "2020-05-17",
        "photo": "photos/soldiers-field-park_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1311910320,
    "oldId": "A32006",
    "hashId": "f8346e51-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346e51-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "19",
    "name": "Park Dr at Buswell St",
    "lat": 42.347241,
    "long": -71.105301,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/park-dr-at-buswell-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1344622620,
    "oldId": "A32008",
    "hashId": "f8346f6f-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8346f6f-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "20",
    "name": "Aquarium T Stop - 200 Atlantic Ave",
    "lat": 42.35991176110118,
    "long": -71.0514298081398,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/aquarium-t-stop_2017-08-13.jpg"
      },
      {
        "date": "2018-03-30",
        "photo": "photos/aquarium-t-stop_2018-03-30.jpg"
      }
    ],
    "firstSeen": 1311869520,
    "oldId": "B32004",
    "hashId": "f8347002-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347002-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "21",
    "name": "Prudential Center - 101 Huntington Ave",
    "lat": 42.34652003998412,
    "long": -71.08065776545118,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/prudential-center_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311883380,
    "oldId": "C32007",
    "hashId": "f834708e-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834708e-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "22",
    "name": "South Station - 700 Atlantic Ave",
    "lat": 42.352175,
    "long": -71.055547,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/south-station_2017-05-28.jpg"
      }
    ],
    "firstSeen": 1311868800,
    "oldId": "A32010",
    "hashId": "f8347118-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347118-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "23",
    "name": "Boston City Hall - 28 State St",
    "lat": 42.35892,
    "long": -71.057629,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/28-state-st_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1311862860,
    "oldId": "B32008",
    "hashId": "f83471a1-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83471a1-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "24",
    "name": "Seaport Square - Seaport Blvd at Northern Ave",
    "lat": 42.35148193460858,
    "long": -71.04436084628105,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/seaport-square_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1311881040,
    "oldId": "B32007",
    "hashId": "f834723b-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834723b-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "25",
    "name": "South End Library - Tremont St at W Newton St",
    "lat": 42.341332,
    "long": -71.076847,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/south-end-library_2018-06-24.jpg"
      },
      {
        "date": "2020-05-31",
        "photo": "photos/south-end-library-tremont-st-at-w-newton-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1311870360,
    "oldId": "A32009",
    "hashId": "f83472d3-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83472d3-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "26",
    "name": "Washington St at Waltham St",
    "lat": 42.341574718737476,
    "long": -71.06890439987183,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/washington-st-at-waltham-st_2019-06-01.jpg"
      },
      {
        "date": "2020-06-07",
        "photo": "photos/washington-st-at-waltham-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1311876360,
    "oldId": "D32002",
    "hashId": "f8347361-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347361-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "27",
    "name": "Roxbury Crossing T Stop - Columbus Ave at Tremont St",
    "lat": 42.331184,
    "long": -71.095171,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/roxbury-crossing-t-stop_2018-06-24.jpg"
      }
    ],
    "firstSeen": 1311876420,
    "oldId": "C32001",
    "hashId": "f83473ea-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83473ea-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "29",
    "name": "Innovation Lab - 125 Western Ave at Batten Way",
    "lat": 42.36371276003581,
    "long": -71.12459778785706,
    "photos": [
      {
        "date": "2020-05-17",
        "photo": "photos/innovation-lab_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1314661920,
    "oldId": "A32011",
    "hashId": "f8347a8b-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347a8b-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "30",
    "name": "Brigham Circle - Francis St at Huntington Ave",
    "lat": 42.33462893055976,
    "long": -71.10407917767589,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/brigham-circle_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311886440,
    "oldId": "B32013",
    "hashId": "f8347b1e-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347b1e-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "31",
    "name": "Seaport Hotel - Congress St at Seaport Ln",
    "lat": 42.34881026188269,
    "long": -71.04167744055303,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/seaport-hotel_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1311871080,
    "oldId": "B32014",
    "hashId": "f8347ba8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347ba8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "32",
    "name": "Landmark Center - Brookline Ave at Park Dr",
    "lat": 42.343690557197476,
    "long": -71.10235286881107,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/landmark-center-brookline-ave-at-park-dr_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1311873780,
    "oldId": "B32015",
    "hashId": "f8347c41-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347c41-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "33",
    "name": "Kenmore Square",
    "lat": 42.348706,
    "long": -71.097009,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/kenmore-square_2018-09-08.jpg"
      },
      {
        "date": "2020-05-31",
        "photo": "photos/kenmore-square_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1311877740,
    "oldId": "B32010",
    "hashId": "f8347ccd-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347ccd-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "35",
    "name": "Arch St at Franklin St",
    "lat": 42.355335019412564,
    "long": -71.0582291708306,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/arch-st-at-franklin-st_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1311883260,
    "oldId": "D32004",
    "hashId": "f8347ded-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347ded-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "36",
    "name": "Copley Square - Dartmouth St at Boylston St",
    "lat": 42.34992828230057,
    "long": -71.07739206866427,
    "photos": [
      {
        "date": "2018-06-23",
        "photo": "photos/copley-square_2018-06-23.jpg"
      },
      {
        "date": "2020-02-23",
        "photo": "photos/copley-square_2020-02-23.jpg"
      }
    ],
    "firstSeen": 1311869880,
    "oldId": "D32005",
    "hashId": "f8347e78-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347e78-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "37",
    "name": "New Balance - 20 Guest St",
    "lat": 42.35732921867706,
    "long": -71.14673539996147,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/new-balance_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1311970380,
    "oldId": "D32001",
    "hashId": "f8347f09-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8347f09-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "39",
    "name": "Washington St at Rutland St",
    "lat": 42.338514601785995,
    "long": -71.07404083013535,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/washington-st-at-rutland-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1311871080,
    "oldId": "C32006",
    "hashId": "f8348021-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348021-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "40",
    "name": "Lewis Wharf at Atlantic Ave",
    "lat": 42.363871,
    "long": -71.050877,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/lewis-wharf-at-atlantic-ave_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1311869520,
    "oldId": "D32006",
    "hashId": "f83480a9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83480a9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "41",
    "name": "Packard's Corner - Commonwealth Ave at Brighton Ave",
    "lat": 42.352261,
    "long": -71.123831,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/packards-corner_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1311871380,
    "oldId": "A32012",
    "hashId": "f8348136-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348136-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "hashId": "f83481c5-0de8-11e7-991c-3863bb43a7d0",
    "id": "42",
    "name": "Boylston St at Arlington St",
    "lat": 42.35258524849636,
    "long": -71.06770277023315,
    "photos": [
      {
        "date": "2021-05-01",
        "photo": "photos/boylston-st-at-arlington-st_2021-05-01.jpg"
      }
    ],
    "discovered": 1619965196820,
    "firstSeen": 1619038131,
    "newId": "f83481c5-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "43",
    "name": "Rowes Wharf at Atlantic Ave",
    "lat": 42.357143,
    "long": -71.050699,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/rowes-wharf_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1311873480,
    "oldId": "D32008",
    "hashId": "f834824e-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834824e-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "44",
    "name": "Congress St at Boston City Hall",
    "lat": 42.36041774637169,
    "long": -71.05752243543975,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/congress-st-at-boston-city-hall_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1311869820,
    "oldId": "D32009",
    "hashId": "f83482d2-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83482d2-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "46",
    "name": "Christian Science Plaza - Massachusetts Ave at Westland Ave",
    "lat": 42.3436658245146,
    "long": -71.08582377433777,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/christian-science-plaza_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311870300,
    "oldId": "B32005",
    "hashId": "f83483f3-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83483f3-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "47",
    "name": "Cross St at Hanover St",
    "lat": 42.362811,
    "long": -71.056067,
    "photos": [
      {
        "date": "2018-03-30",
        "photo": "photos/cross-st-at-hanover-st_2018-03-30.jpg"
      }
    ],
    "firstSeen": 1311868500,
    "oldId": "D32010",
    "hashId": "f8348481-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348481-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "48",
    "name": "Post Office Square",
    "lat": 42.35585435522005,
    "long": -71.05459745998814,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/post-office-square_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1311870120,
    "oldId": "D32012",
    "hashId": "f8348509-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348509-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "49",
    "name": "Stuart St at Charles St",
    "lat": 42.351146,
    "long": -71.066289,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/stuart-st-at-charles-st_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1311869880,
    "oldId": "D32011",
    "hashId": "f8348598-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348598-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "50",
    "name": "Boylston St at Berkeley St",
    "lat": 42.3511419825475,
    "long": -71.07329249382019,
    "photos": [
      {
        "date": "2018-06-23",
        "photo": "photos/boylston-st-at-berkeley-st_2018-06-23.jpg"
      }
    ],
    "firstSeen": 1312055640,
    "oldId": "D32013",
    "hashId": "f8348622-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348622-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "51",
    "name": "Washington St at Lenox St",
    "lat": 42.3350989929096,
    "long": -71.07903778553009,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/washington-st-at-lenox-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1312150080,
    "oldId": "C32005",
    "hashId": "f83486ab-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83486ab-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "52",
    "name": "Newbury St at Hereford St",
    "lat": 42.348717,
    "long": -71.085954,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/newbury-st-at-hereford-st_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1311959580,
    "oldId": "B32000",
    "hashId": "f8348719-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348719-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "53",
    "name": "Beacon St at Massachusetts Ave",
    "lat": 42.35082680669095,
    "long": -71.0898108780384,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/beacon-st-at-mass-ave_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1312071600,
    "oldId": "B32016",
    "hashId": "f8348781-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348781-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "54",
    "name": "Tremont St at West St",
    "lat": 42.354979,
    "long": -71.063348,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/tremont-st-at-west-st_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1314832860,
    "oldId": "D32014",
    "hashId": "f83487f0-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83487f0-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "55",
    "name": "Boylston St at Massachusetts Ave",
    "lat": 42.34740620525209,
    "long": -71.08678414955648,
    "photos": [
      {
        "date": "2018-06-23",
        "photo": "photos/boylston-st-at-massachusetts-ave_2018-06-23.jpg"
      }
    ],
    "firstSeen": 1313781000,
    "oldId": "B32018",
    "hashId": "f8348857-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348857-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "56",
    "name": "Dudley Square - Bolling Building",
    "lat": 42.329842993518085,
    "long": -71.08386572459494,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/dudley-square_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1313818320,
    "oldId": "B32017",
    "hashId": "f83488be-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83488be-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "57",
    "name": "Massachusetts Ave at Columbus Ave",
    "lat": 42.340814,
    "long": -71.0816197,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/massachusetts-ave-at-columbus-ave_2018-06-24.jpg"
      }
    ],
    "firstSeen": 1313801040,
    "oldId": "C32004",
    "hashId": "f8348924-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348924-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "58",
    "name": "Mugar Way at Beacon St",
    "lat": 42.355536284897084,
    "long": -71.07286870479584,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/beacon-st-arlington-st_2017-05-28.jpg"
      }
    ],
    "firstSeen": 1316869800,
    "oldId": "D32017",
    "hashId": "f8348995-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348995-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "59",
    "name": "Chinatown Gate Plaza",
    "lat": 42.351356,
    "long": -71.059367,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/chinatown-gate-plaza_2019-05-17.jpg"
      },
      {
        "date": "2020-09-06",
        "photo": "photos/chinatown-gate-plaza_2020-09-06.jpg"
      },
      {
        "date": "2020-12-28",
        "photo": "photos/chinatown-gate-plaza_2020-12-28.jpg"
      },
      {
        "date": "2021-05-01",
        "photo": "photos/chinatown-gate-plaza_2021-05-01.jpg"
      }
    ],
    "firstSeen": 1314822480,
    "oldId": "D32015",
    "hashId": "f83489ff-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83489ff-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "60",
    "name": "Charles Circle - Charles St at Cambridge St",
    "lat": 42.36079296858265,
    "long": -71.07118962337152,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/charles-circle-charles-st-at-cambridge-st_2017-05-28.jpg"
      },
      {
        "date": "2021-03-14",
        "photo": "photos/charles-circle_2021-03-14.jpg"
      }
    ],
    "firstSeen": 1316793840,
    "oldId": "D32016",
    "hashId": "f8348a68-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8348a68-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "61",
    "name": "Boylston St at Fairfield St",
    "lat": 42.348803822833936,
    "long": -71.08236908912659,
    "photos": [
      {
        "date": "2018-06-23",
        "photo": "photos/boylston-st-at-fairfield-st_2018-06-23.jpg"
      }
    ],
    "firstSeen": 1316926500,
    "oldId": "C32008",
    "hashId": "f8349070-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349070-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "63",
    "name": "Dorchester Ave at Gillette Park",
    "lat": 42.344040510016356,
    "long": -71.05737626552582,
    "photos": [
      {
        "date": "2019-05-26",
        "photo": "photos/dorchester-ave-at-gillette-park_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1319131500,
    "oldId": "C32009",
    "hashId": "f834916a-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834916a-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "64",
    "name": "Congress St at Sleeper St",
    "lat": 42.351004495946704,
    "long": -71.04930013418198,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/congress-st-at-sleeper-st_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1319824080,
    "oldId": "C32010",
    "hashId": "f83491d4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83491d4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "65",
    "name": "Boston Convention and Exhibition Center - Summer St at West Side Dr",
    "lat": 42.347763454147454,
    "long": -71.0453599691391,
    "photos": [
      {
        "date": "2018-03-18",
        "photo": "photos/boston-convention-and-exhibition-center_2018-03-18.jpg"
      },
      {
        "date": "2019-05-26",
        "photo": "photos/boston-convention-and-exhibition-center_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1331834820,
    "oldId": "D32018",
    "hashId": "f834923d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834923d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "66",
    "name": "Commonwealth Ave at Griggs St",
    "lat": 42.34922469338298,
    "long": -71.13275302578586,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/allston-green-district_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1344043080,
    "oldId": "A32017",
    "hashId": "f83492b4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83492b4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "67",
    "name": "MIT at Mass Ave / Amherst St",
    "lat": 42.3581,
    "long": -71.093198,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/mit-at-mass-ave-amherst-st_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1344014160,
    "oldId": "M32006",
    "hashId": "f83493c0-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83493c0-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "68",
    "name": "Central Square at Mass Ave / Essex St",
    "lat": 42.36507,
    "long": -71.1031,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/central-square-at-mass-ave-essex-st_2018-09-08.jpg"
      },
      {
        "date": "2020-06-14",
        "photo": "photos/central-square-at-mass-ave-essex-st_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1343769420,
    "oldId": "M32011",
    "hashId": "f8349440-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349440-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "69",
    "name": "Coolidge Corner - Beacon St at Centre St",
    "lat": 42.341598,
    "long": -71.123338,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/coolidge-corner_2018-03-31.jpg"
      },
      {
        "date": "2020-05-17",
        "photo": "photos/coolidge-corner-beacon-st-at-centre-st_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1343960160,
    "oldId": "K32001",
    "hashId": "f83494b4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83494b4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "70",
    "name": "Harvard Kennedy School at Bennett St / Eliot St",
    "lat": 42.3722168027866,
    "long": -71.12188071012497,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-kennedy-school-at-bennett-st_2019-05-11.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/harvard-kennedy-school-at-bennett-st-eliot-st_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1343752320,
    "oldId": "M32016",
    "hashId": "f834952a-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834952a-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "71",
    "name": "Conway Park - Somerville Avenue",
    "lat": 42.383638948222654,
    "long": -71.10853672027588,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/conway-park-somerville-ave_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1344968820,
    "oldId": "S32004",
    "hashId": "f8349599-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349599-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "72",
    "name": "One Broadway / Kendall Sq at Main St / 3rd St",
    "lat": 42.36224178650923,
    "long": -71.0831107199192,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/one-broadway-kendall-sq-at-main-st-third-st_2017-05-28.jpg"
      },
      {
        "date": "2020-01-11",
        "photo": "photos/one-broadway-kendall-sq-at-main-st-third-st_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1344973320,
    "oldId": "M32003",
    "hashId": "f8349672-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349672-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "73",
    "name": "Harvard Square at Brattle St / Eliot St",
    "lat": 42.373328798874056,
    "long": -71.12098601080106,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-square-at-brattle-st-eliot-st_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1343668920,
    "oldId": "M32017",
    "hashId": "f8349745-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349745-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "74",
    "name": "Harvard Square at Mass Ave/ Dunster",
    "lat": 42.373268,
    "long": -71.118579,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/harvard-square-at-mass-ave-dunster_2018-06-30.jpg"
      },
      {
        "date": "2020-12-31",
        "photo": "photos/harvard-square-at-mass-ave-dunster_2020-12-31.jpg"
      }
    ],
    "firstSeen": 1343678160,
    "oldId": "M32018",
    "hashId": "f83497b9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83497b9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "75",
    "name": "Lafayette Square at Mass Ave / Main St / Columbia St",
    "lat": 42.36346469304347,
    "long": -71.10057324171066,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/lafayette-square-at-mass-ave-main-st_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1343684880,
    "oldId": "M32009",
    "hashId": "f8349824-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349824-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "76",
    "name": "Central Sq Post Office / Cambridge City Hall at Mass Ave / Pleasant St",
    "lat": 42.366426,
    "long": -71.105495,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/central-sq-po-cambridge-city-hall_2018-09-08.jpg"
      },
      {
        "date": "2019-05-11",
        "photo": "photos/central-sq-po-cambridge-city-hall_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1343675640,
    "oldId": "M32012",
    "hashId": "f8349b20-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349b20-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "77",
    "name": "Somerville City Hall",
    "lat": 42.386844,
    "long": -71.09812,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/somerville-city-hall_2019-06-08.jpg"
      },
      {
        "date": "2020-01-11",
        "photo": "photos/somerville-city-hall_2020-01-11.jpg"
      },
      {
        "date": "2020-05-03",
        "photo": "photos/somerville-city-hall_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1343930640,
    "oldId": "S32001",
    "hashId": "f8349ba9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349ba9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "78",
    "name": "Union Square - Somerville",
    "lat": 42.37964789967527,
    "long": -71.09540475672475,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/union-square-somerville_2018-09-08.jpg"
      },
      {
        "date": "2020-12-31",
        "photo": "photos/union-square-somerville_2020-12-31.jpg"
      }
    ],
    "firstSeen": 1343906940,
    "oldId": "S32002",
    "hashId": "f8349c69-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349c69-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "79",
    "name": "Beacon St at Washington / Kirkland",
    "lat": 42.37875351212593,
    "long": -71.10707223415375,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/beacon-st-at-washington-st-kirkland-st_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1343884140,
    "oldId": "S32003",
    "hashId": "f8349d6e-0de8-11e7-991c-3863bb43a7d0",
    "isLegacy": true,
    "newId": "f8349d6e-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "80",
    "name": "MIT Stata Center at Vassar St / Main St",
    "lat": 42.3621312344991,
    "long": -71.09115600585936,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/mit-stata-center-at-vassar-st-main-st_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1344351420,
    "oldId": "M32005",
    "hashId": "f8349de4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349de4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "81",
    "name": "Chinatown T Stop",
    "lat": 42.352409,
    "long": -71.062679,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/boylston-st-at-washington-st_2017-05-28.jpg"
      },
      {
        "date": "2018-03-31",
        "photo": "photos/boylston-st-at-washington-st_2018-03-31.jpg"
      },
      {
        "date": "2018-06-23",
        "photo": "photos/chinatown-t-stop_2018-06-23.jpg"
      },
      {
        "date": "2021-03-14",
        "photo": "photos/chinatown-t-stop_2021-03-14.jpg"
      }
    ],
    "firstSeen": 1344016140,
    "oldId": "D32019",
    "hashId": "f8349e56-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349e56-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "82",
    "name": "Beacon St at Tappan St",
    "lat": 42.33826680058037,
    "long": -71.13894682028331,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/washington-square_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1344055380,
    "oldId": "K32002",
    "hashId": "f8349ed6-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8349ed6-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "84",
    "name": "CambridgeSide Galleria - CambridgeSide PL at Land Blvd",
    "lat": 42.367074071490926,
    "long": -71.07679277658461,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/cambridgeside-galleria-cambridgeside-pl-at-land-blvd_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1344609600,
    "oldId": "M32019",
    "hashId": "f834a1de-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a1de-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "85",
    "name": "Spaulding Rehabilitation Hospital - Charlestown Navy Yard",
    "lat": 42.378338,
    "long": -71.048927,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/spaulding-rehabilitation-hospital_2017-08-13.jpg"
      },
      {
        "date": "2019-07-28",
        "photo": "photos/spaulding-rehabilitation-hospital_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1344698700,
    "oldId": "D32023",
    "hashId": "f834a2a8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a2a8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "86",
    "name": "Brookline Village - Station Street at MBTA",
    "lat": 42.33274392304887,
    "long": -71.11626680949485,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/brookline-village-pearl-st-at-mbta_2019-06-12.jpg"
      },
      {
        "date": "2020-02-23",
        "photo": "photos/brookline-village-pearl-st-at-mbta_2020-02-23.jpg"
      }
    ],
    "firstSeen": 1344983340,
    "oldId": "K32003",
    "hashId": "f834a32d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a32d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "87",
    "name": "Harvard University Housing - 115 Putnam Ave at Peabody Terrace",
    "lat": 42.366621,
    "long": -71.114214,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-university-housing-putnam-ave-at-peabody-terrace_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1347054660,
    "oldId": "M32014",
    "hashId": "f834a3a0-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a3a0-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "88",
    "name": "Inman Square at Vellucci Plaza / Hampshire St",
    "lat": 42.374035,
    "long": -71.101427,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/inman-square-at-vellucci-plaza_2018-09-08.jpg"
      }
    ],
    "isLegacy": true,
    "firstSeen": 1346278500,
    "oldId": "M32010"
  },
  {
    "id": "89",
    "name": "Harvard Law School at Mass Ave / Jarvis St",
    "lat": 42.379011,
    "long": -71.119945,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-law-school-at-mass-ave-jarvis-st_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1347042060,
    "oldId": "M32020",
    "hashId": "f834a67b-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a67b-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "90",
    "name": "Lechmere Station at Cambridge St / First St",
    "lat": 42.370677,
    "long": -71.076529,
    "photos": [
      {
        "date": "2018-03-03",
        "photo": "photos/lechmere-station_2018-03-03.jpg"
      }
    ],
    "firstSeen": 1346265960,
    "oldId": "M32001",
    "hashId": "f834a6f9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a6f9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "91",
    "name": "One Kendall Square at Hampshire St / Portland St",
    "lat": 42.366277,
    "long": -71.09169,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/one-kendall-square-at-hampshire-st-portland-st_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1346262900,
    "oldId": "M32002",
    "hashId": "f834a7d1-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a7d1-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "92",
    "name": "University of Massachusetts Boston - Campus Center",
    "lat": 42.31218918475612,
    "long": -71.03648586203235,
    "photos": [
      {
        "date": "2019-10-20",
        "photo": "photos/umass-boston_2019-10-20.jpg"
      },
      {
        "date": "2020-06-07",
        "photo": "photos/umass-boston_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1347123060,
    "oldId": "C32014",
    "hashId": "f834a838-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a838-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "93",
    "name": "JFK/UMass T Stop",
    "lat": 42.32033973515723,
    "long": -71.05118036270142,
    "photos": [
      {
        "date": "2019-10-20",
        "photo": "photos/jfk-umass-t-stop_2019-10-20.jpg"
      }
    ],
    "firstSeen": 1347122340,
    "oldId": "C32013",
    "hashId": "f834a8a0-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a8a0-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "94",
    "name": "Main St at Austin St",
    "lat": 42.375603,
    "long": -71.064608,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/main-st-at-austin-st_2017-08-13.jpg"
      },
      {
        "date": "2019-07-28",
        "photo": "photos/main-st-at-austin-st_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1347396900,
    "oldId": "D32020",
    "hashId": "f834a959-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834a959-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "95",
    "name": "Cambridge St - at Columbia St / Webster Ave",
    "lat": 42.372969,
    "long": -71.094445,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/cambridge-st-at-columbia-st_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1347402240,
    "oldId": "M32007",
    "hashId": "f834ab92-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ab92-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "96",
    "name": "Cambridge Main Library at Broadway / Trowbridge St",
    "lat": 42.373379,
    "long": -71.111075,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/cambridge-main-library-at-broadway-trowbridge-st_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1347398940,
    "oldId": "M32013",
    "hashId": "f834abfd-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834abfd-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "97",
    "name": "Harvard University River Houses at DeWolfe St / Cowperthwaite St",
    "lat": 42.369239074859216,
    "long": -71.11687034368515,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-river-houses-at-dewolfe-st_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1347399180,
    "oldId": "M32038",
    "hashId": "f834b101-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b101-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "98",
    "name": "Warren St at Chelsea St",
    "lat": 42.371848,
    "long": -71.060292,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/warren-st-at-chelsea-st_2017-08-13.jpg"
      },
      {
        "date": "2019-07-28",
        "photo": "photos/warren-st-at-chelsea-st_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1347393000,
    "oldId": "D32021",
    "hashId": "f834b183-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b183-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "99",
    "name": "Wilson Square",
    "lat": 42.38567578752914,
    "long": -71.11412136298895,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/wilson-square_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1349197200,
    "oldId": "S32005",
    "hashId": "f834b1ed-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b1ed-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "100",
    "name": "Davis Square",
    "lat": 42.396969,
    "long": -71.123024,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/davis-square_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1349196240,
    "oldId": "S32006",
    "hashId": "f834b42d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b42d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "101",
    "name": "Ball Sq",
    "lat": 42.39936816059618,
    "long": -71.11111521723615,
    "photos": [
      {
        "date": "2020-05-03",
        "photo": "photos/ball-sq_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1349319960,
    "oldId": "S32007",
    "hashId": "f834b49d-0de8-11e7-991c-3863bb43a7d0",
    "isLegacy": true,
    "newId": "f834b49d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "102",
    "name": "Powder House Circle - Nathan Tufts Park",
    "lat": 42.400877,
    "long": -71.116772,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/powder-house-circle_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1349320560,
    "oldId": "S32008",
    "hashId": "f834b50a-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b50a-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "103",
    "name": "JFK Crossing at Harvard St. / Thorndike St.",
    "lat": 42.346551241753566,
    "long": -71.1283321143128,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/jfk-crossing-at-harvard-st-thorndike-st_2019-05-25.jpg"
      },
      {
        "date": "2020-05-17",
        "photo": "photos/jfk-crossing-at-harvard-st-thorndike-st_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1365276600,
    "oldId": "K32004",
    "hashId": "f834b5d9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b5d9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "104",
    "name": "Harvard University Radcliffe Quadrangle at Shepard St / Garden St",
    "lat": 42.380287,
    "long": -71.125107,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-radcliffe-quadrangle-shepard-st-garden-st_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1349985660,
    "oldId": "M32024",
    "hashId": "f834b652-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b652-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "105",
    "name": "Lower Cambridgeport at Magazine St / Riverside Rd",
    "lat": 42.357218503176526,
    "long": -71.1138716340065,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/lower-cambridgeport-at-magazine-st-riverside-rd_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1350075840,
    "oldId": "M32022",
    "hashId": "f834b8b3-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b8b3-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "106",
    "name": "Dudley Town Common - Mt Pleasant Ave at Blue Hill Ave",
    "lat": 42.325333,
    "long": -71.075354,
    "photos": [
      {
        "date": "2020-07-24",
        "photo": "photos/dudley-town-common_2020-07-24.jpg"
      }
    ],
    "firstSeen": 1351211580,
    "oldId": "C32015",
    "hashId": "f834b928-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b928-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "107",
    "name": "Ames St at Main St",
    "lat": 42.3625,
    "long": -71.08822,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/main-st-at-ames-st_2017-05-28.jpg"
      }
    ],
    "firstSeen": 1351023360,
    "oldId": "M32037",
    "hashId": "f834b99d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834b99d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "108",
    "name": "Harvard University / SEAS Cruft-Pierce Halls at 29 Oxford St",
    "lat": 42.377945,
    "long": -71.116865,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-university-seas-cruft-pierce-halls_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1351270980,
    "oldId": "M32023",
    "hashId": "f834ba08-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ba08-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "109",
    "name": "West End Park",
    "lat": 42.36590788445084,
    "long": -71.06446668505669,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/west-end-park_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1352556720,
    "oldId": "D32022",
    "hashId": "f834bbdd-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834bbdd-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "110",
    "name": "Harvard University Gund Hall at Quincy St / Kirkland St",
    "lat": 42.376369,
    "long": -71.114025,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/harvard-university-gund-hall-quincy-kirkland_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1365105960,
    "oldId": "M32021",
    "hashId": "f834c5c9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834c5c9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "111",
    "name": "Packard Ave at Powderhouse Blvd",
    "lat": 42.40449,
    "long": -71.123413,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/packard-ave-at-powderhouse-blvd_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1373499720,
    "oldId": "S32009",
    "hashId": "f834c6f5-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834c6f5-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "112",
    "name": "Clarendon Hill at Broadway",
    "lat": 42.406302,
    "long": -71.132446,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/clarendon-hill-at-broadway_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1373470680,
    "oldId": "S32010",
    "hashId": "f834c7a9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834c7a9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "113",
    "name": "Andrew T Stop - Dorchester Ave at Dexter St",
    "lat": 42.33073332585453,
    "long": -71.05699850837482,
    "photos": [
      {
        "date": "2019-10-20",
        "photo": "photos/andrew-t-stop_2019-10-20.jpg"
      }
    ],
    "firstSeen": 1370309760,
    "oldId": "C32012",
    "hashId": "f834ca55-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ca55-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "114",
    "name": "Teele Square",
    "lat": 42.40087950159558,
    "long": -71.12590793383788,
    "firstSeen": 1373504880,
    "oldId": "S32011",
    "hashId": "f834caf2-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834caf2-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "115",
    "name": "Porter Square Station",
    "lat": 42.387995,
    "long": -71.119084,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/porter-square-station_2019-05-11.jpg"
      },
      {
        "date": "2023-04-08",
        "photo": "photos/porter-square-station_2023-04-08.jpg"
      }
    ],
    "firstSeen": 1371490980,
    "oldId": "M32029",
    "hashId": "f834cb89-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834cb89-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "116",
    "name": "359 Broadway - Broadway at Fayette Street",
    "lat": 42.370803,
    "long": -71.104412,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/359-broadway-at-fayette-st_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1372795920,
    "oldId": "M32026",
    "hashId": "f834cf45-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834cf45-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "117",
    "name": "Binney St / Sixth St",
    "lat": 42.36608797388682,
    "long": -71.08633603909765,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/binney-st-sixth-st_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1376085060,
    "oldId": "M32027",
    "hashId": "f834cfd8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834cfd8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "118",
    "name": "Linear Park - Mass. Ave. at Cameron Ave. ",
    "lat": 42.397828,
    "long": -71.130516,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/linear-park-mass-ave-at-cameron-ave_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1372793820,
    "oldId": "M32025",
    "hashId": "f834d064-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834d064-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "119",
    "name": "South Boston Library - 646 E Broadway",
    "lat": 42.335741,
    "long": -71.03877,
    "photos": [
      {
        "date": "2018-03-18",
        "photo": "photos/south-boston-library_2018-03-18.jpg"
      }
    ],
    "firstSeen": 1380233640,
    "oldId": "C32017",
    "hashId": "f834d260-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834d260-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "120",
    "name": "Beacon St at Charles St",
    "lat": 42.356052,
    "long": -71.069849,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/charles-st-at-beacon-st_2017-05-28.jpg"
      }
    ],
    "firstSeen": 1380047640,
    "oldId": "D32024",
    "hashId": "f834d34b-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834d34b-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "121",
    "name": "W Broadway at Dorchester St",
    "lat": 42.335958979433094,
    "long": -71.04622899561036,
    "photos": [
      {
        "date": "2019-05-26",
        "photo": "photos/w-broadway-at-dorchester-st_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1380108480,
    "oldId": "C32016",
    "hashId": "f834db9b-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834db9b-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "122",
    "name": "Burlington Ave at Brookline Ave",
    "lat": 42.345733,
    "long": -71.100694,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/burlington-ave-at-brookline-ave_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1380057120,
    "oldId": "B32020",
    "hashId": "f834dc46-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834dc46-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "124",
    "name": "Curtis Hall - South St at Centre St",
    "lat": 42.309054,
    "long": -71.11543,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/curtis-hall_2019-06-12.jpg"
      }
    ],
    "firstSeen": 1380152460,
    "oldId": "E32001",
    "hashId": "f834dedf-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834dedf-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "125",
    "name": "Hyde Square - Barbara St at Centre St",
    "lat": 42.32176525723952,
    "long": -71.10984160567114,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/hyde-square_2019-06-12.jpg"
      }
    ],
    "firstSeen": 1380141360,
    "oldId": "E32003",
    "hashId": "f834e1b2-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834e1b2-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "126",
    "name": "Egleston Square - Atherton St at Washington St",
    "lat": 42.315692,
    "long": -71.098634,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/egleston-square_2019-06-12.jpg"
      },
      {
        "date": "2020-06-27",
        "photo": "photos/egleston-square_2020-06-27.jpg"
      }
    ],
    "firstSeen": 1380140100,
    "oldId": "E32004",
    "hashId": "f834e244-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834e244-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "128",
    "name": "E Cottage St at Columbia Rd",
    "lat": 42.320561,
    "long": -71.06198,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/e-cottage-st-at-columbia-rd_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1380323940,
    "oldId": "C32018",
    "hashId": "f834e534-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834e534-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "129",
    "name": "Hayes Square - Vine St at Moulton St",
    "lat": 42.377022,
    "long": -71.056605,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/hayes-square-at-vine-st_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1380231600,
    "oldId": "D32026",
    "hashId": "f834e75d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834e75d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "130",
    "name": "Uphams Corner",
    "lat": 42.31727474327453,
    "long": -71.06537003967787,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/uphams-corner_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1380997080,
    "oldId": "C32019",
    "hashId": "f834ea45-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ea45-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "131",
    "name": "Jackson Square T Stop",
    "lat": 42.32293117195949,
    "long": -71.10014140605927,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/jackson-square-t-stop_2018-06-24.jpg"
      },
      {
        "date": "2020-08-01",
        "photo": "photos/jackson-square-t-stop_2020-08-01.jpg"
      }
    ],
    "firstSeen": 1381356660,
    "oldId": "E32006",
    "hashId": "f834ec42-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ec42-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "133",
    "name": "Green Street T Stop - Green St at Amory St",
    "lat": 42.310579,
    "long": -71.107341,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/green-street-t-stop_2018-06-24.jpg"
      },
      {
        "date": "2019-06-12",
        "photo": "photos/green-street-t-stop_2019-06-12.jpg"
      }
    ],
    "firstSeen": 1381362660,
    "oldId": "E32005",
    "hashId": "f834f036-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834f036-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "135",
    "name": "ID Building East",
    "lat": 42.344827,
    "long": -71.028664,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/id-building-east_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1396447200,
    "oldId": "C32020",
    "hashId": "f834f6a3-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834f6a3-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "136",
    "name": "ID Building West",
    "lat": 42.344796,
    "long": -71.031614,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/id-building-west_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1396445400,
    "oldId": "C32021",
    "hashId": "f834f814-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834f814-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "137",
    "name": "Magoun Square at Trum Field",
    "lat": 42.39778339961249,
    "long": -71.10594004465383,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/magoun-square-at-trum-field_2018-06-30.jpg"
      },
      {
        "date": "2020-05-03",
        "photo": "photos/magoun-square-at-trum-field_2020-05-03.jpg"
      },
      {
        "date": "2023-01-29",
        "photo": "photos/magoun-square-at-trum-field_2023-01-29.jpg"
      }
    ],
    "firstSeen": 1396492740,
    "oldId": "S32013",
    "hashId": "f834f8ca-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834f8ca-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "138",
    "name": "Newmarket Square T Stop - Massachusetts Ave at Newmarket Square",
    "lat": 42.326599,
    "long": -71.066498,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/newmarket-square-t-stop_2020-06-07.jpg"
      },
      {
        "date": "2020-07-24",
        "photo": "photos/newmarket-square-t-stop_2020-07-24.jpg"
      }
    ],
    "firstSeen": 1396990440,
    "oldId": "C32022",
    "hashId": "f834f948-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834f948-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "139",
    "name": "Dana Park",
    "lat": 42.361780439606044,
    "long": -71.10809952020645,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/dana-park_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1397155860,
    "oldId": "M32030",
    "hashId": "f834fb66-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834fb66-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "140",
    "name": "Danehy Park",
    "lat": 42.388966,
    "long": -71.132788,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/danehy-park_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1397246280,
    "oldId": "M32031",
    "hashId": "f834fc57-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834fc57-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "141",
    "name": "Kendall Street",
    "lat": 42.363560158429884,
    "long": -71.08216792345047,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/kendall-street_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1400083680,
    "oldId": "M32032",
    "hashId": "f834fcbe-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834fcbe-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "142",
    "name": "Alewife Station at Russell Field",
    "lat": 42.396105,
    "long": -71.139459,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/alewife-station-at-russell-field_2018-06-30.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/alewife-station-at-russell-field_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1405113360,
    "oldId": "M32033",
    "hashId": "f834fd36-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834fd36-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "143",
    "name": "EF - North Point Park",
    "lat": 42.369885,
    "long": -71.069957,
    "photos": [
      {
        "date": "2018-03-03",
        "photo": "photos/ef-north-point-park_2018-03-03.jpg"
      }
    ],
    "firstSeen": 1403705520,
    "oldId": "M32034",
    "hashId": "f834fda2-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834fda2-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "144",
    "name": "Rogers St & Land Blvd",
    "lat": 42.366446738529454,
    "long": -71.07654601335526,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/rogers-st-at-land-blvd_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1533849583,
    "oldId": "M32035",
    "hashId": "f834fede-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834fede-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "145",
    "name": "Rindge Avenue - O'Neill Library",
    "lat": 42.392766,
    "long": -71.129042,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/rindge-avenue-oneill-library_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1409520480,
    "oldId": "M32036",
    "hashId": "f834ff5a-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ff5a-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "146",
    "name": "Murphy Skating Rink - 1880 Day Blvd",
    "lat": 42.336448,
    "long": -71.023739,
    "photos": [
      {
        "date": "2018-03-18",
        "photo": "photos/day-boulevard_2018-03-18.jpg"
      }
    ],
    "firstSeen": 1397261940,
    "oldId": "C32023",
    "hashId": "f834ffc9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f834ffc9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "149",
    "name": "175 N Harvard St",
    "lat": 42.364474565180565,
    "long": -71.12840831285212,
    "photos": [
      {
        "date": "2020-05-17",
        "photo": "photos/175-n-harvard-st_2020-05-17.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/175-n-harvard-st_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1397770560,
    "oldId": "A32019",
    "hashId": "f8350129-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350129-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "150",
    "name": "State Street at Channel Center",
    "lat": 42.344137,
    "long": -71.052608,
    "photos": [
      {
        "date": "2019-05-26",
        "photo": "photos/state-street-at-channel-center_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1407518760,
    "oldId": "C32024",
    "hashId": "f8350199-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350199-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "151",
    "name": "Surface Rd at India St",
    "lat": 42.358155,
    "long": -71.052163,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/surface-rd-at-india-st_2017-08-13.jpg"
      },
      {
        "date": "2018-03-30",
        "photo": "photos/surface-rd-at-india-st_2018-03-30.jpg"
      }
    ],
    "firstSeen": 1429272614,
    "oldId": "A32013",
    "hashId": "f8350204-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350204-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "152",
    "name": "Ink Block - Harrison Ave at Herald St",
    "lat": 42.345901,
    "long": -71.063187,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/ink-block_2018-06-24.jpg"
      }
    ],
    "firstSeen": 1429275740,
    "oldId": "C32025",
    "hashId": "f835027c-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835027c-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "156",
    "name": "Somerville Hospital",
    "lat": 42.390446216041305,
    "long": -71.10856622457504,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/somerville-hospital_2019-06-08.jpg"
      },
      {
        "date": "2020-05-03",
        "photo": "photos/somerville-hospital_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1533079967,
    "oldId": "S32020",
    "hashId": "f835043d-0de8-11e7-991c-3863bb43a7d0",
    "isLegacy": true,
    "newId": "f835043d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "157",
    "name": "Seaport Blvd at Sleeper St",
    "lat": 42.35317809186252,
    "long": -71.04817356923377,
    "photos": [
      {
        "date": "2018-03-30",
        "photo": "photos/seaport-blvd-at-sleeper-st_2018-03-30.jpg"
      },
      {
        "date": "2019-05-17",
        "photo": "photos/seaport-blvd-at-sleeper-st_2019-05-17.jpg"
      },
      {
        "date": "2020-06-07",
        "photo": "photos/seaport-blvd-at-sleeper-st_2020-06-07.jpg"
      },
      {
        "date": "2020-06-14",
        "photo": "photos/seaport-blvd-at-sleeper-st_2020-06-14.jpg"
      },
      {
        "date": "2021-01-16",
        "photo": "photos/seaport-blvd-at-sleeper-st_2021-01-16.jpg"
      }
    ],
    "firstSeen": 1509029086,
    "oldId": "C32036",
    "hashId": "f8350a84-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350a84-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "159",
    "name": "S Huntington Ave at Heath St",
    "lat": 42.32760387040573,
    "long": -71.11089169979095,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/s-huntington-ave-at-heath-st_2019-06-12.jpg"
      }
    ],
    "firstSeen": 1444941469,
    "oldId": "E32007",
    "hashId": "f8350b76-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350b76-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "160",
    "name": "Wentworth Institute of Technology - Huntington Ave at Vancouver St",
    "lat": 42.33758601097239,
    "long": -71.09627097845076,
    "photos": [
      {
        "date": "2018-03-31",
        "photo": "photos/wentworth-institute-of-technology_2018-03-31.jpg"
      }
    ],
    "firstSeen": 1443831047,
    "oldId": "B32021",
    "hashId": "f8350be5-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350be5-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "161",
    "name": "W Broadway at D St",
    "lat": 42.3391085,
    "long": -71.0514432,
    "photos": [
      {
        "date": "2019-05-26",
        "photo": "photos/w-broadway-at-d-st_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1443823245,
    "oldId": "C32029",
    "hashId": "f8350c56-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350c56-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "162",
    "name": "Franklin Park - Seaver St at Humboldt Ave",
    "lat": 42.309796,
    "long": -71.092225,
    "photos": [
      {
        "date": "2020-06-27",
        "photo": "photos/seaver-st-at-humboldt-ave_2020-06-27.jpg"
      }
    ],
    "firstSeen": 1444039477,
    "oldId": "C32027",
    "hashId": "f8350cc1-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350cc1-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "163",
    "name": "The Lawn on D",
    "lat": 42.344792,
    "long": -71.044024,
    "photos": [
      {
        "date": "2018-03-18",
        "photo": "photos/lawn-on-d_2018-03-18.jpg"
      },
      {
        "date": "2019-05-26",
        "photo": "photos/lawn-on-d_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1444132446,
    "oldId": "C32028",
    "hashId": "f8350d30-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350d30-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "167",
    "name": "Ryan Playground - Dorchester Ave at Harbor View St",
    "lat": 42.317642,
    "long": -71.056664,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/dorchester-ave-at-harbor-view-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1444078860,
    "oldId": "C32031",
    "hashId": "f8350ecf-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8350ecf-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "169",
    "name": "Edwards Playground - Main St at Eden St",
    "lat": 42.378965,
    "long": -71.068607,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/main-st-at-eden-st_2017-08-13.jpg"
      },
      {
        "date": "2019-07-28",
        "photo": "photos/main-st-at-eden-st_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1444165414,
    "oldId": "A32022",
    "hashId": "f8351019-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351019-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "170",
    "name": "Franklin Park Zoo - Franklin Park Rd at Blue Hill Ave",
    "lat": 42.303469,
    "long": -71.085347,
    "photos": [
      {
        "date": "2020-06-27",
        "photo": "photos/franklin-park-zoo_2020-06-27.jpg"
      }
    ],
    "firstSeen": 1444153920,
    "oldId": "C32030",
    "hashId": "f835108b-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835108b-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "171",
    "name": "Bunker Hill Community College",
    "lat": 42.37408991426522,
    "long": -71.06905996799469,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/bunker-hill-community-college_2017-08-13.jpg"
      },
      {
        "date": "2022-08-18",
        "photo": "photos/bunker-hill-community-college_2022-08-18.jpg"
      }
    ],
    "firstSeen": 1444941964,
    "oldId": "A32023",
    "hashId": "f83510fd-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83510fd-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "173",
    "name": "Savin Hill T Stop - S Sydney St at Bay St",
    "lat": 42.3106,
    "long": -71.0539,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/savin-hill-t-stop_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1460910715,
    "oldId": "C32033",
    "hashId": "f83511d4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83511d4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "174",
    "name": "Washington St at Brock St",
    "lat": 42.3489528466951,
    "long": -71.16031676530838,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/washington-st-at-brock-st_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1444420764,
    "oldId": "D32029",
    "hashId": "f8351255-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351255-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "175",
    "name": "Brighton Center - Washington St at Cambridge St",
    "lat": 42.34894857235593,
    "long": -71.15027189254761,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/brighton-center_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1444587614,
    "oldId": "D32028",
    "hashId": "f83512c9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83512c9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "176",
    "name": "Lesley University",
    "lat": 42.38674802045056,
    "long": -71.11901879310608,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/lesley-university_2019-05-11.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/lesley-university_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1446674217,
    "oldId": "M32039",
    "hashId": "f8351336-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351336-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "177",
    "name": "University Park",
    "lat": 42.36264779118595,
    "long": -71.10006093978882,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/university-park_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1446676212,
    "oldId": "M32040",
    "hashId": "f83513a7-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83513a7-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "178",
    "name": "MIT Pacific St at Purrington St",
    "lat": 42.35957320109044,
    "long": -71.10129475593567,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/mit-at-pacific-st-purrington-st_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1446668578,
    "oldId": "M32041",
    "hashId": "f835141c-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835141c-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "179",
    "name": "MIT Vassar St",
    "lat": 42.355601213279265,
    "long": -71.10394477844238,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/mit-at-vassar-st_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1446684891,
    "oldId": "M32042",
    "hashId": "f8351490-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351490-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "180",
    "name": "Mt Auburn",
    "lat": 42.37478628706384,
    "long": -71.13320231437683,
    "photos": [
      {
        "date": "2020-07-26",
        "photo": "photos/mt-auburn_2020-07-26.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/mt-auburn_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1446731928,
    "oldId": "M32043",
    "hashId": "f83514f9-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83514f9-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "181",
    "name": "Huron Ave At Vassal Lane",
    "lat": 42.381650612901176,
    "long": -71.13426981498378,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/huron-ave-at-vassal-lane_2018-06-30.jpg"
      },
      {
        "date": "2020-07-26",
        "photo": "photos/huron-ave-at-vassal-lane_2020-07-26.jpg"
      }
    ],
    "firstSeen": 1509106336,
    "oldId": "M32044",
    "hashId": "f8351569-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351569-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "182",
    "name": "Museum of Science",
    "lat": 42.36769018024484,
    "long": -71.07116281986237,
    "firstSeen": 1494525549,
    "oldId": "M32045",
    "hashId": "f83515d8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83515d8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "183",
    "name": "Alewife MBTA at Steel Place",
    "lat": 42.395588457167094,
    "long": -71.14260613918304,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/alewife-mbta-at-steel-place_2018-06-30.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/alewife-mbta-at-steel-place_2020-08-08.jpg"
      },
      {
        "date": "2021-04-10",
        "photo": "photos/alewife-mbta-at-steel-place_2021-04-10.jpg"
      },
      {
        "date": "2023-04-08",
        "photo": "photos/alewife-mbta-at-steel-place_2023-04-08.jpg"
      }
    ],
    "firstSeen": 1472591977,
    "oldId": "M32046",
    "hashId": "f8351649-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351649-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "184",
    "name": "Sidney Research Campus/Erie Street at Waverly",
    "lat": 42.35775309465199,
    "long": -71.10393404960632,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/sidney-research-campus_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1475700885,
    "oldId": "M32047",
    "hashId": "f83516b8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83516b8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "185",
    "name": "Third at Binney",
    "lat": 42.36544486137399,
    "long": -71.08277142047882,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/third-at-binney-st_2017-08-13.jpg"
      },
      {
        "date": "2020-12-28",
        "photo": "photos/third-at-binney-st_2020-12-28.jpg"
      }
    ],
    "firstSeen": 1477587118,
    "oldId": "M32048",
    "hashId": "f8351726-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351726-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "186",
    "name": "Congress St at Northern Ave",
    "lat": 42.3481,
    "long": -71.03764,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/congress-st-at-northern-ave_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1460411250,
    "oldId": "C32032",
    "hashId": "f8351791-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351791-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "187",
    "name": "Cypress St at Clark Playground",
    "lat": 42.32784317478686,
    "long": -71.1253622174263,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/cypress-st-at-clark-playground_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1532625116,
    "oldId": "K32009",
    "hashId": "f83517fa-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83517fa-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "188",
    "name": "Foss Park",
    "lat": 42.39108438146733,
    "long": -71.09039425849915,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/foss-park_2019-06-08.jpg"
      },
      {
        "date": "2020-05-03",
        "photo": "photos/foss-park_2020-05-03.jpg"
      },
      {
        "date": "2020-12-31",
        "photo": "photos/foss-park_2020-12-31.jpg"
      }
    ],
    "firstSeen": 1533056044,
    "oldId": "S32015",
    "hashId": "f8351dc2-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351dc2-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "189",
    "name": "Kendall T",
    "lat": 42.362427842912396,
    "long": -71.08495473861694,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/kendall-t_2020-01-11.jpg"
      },
      {
        "date": "2023-01-29",
        "photo": "photos/kendall-t_2023-01-29.jpg"
      }
    ],
    "firstSeen": 1458594307,
    "oldId": "M32004",
    "hashId": "f8351e42-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351e42-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "190",
    "name": "Nashua Street at Red Auerbach Way",
    "lat": 42.365673,
    "long": -71.064263,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/nashua-st-at-red-auerbach-way_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1457041627,
    "oldId": "A32025",
    "hashId": "f8351eb0-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351eb0-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "191",
    "name": "Tappan St at Brookline Hills MBTA",
    "lat": 42.331370168043215,
    "long": -71.1268836054569,
    "photos": [
      {
        "date": "2020-02-23",
        "photo": "photos/tappan-st-at-brookline-hills-mbta_2020-02-23.jpg"
      },
      {
        "date": "2020-07-03",
        "photo": "photos/tappan-st-at-brookline-hills-mbta_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1532540526,
    "oldId": "K32008",
    "hashId": "f8351f1d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351f1d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "192",
    "name": "Purchase St at Pearl St",
    "lat": 42.354659,
    "long": -71.053181,
    "photos": [
      {
        "date": "2017-08-13",
        "photo": "photos/purchase-st-at-pearl-st_2017-08-13.jpg"
      }
    ],
    "firstSeen": 1460750192,
    "oldId": "A32026",
    "hashId": "f8351f87-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351f87-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "193",
    "name": "Brookline Town Hall",
    "lat": 42.33376472623494,
    "long": -71.12046446660315,
    "photos": [
      {
        "date": "2020-02-23",
        "photo": "photos/brookline-town-hall_2020-02-23.jpg"
      }
    ],
    "firstSeen": 1461870950,
    "oldId": "K32005",
    "hashId": "f8351ff1-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8351ff1-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "194",
    "name": "Broadway St at Mt Pleasant St",
    "lat": 42.386141411639564,
    "long": -71.07828140258789,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/broadway-st-at-mt-pleasant-st_2018-09-08.jpg"
      },
      {
        "date": "2020-05-03",
        "photo": "photos/broadway-st-at-mt-pleasant-st_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1461940218,
    "oldId": "S32014",
    "hashId": "f8352068-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352068-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "195",
    "name": "Child Street at Brian P. Murphy Staircase",
    "lat": 42.371976784679745,
    "long": -71.07200495972165,
    "photos": [
      {
        "date": "2018-03-03",
        "photo": "photos/brian-p-murphy-staircase-at-child-st_2018-03-03.jpg"
      }
    ],
    "firstSeen": 1467325390,
    "oldId": "M32049",
    "hashId": "f83520d8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83520d8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "196",
    "name": "Roxbury YMCA - Warren St at MLK Blvd",
    "lat": 42.31787329345602,
    "long": -71.08243077993393,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/roxbury-ymca_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1469109626,
    "oldId": "B32022",
    "hashId": "f8352150-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352150-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "197",
    "name": "MLK Blvd at Washington St",
    "lat": 42.32143814183195,
    "long": -71.09126061201096,
    "photos": [
      {
        "date": "2020-08-01",
        "photo": "photos/mlk-blvd-at-washington-st_2020-08-01.jpg"
      }
    ],
    "firstSeen": 1468690201,
    "oldId": "B32023",
    "hashId": "f8352219-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352219-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "199",
    "name": "Uphams Corner T Stop - Magnolia St at Dudley St",
    "lat": 42.318697341722434,
    "long": -71.0697814822197,
    "photos": [
      {
        "date": "2020-08-01",
        "photo": "photos/uphams-corner-t-stop_2020-08-01.jpg"
      }
    ],
    "firstSeen": 1469480496,
    "oldId": "B32025",
    "hashId": "f8352306-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352306-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "200",
    "name": "Washington St at Melnea Cass Blvd",
    "lat": 42.332817,
    "long": -71.081198,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/washington-st-at-melnea-cass-blvd_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1469122357,
    "oldId": "B32026",
    "hashId": "f8352378-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352378-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "201",
    "name": "NCAAA - Walnut Ave at Crawford St",
    "lat": 42.316902,
    "long": -71.091946,
    "photos": [
      {
        "date": "2020-08-01",
        "photo": "photos/walnut-ave-at-crawford-st_2020-08-01.jpg"
      }
    ],
    "firstSeen": 1470591910,
    "oldId": "B32027",
    "hashId": "f83523e3-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83523e3-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "202",
    "name": "Grove Hall Library - 41 Geneva Ave",
    "lat": 42.30791,
    "long": -71.080952,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/grove-hall-library_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1471562392,
    "oldId": "B32028",
    "hashId": "f835244e-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835244e-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "203",
    "name": "Columbia Rd at Ceylon St",
    "lat": 42.309572,
    "long": -71.0729,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/columbia-rd-at-ceylon-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1470694872,
    "oldId": "B32029",
    "hashId": "f83524c0-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83524c0-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "204",
    "name": "Walnut Ave at Warren St",
    "lat": 42.324081,
    "long": -71.083235,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/walnut-ave-at-warren-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1470699665,
    "oldId": "B32030",
    "hashId": "f8352534-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352534-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "205",
    "name": "Bowdoin St at Quincy St",
    "lat": 42.30785224238503,
    "long": -71.06512248516083,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/bowdoin-st-at-quincy-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1471376480,
    "oldId": "B32031",
    "hashId": "f835259f-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835259f-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "206",
    "name": "Government Center - Cambridge St at Court St",
    "lat": 42.3598253979878,
    "long": -71.05979576244862,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/government-center_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1524089822,
    "oldId": "B32032",
    "hashId": "f835260c-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835260c-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "208",
    "name": "Oak Square - 615 Washington St",
    "lat": 42.35057,
    "long": -71.166491,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/oak-square_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1472670990,
    "oldId": "D32031",
    "hashId": "f835288a-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f835288a-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "209",
    "name": "Chelsea St at Saratoga St",
    "lat": 42.379772,
    "long": -71.027448,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/chelsea-st-at-saratoga-st_2019-05-19.jpg"
      },
      {
        "date": "2021-01-10",
        "photo": "photos/chelsea-st-at-saratoga-st_2021-01-10.jpg"
      }
    ],
    "firstSeen": 1475021838,
    "oldId": "A32027",
    "hashId": "f8352905-0de8-11e7-991c-3863bb43a7d0",
    "isLegacy": true
  },
  {
    "id": "210",
    "name": "Bennington St at Byron St",
    "lat": 42.383532520117896,
    "long": -71.01619094610214,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/bennington-st-at-byron-st_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1474994484,
    "oldId": "A32028",
    "hashId": "f8352973-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352973-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "211",
    "name": "Piers Park",
    "lat": 42.36501119822304,
    "long": -71.03528559207916,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/piers-park_2019-05-19.jpg"
      },
      {
        "date": "2020-07-12",
        "photo": "photos/piers-park_2020-07-12.jpg"
      }
    ],
    "firstSeen": 1475188584,
    "oldId": "A32029",
    "hashId": "f83529e2-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83529e2-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "212",
    "name": "Maverick Square - Lewis Mall",
    "lat": 42.368844082898356,
    "long": -71.03977829217911,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/maverick-square-lewis-mall_2019-05-19.jpg"
      },
      {
        "date": "2021-01-10",
        "photo": "photos/maverick-square-lewis-mall_2021-01-10.jpg"
      },
      {
        "date": "2021-05-01",
        "photo": "photos/maverick-square-lewis-mall_2021-05-01.jpg"
      }
    ],
    "firstSeen": 1475101634,
    "oldId": "A32030",
    "hashId": "f8352a4d-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352a4d-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "213",
    "name": "East Boston Neighborhood Health Center - 20 Maverick Square",
    "lat": 42.369536,
    "long": -71.039431,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/ebnhc-maverick-square_2019-05-19.jpg"
      },
      {
        "date": "2020-07-12",
        "photo": "photos/ebnhc-maverick-square_2020-07-12.jpg"
      }
    ],
    "firstSeen": 1475174063,
    "oldId": "A32031",
    "hashId": "f8352ac4-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352ac4-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "214",
    "name": "Airport T Stop - Bremen St at Brooks St",
    "lat": 42.375353774222354,
    "long": -71.03131771093103,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/airport-t-stop_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1475079932,
    "oldId": "A32032",
    "hashId": "f8352b39-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352b39-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "215",
    "name": "The Eddy - New St at Sumner St",
    "lat": 42.370744,
    "long": -71.044201,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/the-eddy-new-st-at-sumner-st_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1475190403,
    "oldId": "A32033",
    "hashId": "f8352ba8-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352ba8-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "216",
    "name": "Glendon St at Condor St",
    "lat": 42.38240377674137,
    "long": -71.03024303913116,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/glendon-st-at-condor-st_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1475250191,
    "oldId": "A32034",
    "hashId": "f8352c1a-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352c1a-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "217",
    "name": "Orient Heights T Stop - Bennington St at Saratoga St",
    "lat": 42.386781,
    "long": -71.006098,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/orient-heights-t-stop_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1475186918,
    "oldId": "A32035",
    "hashId": "f8352c92-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8352c92-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "218",
    "name": "Watermark Seaport - Boston Wharf Rd at Seaport Blvd",
    "lat": 42.351586001198456,
    "long": -71.04569256305693,
    "photos": [
      {
        "date": "2019-05-17",
        "photo": "photos/watermark-seaport_2019-05-17.jpg"
      }
    ],
    "firstSeen": 1475696347,
    "oldId": "C32034",
    "hashId": "f8353277-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f8353277-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "219",
    "name": "Boston East - 126 Border St",
    "lat": 42.373312125824704,
    "long": -71.0410200806291,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/boston-east_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1479301223,
    "oldId": "A32036",
    "hashId": "f83532fa-0de8-11e7-991c-3863bb43a7d0",
    "newId": "f83532fa-0de8-11e7-991c-3863bb43a7d0"
  },
  {
    "id": "221",
    "name": "Verizon Innovation Hub 10 Ware Street",
    "lat": 42.37250864997261,
    "long": -71.11305356025696,
    "photos": [
      {
        "date": "2019-05-11",
        "photo": "photos/verizon-innovation-hub-10-ware-st_2019-05-11.jpg"
      }
    ],
    "firstSeen": 1496836026,
    "oldId": "M32050",
    "hashId": "fbb9b58c-2b92-4bcf-8e4c-a982e40d2f39",
    "newId": "fbb9b58c-2b92-4bcf-8e4c-a982e40d2f39"
  },
  {
    "id": "222",
    "name": "Troy Boston",
    "lat": 42.343749,
    "long": -71.062256,
    "photos": [
      {
        "date": "2018-06-24",
        "photo": "photos/troy-boston_2018-06-24.jpg"
      }
    ],
    "firstSeen": 1509148096,
    "oldId": "C32035",
    "hashId": "83183df0-38c2-4ae8-a0c4-4b59599c9582",
    "newId": "83183df0-38c2-4ae8-a0c4-4b59599c9582"
  },
  {
    "id": "224",
    "name": "Fresh Pond Reservation",
    "lat": 42.38267827521855,
    "long": -71.143478951426,
    "photos": [
      {
        "date": "2018-06-30",
        "photo": "photos/fresh-pond-reservation_2018-06-30.jpg"
      }
    ],
    "firstSeen": 1498577114,
    "oldId": "M32051",
    "hashId": "1331197c-f6c6-4f4a-a29b-95e580359886",
    "newId": "1331197c-f6c6-4f4a-a29b-95e580359886"
  },
  {
    "id": "225",
    "name": "Cambridge Dept. of Public Works -147 Hampshire St.",
    "lat": 42.37119727759414,
    "long": -71.09759867191315,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/cambridge-department-of-public-works_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1502396993,
    "oldId": "M32052",
    "hashId": "7560f0e3-267b-4fb3-bbfa-6e02010cfcb7",
    "newId": "7560f0e3-267b-4fb3-bbfa-6e02010cfcb7"
  },
  {
    "id": "226",
    "name": "Commonwealth Ave At Babcock St",
    "lat": 42.35154734791375,
    "long": -71.12126246094704,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/commonwealth-ave-at-babcock-st_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1501023805,
    "oldId": "K32006",
    "hashId": "ea65c25f-0718-4f79-8588-c58b9fe3a105",
    "newId": "ea65c25f-0718-4f79-8588-c58b9fe3a105"
  },
  {
    "id": "227",
    "name": "Silber Way",
    "lat": 42.34949599514002,
    "long": -71.10057592391968,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/silber-way_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1501095235,
    "oldId": "D32032",
    "hashId": "36f36eed-1070-49be-8b31-5676b8b3489f",
    "newId": "36f36eed-1070-49be-8b31-5676b8b3489f"
  },
  {
    "id": "228",
    "name": "One Memorial Drive",
    "lat": 42.361619320766856,
    "long": -71.0804355052096,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/one-memorial-drive_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1506017929,
    "oldId": "M32053",
    "hashId": "a825b55d-3ab3-481f-9c50-d3a7313e6843",
    "newId": "a825b55d-3ab3-481f-9c50-d3a7313e6843"
  },
  {
    "id": "232",
    "name": "Four Corners - 157 Washington St",
    "lat": 42.304127927390155,
    "long": -71.07929527759552,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/four-corners_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1513699569,
    "oldId": "C32037",
    "hashId": "31382324-2931-491a-827a-40149e45afee",
    "newId": "31382324-2931-491a-827a-40149e45afee"
  },
  {
    "id": "233",
    "name": "St Mary's",
    "lat": 42.346197076555455,
    "long": -71.10728681087494,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/st-marys_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1534189922,
    "oldId": "K32007",
    "hashId": "1cced911-b428-4cfd-81be-3a8f77ce7379",
    "newId": "1cced911-b428-4cfd-81be-3a8f77ce7379"
  },
  {
    "id": "234",
    "name": "Broadway at Central St",
    "lat": 42.39517150121575,
    "long": -71.09850591982195,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/broadway-at-central-st_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1532650437,
    "oldId": "S32016",
    "hashId": "625f105e-4407-4988-9095-d1979cbda5e1",
    "newId": "625f105e-4407-4988-9095-d1979cbda5e1"
  },
  {
    "id": "235",
    "name": "East Somerville Library (Broadway and Illinois)",
    "lat": 42.3876281075134,
    "long": -71.08318716287613,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/east-somerville-library_2018-09-08.jpg"
      },
      {
        "date": "2020-05-03",
        "photo": "photos/east-somerville-library_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1533059581,
    "oldId": "S32017",
    "hashId": "25cc602a-152a-4abe-9df8-a131d0a5d94b",
    "newId": "25cc602a-152a-4abe-9df8-a131d0a5d94b"
  },
  {
    "id": "236",
    "name": "Assembly Square T",
    "lat": 42.392232840046276,
    "long": -71.07746601104736,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/assembly-square-t-stop_2018-09-08.jpg"
      },
      {
        "date": "2019-06-27",
        "photo": "photos/assembly-square-t_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1533078908,
    "oldId": "S32018",
    "hashId": "7c860828-3359-4229-b59b-963f5a4bab00",
    "newId": "7c860828-3359-4229-b59b-963f5a4bab00"
  },
  {
    "id": "239",
    "name": "Community Path at Cedar Street",
    "lat": 42.39407223665884,
    "long": -71.11133694648743,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/community-path-at-cedar-st_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1534436061,
    "oldId": "S32022",
    "hashId": "7df1ee28-441e-44c1-8507-8f58946be96b",
    "newId": "7df1ee28-441e-44c1-8507-8f58946be96b"
  },
  {
    "id": "255",
    "name": "Park St at Norwell St",
    "lat": 42.29442578670662,
    "long": -71.07821760713703,
    "photos": [
      {
        "date": "2020-06-27",
        "photo": "photos/park-st-at-norwell-st_2020-06-27.jpg"
      }
    ],
    "firstSeen": 1535143018,
    "oldId": "C32039",
    "hashId": "103a9881-e30a-4d57-b81d-863b995ff759",
    "newId": "103a9881-e30a-4d57-b81d-863b995ff759"
  },
  {
    "id": "258",
    "name": "Gallivan Blvd at Adams St",
    "lat": 42.28297567840305,
    "long": -71.05466698296368,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/gallivan-blvd-at-adams-st_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1535107508,
    "oldId": "C32040",
    "hashId": "0839bb10-e876-4198-95e4-0147a561853d",
    "newId": "0839bb10-e876-4198-95e4-0147a561853d"
  },
  {
    "id": "259",
    "name": "Washington St at Bowdoin St",
    "lat": 42.299164979365884,
    "long": -71.07345871645519,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/washington-st-at-bradlee-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1535160103,
    "oldId": "C32042",
    "hashId": "829fa5a8-cc88-4ab2-9f34-b481aaa1612e",
    "newId": "829fa5a8-cc88-4ab2-9f34-b481aaa1612e"
  },
  {
    "id": "260",
    "name": "Fields Corner T Stop",
    "lat": 42.299633488420625,
    "long": -71.06068975823291,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/fields-corner-t-stop_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1535389518,
    "oldId": "C32041",
    "hashId": "8d893519-bc21-4536-b346-f558988bc8ad",
    "newId": "8d893519-bc21-4536-b346-f558988bc8ad"
  },
  {
    "id": "271",
    "name": "Ashmont T Stop",
    "lat": 42.2856944,
    "long": -71.0641389,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/ashmont-t-stop_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1562716385,
    "oldId": "C32062",
    "hashId": "57caf362-1e8b-46aa-9ee3-82341c405ab0",
    "newId": "57caf362-1e8b-46aa-9ee3-82341c405ab0"
  },
  {
    "id": "272",
    "name": "Shawmut T Stop",
    "lat": 42.292917,
    "long": -71.06575,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/shawmut-t-stop_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1562204452,
    "oldId": "E32009",
    "hashId": "ea00f276-26dc-48ee-b2c8-6e56fd632a2d",
    "newId": "ea00f276-26dc-48ee-b2c8-6e56fd632a2d"
  },
  {
    "id": "273",
    "name": "Forest Hills",
    "lat": 42.300923,
    "long": -71.114249,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/forest-hills-t-stop_2020-05-22.jpg"
      },
      {
        "date": "2022-05-01",
        "photo": "photos/forest-hills-t-stop_2022-05-01.jpg"
      }
    ],
    "firstSeen": 1562103486,
    "oldId": "E32010",
    "hashId": "4764641e-b080-4c70-a1e1-049de81e09d0",
    "newId": "4764641e-b080-4c70-a1e1-049de81e09d0"
  },
  {
    "id": "279",
    "name": "Williams St at Washington St",
    "lat": 42.306539,
    "long": -71.107669,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/williams-st-at-washington-st_2019-06-12.jpg"
      }
    ],
    "firstSeen": 1537477856,
    "oldId": "D32040",
    "hashId": "c202f2ca-ec1d-4dc5-a734-e0a342f23ba4",
    "newId": "c202f2ca-ec1d-4dc5-a734-e0a342f23ba4"
  },
  {
    "id": "280",
    "name": "Main St at Baldwin St",
    "lat": 42.380857,
    "long": -71.070629,
    "photos": [
      {
        "date": "2019-07-28",
        "photo": "photos/main-st-at-baldwin-st_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1537400682,
    "oldId": "D32036",
    "hashId": "7f68a70d-af0e-450a-b7a8-ce621cd0c8c1",
    "newId": "7f68a70d-af0e-450a-b7a8-ce621cd0c8c1"
  },
  {
    "id": "282",
    "name": "Stony Brook T Stop",
    "lat": 42.316966,
    "long": -71.104374,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/stony-brook-t-stop_2019-06-12.jpg"
      },
      {
        "date": "2020-06-27",
        "photo": "photos/stony-brook-t-stop_2020-06-27.jpg"
      }
    ],
    "firstSeen": 1537491993,
    "oldId": "D32041",
    "hashId": "c98f50f6-1901-4c64-b164-8cf33245e022",
    "newId": "c98f50f6-1901-4c64-b164-8cf33245e022"
  },
  {
    "id": "296",
    "name": "Farragut Rd at E. 6th St",
    "lat": 42.3334,
    "long": -71.02495,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/farragut-rd-at-e-6th-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1538070016,
    "oldId": "C32051",
    "hashId": "74cb9bf6-bb53-4c70-88d2-67998b99628c",
    "newId": "74cb9bf6-bb53-4c70-88d2-67998b99628c"
  },
  {
    "id": "318",
    "name": "Ames St at Broadway",
    "lat": 42.3636929,
    "long": -71.0875672,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/ames-st-at-broadway_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1540485059,
    "oldId": "M32059",
    "hashId": "dd05a440-f881-410c-a071-864da11c634a",
    "newId": "dd05a440-f881-410c-a071-864da11c634a"
  },
  {
    "id": "319",
    "name": "84 Cambridgepark Dr",
    "lat": 42.3936,
    "long": -71.143941,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/84-cambridgepark-dr_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1539867164,
    "oldId": "M32058",
    "hashId": "bd3d4824-7203-4edc-a314-cea2b413e886",
    "newId": "bd3d4824-7203-4edc-a314-cea2b413e886"
  },
  {
    "id": "327",
    "name": "Main St at Thompson Sq",
    "lat": 42.374878467440965,
    "long": -71.06383498767171,
    "photos": [
      {
        "date": "2019-07-28",
        "photo": "photos/main-st-at-thompson-sq_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1540326446,
    "oldId": "D32038",
    "hashId": "3dbbb3cb-b52d-46f8-8f9c-db11e83d081c",
    "newId": "3dbbb3cb-b52d-46f8-8f9c-db11e83d081c"
  },
  {
    "id": "328",
    "name": "Grove St at Community Path",
    "lat": 42.396386809455,
    "long": -71.12011306006025,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/grove-st-at-community-path_2019-06-08.jpg"
      },
      {
        "date": "2020-08-08",
        "photo": "photos/grove-st-at-community-path_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1540326330,
    "oldId": "S32019",
    "hashId": "baab0b05-a028-4147-8432-a2726c7e48ea",
    "newId": "baab0b05-a028-4147-8432-a2726c7e48ea"
  },
  {
    "id": "329",
    "name": "Washington St at Myrtle St",
    "lat": 42.3817508,
    "long": -71.0839523,
    "photos": [
      {
        "date": "2018-09-08",
        "photo": "photos/washington-st-at-myrtle-st_2018-09-08.jpg"
      }
    ],
    "firstSeen": 1540328398,
    "oldId": "S32021",
    "hashId": "13681246-4bf8-444a-a908-fa165e4ad298",
    "isLegacy": true,
    "newId": "13681246-4bf8-444a-a908-fa165e4ad298"
  },
  {
    "id": "330",
    "name": "30 Dane St",
    "lat": 42.38100143038052,
    "long": -71.10402522613185,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/30-dane-st_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1540325686,
    "oldId": "S32023",
    "hashId": "f2b83aa2-86cd-4904-a099-74bf2023f768",
    "newId": "f2b83aa2-86cd-4904-a099-74bf2023f768"
  },
  {
    "id": "331",
    "name": "Huntington Ave at Mass Art",
    "lat": 42.336585554561154,
    "long": -71.0988699646623,
    "photos": [
      {
        "date": "2020-02-23",
        "photo": "photos/huntington-ave-at-mass-art_2020-02-23.jpg"
      }
    ],
    "firstSeen": 1540327978,
    "oldId": "B32033",
    "hashId": "5018e851-f2f3-4e18-9914-c316bbcc2c3d",
    "newId": "5018e851-f2f3-4e18-9914-c316bbcc2c3d"
  },
  {
    "id": "332",
    "name": "Harvard Ave at Brainerd Rd",
    "lat": 42.34953016890375,
    "long": -71.13022770741735,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/harvard-ave-at-brainerd-rd_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1540323371,
    "oldId": "D32035",
    "hashId": "bb27977d-36c1-496d-8bac-631b612dd7b8",
    "newId": "bb27977d-36c1-496d-8bac-631b612dd7b8"
  },
  {
    "id": "333",
    "name": "699 Mt Auburn St",
    "lat": 42.37500235137831,
    "long": -71.14871613699506,
    "photos": [
      {
        "date": "2020-07-26",
        "photo": "photos/699-mt-auburn-st_2020-07-26.jpg"
      }
    ],
    "firstSeen": 1540324799,
    "oldId": "M32054",
    "hashId": "93381e13-cd04-4e10-ac81-9f5926aa01d5",
    "newId": "93381e13-cd04-4e10-ac81-9f5926aa01d5"
  },
  {
    "id": "334",
    "name": "Mass Ave at Hadley/Walden",
    "lat": 42.39120971915856,
    "long": -71.12260755160605,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/mass-ave-at-hadley-st-walden-st_2020-01-11.jpg"
      },
      {
        "date": "2023-04-08",
        "photo": "photos/mass-ave-at-hadley-st-walden-st_2023-04-08.jpg"
      }
    ],
    "firstSeen": 1540328736,
    "oldId": "M32056",
    "hashId": "1e3beeb4-f8e1-452c-93e5-48df78d4b313",
    "newId": "1e3beeb4-f8e1-452c-93e5-48df78d4b313"
  },
  {
    "id": "335",
    "name": "Harvard St at Greene-Rose Heritage Park",
    "lat": 42.36599432867437,
    "long": -71.09522221614498,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/harvard-st-at-greene-rose-heritage-park_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1540326861,
    "oldId": "M32055",
    "hashId": "70f20fcb-8e63-4015-9007-b97c525ab0a9",
    "newId": "70f20fcb-8e63-4015-9007-b97c525ab0a9"
  },
  {
    "id": "336",
    "name": "Mattapan T Stop",
    "lat": 42.267902,
    "long": -71.093641,
    "photos": [
      {
        "date": "2020-11-21",
        "photo": "photos/mattapan-t-stop_2020-11-21.jpg"
      }
    ],
    "firstSeen": 1541522271,
    "oldId": "C32045",
    "hashId": "b5894555-30a3-46db-918a-fdeb083d6001",
    "newId": "b5894555-30a3-46db-918a-fdeb083d6001"
  },
  {
    "id": "337",
    "name": "Roslindale Village - South St",
    "lat": 42.287072,
    "long": -71.127754,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/roslindale-village-south-st_2020-05-22.jpg"
      },
      {
        "date": "2022-05-01",
        "photo": "photos/roslindale-village-south-st_2022-05-01.jpg"
      }
    ],
    "firstSeen": 1540335792,
    "oldId": "C32047",
    "hashId": "5b1d2661-8a93-4f14-b242-ebc52792f951",
    "newId": "5b1d2661-8a93-4f14-b242-ebc52792f951"
  },
  {
    "id": "338",
    "name": "Commonwealth Ave at Kelton St",
    "lat": 42.348358628170445,
    "long": -71.13997217276847,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/commonwealth-ave-at-kelton-st_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1540409464,
    "oldId": "D32034",
    "hashId": "0adccd89-1d8d-44da-9f7c-5f18acafeddf",
    "newId": "0adccd89-1d8d-44da-9f7c-5f18acafeddf"
  },
  {
    "id": "339",
    "name": "Archdale Rd at Washington St",
    "lat": 42.29266592872725,
    "long": -71.12119538714069,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/archdale-rd-at-washington-st_2020-05-22.jpg"
      }
    ],
    "firstSeen": 1540407605,
    "oldId": "C32048",
    "hashId": "1ef9964d-47ac-4cd4-916e-7c006fa1e605",
    "newId": "1ef9964d-47ac-4cd4-916e-7c006fa1e605"
  },
  {
    "id": "340",
    "name": "Blue Hill Ave at Almont St",
    "lat": 42.274620671812244,
    "long": -71.09372552493369,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/blue-hill-ave-at-almont-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540514244,
    "oldId": "C32044",
    "hashId": "e32c0bb9-e85b-4764-b0fb-5c582cfd3a90",
    "newId": "e32c0bb9-e85b-4764-b0fb-5c582cfd3a90"
  },
  {
    "id": "341",
    "name": "Roslindale Village - Washington St",
    "lat": 42.28630716010807,
    "long": -71.12820532182013,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/roslindale-village-washington-st_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1540589573,
    "oldId": "C32046",
    "hashId": "aa964f5b-fadf-4fc1-8c91-84ee36e15f0d",
    "newId": "aa964f5b-fadf-4fc1-8c91-84ee36e15f0d"
  },
  {
    "id": "342",
    "name": "Boylston St at Jersey St",
    "lat": 42.344650628878306,
    "long": -71.09732501226972,
    "photos": [
      {
        "date": "2018-06-23",
        "photo": "photos/jersey-st-at-boylston-st_2018-06-23.jpg"
      },
      {
        "date": "2020-02-23",
        "photo": "photos/boylston-st-at-jersey-st_2020-02-23.jpg"
      }
    ],
    "firstSeen": 1311870060,
    "nonLegacyFirstSeen": 1540402675,
    "oldId": "B32034",
    "mergedLegacyStationInfo": {
      "id": 45,
      "name": "Jersey St. at Boylston St.",
      "lat": 42.3446808403874,
      "long": -71.09785348176956,
      "oldId": "B32011"
    },
    "hashId": "47c44bb1-d065-41f5-ad79-c18aee60af34",
    "newId": "47c44bb1-d065-41f5-ad79-c18aee60af34"
  },
  {
    "id": "343",
    "name": "Morton St T",
    "lat": 42.280725142920296,
    "long": -71.08617242434775,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/morton-st-t-stop_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540516393,
    "oldId": "C23045",
    "hashId": "b205d2bb-bb76-49ac-8d14-85eec5a874d3",
    "newId": "b205d2bb-bb76-49ac-8d14-85eec5a874d3"
  },
  {
    "id": "344",
    "name": "Commonwealth Ave at Chiswick Rd",
    "lat": 42.34024644768889,
    "long": -71.15168806174552,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/commonwealth-ave-at-chiswick-rd_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1540419384,
    "oldId": "D32033",
    "hashId": "51bb4381-dae4-4aeb-b213-0614b807be29",
    "newId": "51bb4381-dae4-4aeb-b213-0614b807be29"
  },
  {
    "id": "345",
    "name": "Park Plaza at Charles St S.",
    "lat": 42.351828068920675,
    "long": -71.06781138111728,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/park-plaza-at-charles-st-s_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1540407066,
    "oldId": "D32042",
    "hashId": "df03c61a-29d7-445d-9980-d7f058916591",
    "newId": "df03c61a-29d7-445d-9980-d7f058916591"
  },
  {
    "id": "346",
    "name": "Cleveland Circle",
    "lat": 42.335543083570826,
    "long": -71.15061519672052,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/cleveland-circle_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1540415387,
    "oldId": "B32035",
    "hashId": "25a9114f-66f0-427c-920f-5526ca84f056",
    "newId": "25a9114f-66f0-427c-920f-5526ca84f056"
  },
  {
    "id": "347",
    "name": "Thetford Ave at Norfolk St",
    "lat": 42.286212948246096,
    "long": -71.07942931372236,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/thetford-ave-at-norfolk-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1541509401,
    "oldId": "C32049",
    "hashId": "b0b8e427-7203-4844-9c70-129e08a74452",
    "newId": "b0b8e427-7203-4844-9c70-129e08a74452"
  },
  {
    "id": "348",
    "name": "Talbot Ave At Blue Hill Ave",
    "lat": 42.2945833,
    "long": -71.087111,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/talbot-ave-at-blue-hill-ave_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540408170,
    "oldId": "C32043",
    "hashId": "97a1e4e7-a1ff-427a-aaea-48ca0d00bf9c",
    "newId": "97a1e4e7-a1ff-427a-aaea-48ca0d00bf9c"
  },
  {
    "id": "349",
    "name": "Washington St at Talbot Ave",
    "lat": 42.290333,
    "long": -71.071806,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/washington-st-at-talbot-ave_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540588063,
    "oldId": "C32038",
    "hashId": "eb8824c3-9c84-4fec-8d6f-d537c8ab3beb",
    "newId": "eb8824c3-9c84-4fec-8d6f-d537c8ab3beb"
  },
  {
    "id": "350",
    "name": "Codman Square Library",
    "lat": 42.2873611,
    "long": -71.071111,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/codman-square-library_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540504109,
    "oldId": "C32055",
    "hashId": "ccf82572-a142-4378-9a4b-5a51fcb4ef81",
    "newId": "ccf82572-a142-4378-9a4b-5a51fcb4ef81"
  },
  {
    "id": "351",
    "name": "Faneuil St at Arlington St",
    "lat": 42.35276620545606,
    "long": -71.15988485533308,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/faneuil-st-at-arlington-st_2019-05-25.jpg"
      }
    ],
    "firstSeen": 1540411906,
    "oldId": "A32038",
    "hashId": "83852166-6e71-4f69-ba83-020a4c644b86",
    "isLegacy": true
  },
  {
    "id": "352",
    "name": "Ring Rd",
    "lat": 42.348278385426255,
    "long": -71.08044855314802,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/ring-rd-at-prudential-center_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1540495812,
    "oldId": "C32058",
    "hashId": "548b9fdd-c5a1-4638-8e45-36cf549591be",
    "newId": "548b9fdd-c5a1-4638-8e45-36cf549591be"
  },
  {
    "id": "353",
    "name": "Mattapan Library",
    "lat": 42.2773889,
    "long": -71.09325,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/mattapan-library_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540922995,
    "oldId": "C32050",
    "hashId": "b995a0b4-fc26-4661-9bc8-e166d648edfd",
    "newId": "b995a0b4-fc26-4661-9bc8-e166d648edfd"
  },
  {
    "id": "354",
    "name": "Washington St at Egremont Rd",
    "lat": 42.34286835071959,
    "long": -71.14127840855872,
    "photos": [
      {
        "date": "2019-05-25",
        "photo": "photos/washington-st-at-egremont-rd_2019-05-25.jpg"
      },
      {
        "date": "2020-07-03",
        "photo": "photos/washington-st-at-egremont-rd_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1540848852,
    "oldId": "A32037",
    "hashId": "510239fe-69c3-458b-b53c-6ce001845f4a",
    "newId": "510239fe-69c3-458b-b53c-6ce001845f4a"
  },
  {
    "id": "355",
    "name": "Bennington St at Constitution Beach",
    "lat": 42.385223935870535,
    "long": -71.01063068965232,
    "photos": [
      {
        "date": "2019-05-19",
        "photo": "photos/bennington-st-at-constitution-beach_2019-05-19.jpg"
      }
    ],
    "firstSeen": 1541167051,
    "oldId": "A32039",
    "hashId": "fe6a64e7-c712-404c-85ae-ee77a37245b1",
    "newId": "fe6a64e7-c712-404c-85ae-ee77a37245b1"
  },
  {
    "id": "356",
    "name": "Charlestown Navy Yard",
    "lat": 42.374124549426526,
    "long": -71.05481199938367,
    "photos": [
      {
        "date": "2019-07-28",
        "photo": "photos/charlestown-navy-yard_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1540842685,
    "oldId": "D32039",
    "hashId": "23146f28-9762-4a6d-a6db-fcd4ff0e5d07",
    "newId": "23146f28-9762-4a6d-a6db-fcd4ff0e5d07"
  },
  {
    "id": "357",
    "name": "Centre St at Seaverns Ave",
    "lat": 42.3121203,
    "long": -71.1142981,
    "photos": [
      {
        "date": "2019-06-12",
        "photo": "photos/centre-st-at-seaverns-ave_2019-06-12.jpg"
      }
    ],
    "firstSeen": 1540930928,
    "oldId": "E32008",
    "hashId": "81676204-e42f-4d3d-991e-9c1264f36229",
    "newId": "81676204-e42f-4d3d-991e-9c1264f36229"
  },
  {
    "id": "358",
    "name": "Medford St at Charlestown BCYF",
    "lat": 42.380429474494896,
    "long": -71.06055721676967,
    "photos": [
      {
        "date": "2019-07-28",
        "photo": "photos/medford-st-at-charlestown-bcyf_2019-07-28.jpg"
      }
    ],
    "firstSeen": 1540934803,
    "oldId": "D32037",
    "hashId": "a18e1e55-507e-49d8-b7c0-5c0efd959125",
    "newId": "a18e1e55-507e-49d8-b7c0-5c0efd959125"
  },
  {
    "id": "359",
    "name": "One Brigham Circle",
    "lat": 42.333922701369964,
    "long": -71.10446508982932,
    "photos": [
      {
        "date": "2020-02-23",
        "photo": "photos/one-brigham-circle_2020-02-23.jpg"
      }
    ],
    "firstSeen": 1540929810,
    "oldId": "B32036",
    "hashId": "f24a573e-ef07-4785-9270-70c443510ea6",
    "newId": "f24a573e-ef07-4785-9270-70c443510ea6"
  },
  {
    "id": "360",
    "name": "Bartlett St at John Elliot Sq",
    "lat": 42.3294633,
    "long": -71.0901582,
    "photos": [
      {
        "date": "2020-07-24",
        "photo": "photos/bartlett-st-at-john-elliot-sq_2020-07-24.jpg"
      }
    ],
    "firstSeen": 1540936898,
    "oldId": "B32055",
    "hashId": "8b703219-1e16-4ba1-9f46-2b7030c7849a",
    "newId": "8b703219-1e16-4ba1-9f46-2b7030c7849a"
  },
  {
    "id": "361",
    "name": "Deerfield St at Commonwealth Ave",
    "lat": 42.34924376955301,
    "long": -71.09728209692548,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/deerfield-st-at-commonwealth-ave_2020-05-31.jpg"
      },
      {
        "date": "2021-03-14",
        "photo": "photos/deerfield-st-at-commonwealth-ave_2021-03-14.jpg"
      }
    ],
    "firstSeen": 1540929411,
    "oldId": "B32037",
    "hashId": "e2c306f6-82c7-4d0d-89fd-c9452d2f9ad1",
    "newId": "e2c306f6-82c7-4d0d-89fd-c9452d2f9ad1"
  },
  {
    "id": "362",
    "name": "Columbia Rd at Tierney Community Center",
    "lat": 42.33023070528729,
    "long": -71.05060093123029,
    "photos": [
      {
        "date": "2019-10-20",
        "photo": "photos/columbia-rd-at-tierney-learning-center_2019-10-20.jpg"
      }
    ],
    "firstSeen": 1540934962,
    "oldId": "C32052",
    "hashId": "52ca4d94-587f-4a61-a298-a1a755e73bfb",
    "newId": "52ca4d94-587f-4a61-a298-a1a755e73bfb"
  },
  {
    "id": "363",
    "name": "Harrison Ave at Mullins Way",
    "lat": 42.34521562115022,
    "long": -71.0638403149278,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/harrison-ave-at-mullins-way_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540930988,
    "oldId": "C32057",
    "hashId": "81bfc33b-30d8-4881-b261-712715a8b2bc",
    "newId": "81bfc33b-30d8-4881-b261-712715a8b2bc"
  },
  {
    "id": "364",
    "name": "Tremont St at Northampton St",
    "lat": 42.338431795290944,
    "long": -71.08169049924982,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/tremont-st-at-northampton-st_2020-05-31.jpg"
      },
      {
        "date": "2020-07-24",
        "photo": "photos/tremont-st-at-northampton-st_2020-07-24.jpg"
      },
      {
        "date": "2021-05-01",
        "photo": "photos/tremont-st-at-northampton-st_2021-05-01.jpg"
      }
    ],
    "firstSeen": 1540933540,
    "oldId": "C32056",
    "hashId": "8bc42f7f-753d-4cca-a7d6-0d7c2530ea2c",
    "newId": "8bc42f7f-753d-4cca-a7d6-0d7c2530ea2c"
  },
  {
    "id": "365",
    "name": "Harrison Ave at Bennet St",
    "lat": 42.34942609892521,
    "long": -71.06209959843909,
    "photos": [
      {
        "date": "2019-06-01",
        "photo": "photos/harrison-ave-at-bennet-st_2019-06-01.jpg"
      }
    ],
    "firstSeen": 1540932601,
    "oldId": "D32043",
    "hashId": "fbc512a5-4b98-4821-bcfb-bb0c7ac83543",
    "newId": "fbc512a5-4b98-4821-bcfb-bb0c7ac83543"
  },
  {
    "id": "366",
    "name": "Broadway T Stop",
    "lat": 42.343048583900966,
    "long": -71.05803430091328,
    "photos": [
      {
        "date": "2019-05-26",
        "photo": "photos/broadway-t-stop_2019-05-26.jpg"
      }
    ],
    "firstSeen": 1540935095,
    "oldId": "C32053",
    "hashId": "56817d8e-3a1c-431a-a529-1a600f80c364",
    "newId": "56817d8e-3a1c-431a-a529-1a600f80c364"
  },
  {
    "id": "367",
    "name": "Vassal Lane at Tobin/VLUS",
    "lat": 42.38536765218425,
    "long": -71.13775670528412,
    "photos": [
      {
        "date": "2020-07-26",
        "photo": "photos/vassal-lane-at-tobin-vlus_2020-07-26.jpg"
      }
    ],
    "firstSeen": 1540938598,
    "oldId": "M32057",
    "hashId": "02a4416f-80b4-4472-accd-5db482ed5b6d",
    "newId": "02a4416f-80b4-4472-accd-5db482ed5b6d"
  },
  {
    "id": "368",
    "name": "Blue Hill Ave at Southwood St",
    "lat": 42.317860205899876,
    "long": -71.07798628527235,
    "photos": [
      {
        "date": "2020-08-01",
        "photo": "photos/blue-hill-ave-at-southwood-st_2020-08-01.jpg"
      }
    ],
    "firstSeen": 1541283291,
    "oldId": "B32057",
    "hashId": "eed9e64f-f09d-43c1-aa31-da968c66750a",
    "newId": "eed9e64f-f09d-43c1-aa31-da968c66750a"
  },
  {
    "id": "369",
    "name": "Boston Public Market",
    "lat": 42.36254854409333,
    "long": -71.0573735833168,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/boston-public-market_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1541084042,
    "oldId": "D32044",
    "hashId": "3df7aad4-5696-46d1-9d49-7e1e040a1282",
    "newId": "3df7aad4-5696-46d1-9d49-7e1e040a1282"
  },
  {
    "id": "370",
    "name": "Dartmouth St at Newbury St",
    "lat": 42.35096144421219,
    "long": -71.07782810926437,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/dartmouth-st-at-newbury-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1541093836,
    "oldId": "D32045",
    "hashId": "803a0c16-4f81-44f1-8b49-a13fcdda40c1",
    "newId": "803a0c16-4f81-44f1-8b49-a13fcdda40c1"
  },
  {
    "id": "371",
    "name": "700 Huron Ave",
    "lat": 42.380788172914286,
    "long": -71.15412890911102,
    "photos": [
      {
        "date": "2020-07-26",
        "photo": "photos/700-huron-ave_2020-07-26.jpg"
      }
    ],
    "firstSeen": 1541590314,
    "oldId": "M32060",
    "hashId": "9e9be067-c6bb-41de-9491-eda69d24d6de",
    "newId": "9e9be067-c6bb-41de-9491-eda69d24d6de"
  },
  {
    "id": "372",
    "name": "Boylston St at Exeter St",
    "lat": 42.349589423682445,
    "long": -71.0794677917329,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/boylston-st-at-exeter-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1541700294,
    "oldId": "D32046",
    "hashId": "cad0e759-b605-458a-b772-768dcce81dc6",
    "newId": "cad0e759-b605-458a-b772-768dcce81dc6"
  },
  {
    "id": "373",
    "name": "Belgrade Ave at Walworth St",
    "lat": 42.28634589486312,
    "long": -71.13672129828046,
    "photos": [
      {
        "date": "2020-06-21",
        "photo": "photos/belgrade-ave-at-walworth-st_2020-06-21.jpg"
      },
      {
        "date": "2022-05-01",
        "photo": "photos/belgrade-ave-at-walworth-st_2022-05-01.jpg"
      }
    ],
    "firstSeen": 1541956726,
    "oldId": "C32059",
    "hashId": "ba634139-d7bf-448f-aac4-25995d36b67b",
    "newId": "ba634139-d7bf-448f-aac4-25995d36b67b"
  },
  {
    "id": "374",
    "name": "Tremont St at Hamilton Pl",
    "lat": 42.35668334969929,
    "long": -71.06166645884514,
    "photos": [
      {
        "date": "2019-07-02",
        "photo": "photos/tremont-st-at-hamilton-pl_2019-07-02.jpg"
      }
    ],
    "firstSeen": 1542826612,
    "oldId": "B32056",
    "hashId": "1676a6f7-c417-48d2-92e8-5ce21995efc3",
    "newId": "1676a6f7-c417-48d2-92e8-5ce21995efc3"
  },
  {
    "id": "376",
    "name": "Honan Library",
    "lat": 42.36027370454934,
    "long": -71.12852452327388,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/honan-allston-library_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1543621353,
    "oldId": "A32040",
    "hashId": "c5cfd1ed-aa44-4e20-96bb-e334c96f0d68",
    "newId": "c5cfd1ed-aa44-4e20-96bb-e334c96f0d68"
  },
  {
    "id": "377",
    "name": "Perry Park",
    "lat": 42.37927324600841,
    "long": -71.10341902831351,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/perry-park_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1543957597,
    "oldId": "S32034",
    "hashId": "5bbdd12f-fec4-4741-9d5d-4a60834c5d02",
    "newId": "5bbdd12f-fec4-4741-9d5d-4a60834c5d02"
  },
  {
    "id": "378",
    "name": "191 Beacon St",
    "lat": 42.38032335045416,
    "long": -71.10878612855231,
    "photos": [
      {
        "date": "2019-06-08",
        "photo": "photos/191-beacon-st_2019-06-08.jpg"
      }
    ],
    "firstSeen": 1544051477,
    "oldId": "S32035",
    "hashId": "ec3eea05-a3ff-43c6-9e24-0c63f09b915f",
    "newId": "ec3eea05-a3ff-43c6-9e24-0c63f09b915f"
  },
  {
    "id": "379",
    "name": "Tremont St at W. Dedham St",
    "lat": 42.34254913737952,
    "long": -71.07421449387857,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/tremont-st-at-w-dedham-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1547162749,
    "oldId": "C32060",
    "hashId": "342a2c60-71b0-4d19-98d6-47987cf20dbc",
    "newId": "342a2c60-71b0-4d19-98d6-47987cf20dbc"
  },
  {
    "id": "380",
    "name": "Mass Ave at Albany St",
    "lat": 42.36135837662249,
    "long": -71.09670273977825,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/mass-ave-at-albany-st_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1547147910,
    "oldId": "M32061",
    "hashId": "f6df6c1a-064f-428a-951c-2069a081b9df",
    "newId": "f6df6c1a-064f-428a-951c-2069a081b9df"
  },
  {
    "id": "381",
    "name": "Inman Square at Springfield St.",
    "lat": 42.37426713524931,
    "long": -71.10026478767395,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/inman-square-at-springfield-st_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1547760944,
    "oldId": "M32062",
    "hashId": "2104f242-2dde-4ec0-8197-4454b1021ac1",
    "newId": "2104f242-2dde-4ec0-8197-4454b1021ac1"
  },
  {
    "id": "384",
    "name": "Clarendon St at Newbury St",
    "lat": 42.35159662465317,
    "long": -71.0756166279316,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/clarendon-st-at-newbury-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1555951221,
    "oldId": "D32047",
    "hashId": "8983c75a-12c1-4995-aedc-24c94bd895e3",
    "newId": "8983c75a-12c1-4995-aedc-24c94bd895e3"
  },
  {
    "id": "385",
    "name": "Albany St at E. Brookline St",
    "lat": 42.33664795427071,
    "long": -71.0689445958451,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/albany-st-at-e-brookline-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1557781273,
    "oldId": "C32061",
    "hashId": "1e1b40cd-8e00-4b74-b147-be6b23f5c9e7",
    "newId": "1e1b40cd-8e00-4b74-b147-be6b23f5c9e7"
  },
  {
    "id": "386",
    "name": "Sennott Park Broadway at Norfolk Street",
    "lat": 42.36860524248197,
    "long": -71.09930185605663,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/sennott-park-broadway-at-norfolk-street_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1558543612,
    "oldId": "M32063",
    "hashId": "5bc7cba9-f0b0-442d-932c-e8c7927d87ce",
    "newId": "5bc7cba9-f0b0-442d-932c-e8c7927d87ce"
  },
  {
    "id": "387",
    "name": "7 Acre Park",
    "lat": 42.41143222973408,
    "long": -71.06823265090497,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/norman-st-at-kelvin-st_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1560542274,
    "oldId": "V32001",
    "hashId": "53a148ca-ac69-4a9c-a986-9a4b4df2f5df",
    "newId": "53a148ca-ac69-4a9c-a986-9a4b4df2f5df"
  },
  {
    "id": "388",
    "name": "Main Street at Carter Street",
    "lat": 42.406151572663504,
    "long": -71.0604074504954,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/main-st-at-carter-st_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1560723784,
    "oldId": "V32002",
    "isLegacy": true
  },
  {
    "id": "389",
    "name": "Everett Square (Broadway at Chelsea St)",
    "lat": 42.40701601597739,
    "long": -71.05645000935965,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/everett-square_2019-06-27.jpg"
      },
      {
        "date": "2020-06-20",
        "photo": "photos/everett-square_2020-06-20.jpg"
      }
    ],
    "firstSeen": 1560574579,
    "oldId": "V32003",
    "hashId": "25489822-e43a-4704-b2a8-d905b78ba77c",
    "newId": "25489822-e43a-4704-b2a8-d905b78ba77c"
  },
  {
    "id": "390",
    "name": "Broadway at Lynde St",
    "lat": 42.39648358247693,
    "long": -71.06546759905541,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/broadway-at-lynde-st_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1560561242,
    "oldId": "V32004",
    "hashId": "3f4f4ad4-a715-43d5-828f-f8381f26b66a",
    "newId": "3f4f4ad4-a715-43d5-828f-f8381f26b66a"
  },
  {
    "id": "391",
    "name": "Encore",
    "lat": 42.39329263225162,
    "long": -71.0724475979805,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/encore_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1561308045,
    "oldId": "V32005",
    "hashId": "f7d416f8-4377-4019-bba7-302dc1d5a11d",
    "newId": "f7d416f8-4377-4019-bba7-302dc1d5a11d"
  },
  {
    "id": "392",
    "name": "Glendale Square (Ferry St at Broadway)",
    "lat": 42.41427294293992,
    "long": -71.04479655659816,
    "photos": [
      {
        "date": "2020-06-20",
        "photo": "photos/glendale-square_2020-06-20.jpg"
      }
    ],
    "firstSeen": 1561049568,
    "oldId": "V32006",
    "hashId": "d22265a8-a6d7-4922-a6b5-4be738a9e5c4",
    "newId": "d22265a8-a6d7-4922-a6b5-4be738a9e5c4"
  },
  {
    "id": "393",
    "name": "Swan St. Park",
    "lat": 42.412504507772105,
    "long": -71.05842225271772,
    "photos": [
      {
        "date": "2020-06-20",
        "photo": "photos/ferry-st-at-pleasantview-ave_2020-06-20.jpg"
      }
    ],
    "firstSeen": 1561359259,
    "oldId": "V32007",
    "hashId": "4b5de733-9353-4873-9dc2-9558b3cb063a",
    "newId": "4b5de733-9353-4873-9dc2-9558b3cb063a"
  },
  {
    "id": "394",
    "name": "Broadway at Maple St",
    "lat": 42.410346909767846,
    "long": -71.05260457852637,
    "photos": [
      {
        "date": "2020-06-20",
        "photo": "photos/broadway-at-maple-st_2020-06-20.jpg"
      }
    ],
    "firstSeen": 1561161776,
    "oldId": "V32008",
    "hashId": "f0f01d4c-ba4a-4ab2-9cc0-9fa1f9223300",
    "newId": "f0f01d4c-ba4a-4ab2-9cc0-9fa1f9223300"
  },
  {
    "id": "395",
    "name": "Chelsea St at Vine St",
    "lat": 42.420348701645025,
    "long": -71.04419767862055,
    "photos": [
      {
        "date": "2020-06-20",
        "photo": "photos/chelsea-st-at-vine-st_2020-06-20.jpg"
      }
    ],
    "firstSeen": 1561219701,
    "oldId": "V32009",
    "hashId": "fe534b6f-61ad-47d3-b9f6-cdaa128f53a4",
    "newId": "fe534b6f-61ad-47d3-b9f6-cdaa128f53a4"
  },
  {
    "id": "396",
    "name": "Wasgott Playground",
    "lat": 42.4093300667225,
    "long": -71.06381878293178,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/main-st-at-beacon-st_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1561222006,
    "oldId": "V32010",
    "hashId": "a5600f2e-baaa-43ea-bce5-880179f38599",
    "newId": "a5600f2e-baaa-43ea-bce5-880179f38599"
  },
  {
    "id": "397",
    "name": "Broadway at Beacham St",
    "lat": 42.39836059620832,
    "long": -71.06373842814719,
    "photos": [
      {
        "date": "2019-06-27",
        "photo": "photos/broadway-at-beacham-st_2019-06-27.jpg"
      }
    ],
    "firstSeen": 1561149760,
    "oldId": "V32011",
    "hashId": "13444449-b3a8-43d2-b8b9-a59fbc59a174",
    "newId": "13444449-b3a8-43d2-b8b9-a59fbc59a174"
  },
  {
    "id": "398",
    "name": "75 Binney St",
    "lat": 42.36550728505653,
    "long": -71.0801375997653,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/75-binney-st_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1562083422,
    "oldId": "M32064",
    "hashId": "133d159e-a202-4310-910f-eca392fe181e",
    "newId": "133d159e-a202-4310-910f-eca392fe181e"
  },
  {
    "id": "399",
    "name": "Shawmut Ave at Oak St W",
    "lat": 42.34854543411198,
    "long": -71.06559185315746,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/shawmut-ave-at-oak-st-w_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1562712093,
    "oldId": "D32048",
    "hashId": "53548ba4-ad0a-4d92-8e57-fd5c9f3ad102",
    "newId": "53548ba4-ad0a-4d92-8e57-fd5c9f3ad102"
  },
  {
    "id": "400",
    "name": "Lansdowne T Stop",
    "lat": 42.34734473177431,
    "long": -71.1001680795016,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/lansdowne-t-stop_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1562767924,
    "oldId": "B32058",
    "hashId": "99675a11-a92c-465c-bf5a-434b08ba5fc6",
    "newId": "99675a11-a92c-465c-bf5a-434b08ba5fc6"
  },
  {
    "id": "401",
    "name": "High St at Cypress St",
    "lat": 42.32464510949409,
    "long": -71.12135231494904,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/cypress-st-at-high-st_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1562800133,
    "oldId": "K32010",
    "hashId": "186accbb-6a08-4e1d-b267-9033c4443a56",
    "newId": "186accbb-6a08-4e1d-b267-9033c4443a56"
  },
  {
    "id": "402",
    "name": "Washington Sq",
    "lat": 42.33985827126298,
    "long": -71.13421171095978,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/washington-st-at-griggs-rd_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1562794852,
    "oldId": "K32011",
    "hashId": "270ad97d-035d-472a-9827-76d3187afc56",
    "newId": "270ad97d-035d-472a-9827-76d3187afc56"
  },
  {
    "id": "403",
    "name": "Marion St at Harvard St",
    "lat": 42.340122426966424,
    "long": -71.12070563810447,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/marion-st-at-harvard-st_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1562786898,
    "oldId": "K32012",
    "hashId": "0daf7408-7a15-4d2b-8c39-f1a097cdbe85",
    "newId": "0daf7408-7a15-4d2b-8c39-f1a097cdbe85"
  },
  {
    "id": "404",
    "name": "Mass Ave T Station",
    "lat": 42.34135615767354,
    "long": -71.08336953450817,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/massachusetts-ave-t-stop_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1563222150,
    "oldId": "C32063",
    "hashId": "ad2cf74c-0e8c-4e15-8f19-2259e8c82f0f",
    "newId": "ad2cf74c-0e8c-4e15-8f19-2259e8c82f0f"
  },
  {
    "id": "405",
    "name": "Sydney St at Carson St",
    "lat": 42.32039374304257,
    "long": -71.05355408051764,
    "photos": [
      {
        "date": "2019-10-20",
        "photo": "photos/sydney-st-at-carson-st_2019-10-20.jpg"
      }
    ],
    "firstSeen": 1563252201,
    "oldId": "C32064",
    "hashId": "f8285d4f-5053-4e26-bb28-72016fe11353",
    "newId": "f8285d4f-5053-4e26-bb28-72016fe11353"
  },
  {
    "id": "406",
    "name": "Somerville City Hall Annex",
    "lat": 42.39189811591863,
    "long": -71.09745375830244,
    "photos": [
      {
        "date": "2020-05-03",
        "photo": "photos/somerville-city-hall-annex_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1563313913,
    "oldId": "S32036",
    "hashId": "88b35b6d-f355-44dc-b85e-ed2eb1bc9099",
    "newId": "88b35b6d-f355-44dc-b85e-ed2eb1bc9099"
  },
  {
    "id": "407",
    "name": "Craigie at Summer St",
    "lat": 42.38831607584243,
    "long": -71.1106824874878,
    "photos": [
      {
        "date": "2020-01-11",
        "photo": "photos/craigie-st-at-summer-st_2020-01-11.jpg"
      }
    ],
    "firstSeen": 1563931995,
    "oldId": "S32037",
    "hashId": "403c0f6c-d01a-4171-a7be-3aa4dbb0d7d1",
    "isLegacy": true,
    "newId": "403c0f6c-d01a-4171-a7be-3aa4dbb0d7d1"
  },
  {
    "id": "408",
    "name": "Edgerly Education Center",
    "lat": 42.38717463348314,
    "long": -71.08714388528824,
    "photos": [
      {
        "date": "2020-05-03",
        "photo": "photos/edgerly-education-center_2020-05-03.jpg"
      },
      {
        "date": "2020-12-31",
        "photo": "photos/edgerly-education-center_2020-12-31.jpg"
      }
    ],
    "firstSeen": 1563922766,
    "oldId": "S32038",
    "hashId": "42886366-9cbf-432c-ba84-beef38877c87",
    "newId": "42886366-9cbf-432c-ba84-beef38877c87"
  },
  {
    "id": "409",
    "name": "Elm St at White St",
    "lat": 42.38952436305763,
    "long": -71.11694140364307,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/elm-st-at-white-st_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1564009481,
    "oldId": "S32039",
    "hashId": "171f821a-93cf-4791-b322-0834a29c2c5a",
    "newId": "171f821a-93cf-4791-b322-0834a29c2c5a"
  },
  {
    "id": "410",
    "name": "Adams St at Lonsdale St",
    "lat": 42.291679428741226,
    "long": -71.05726346409938,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/adams-st-at-lonsdale-st_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1564615026,
    "oldId": "C32065",
    "hashId": "ef54374b-0406-4b17-b0a1-847f1d7bea06",
    "newId": "ef54374b-0406-4b17-b0a1-847f1d7bea06"
  },
  {
    "id": "411",
    "name": "Dorchester Ave at King St",
    "lat": 42.291718,
    "long": -71.062571,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/dorchester-ave-at-king-st_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1564612829,
    "oldId": "C32066",
    "hashId": "4fe8e777-345b-4fc2-a684-dd874e8186d8",
    "isLegacy": true,
    "newId": "4fe8e777-345b-4fc2-a684-dd874e8186d8"
  },
  {
    "id": "412",
    "name": "Washington St at Peters Park",
    "lat": 42.34385240349049,
    "long": -71.06764554982874,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/washington-st-at-peters-park_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1564606660,
    "oldId": "C32067",
    "hashId": "295553ea-851b-4ac2-a094-f9d1208e399a",
    "newId": "295553ea-851b-4ac2-a094-f9d1208e399a"
  },
  {
    "id": "413",
    "name": "Kennedy-Longfellow School 158 Spring St",
    "lat": 42.36955297756508,
    "long": -71.08579014980933,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/kennedy-longfellow-school-158-spring-st_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1565711742,
    "oldId": "M32065",
    "hashId": "9ca92778-9101-48df-90ea-08b784e26a1a",
    "newId": "9ca92778-9101-48df-90ea-08b784e26a1a"
  },
  {
    "id": "414",
    "name": "Discovery Park - 30 Acorn Park Drive",
    "lat": 42.39790817368333,
    "long": -71.14797130814623,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/discovery-park-30-acorn-park-dr_2020-08-08.jpg"
      }
    ],
    "firstSeen": 1565808774,
    "oldId": "M32066",
    "hashId": "8347c34d-68df-404b-ac58-7a387cc3ce4b",
    "newId": "8347c34d-68df-404b-ac58-7a387cc3ce4b"
  },
  {
    "id": "415",
    "name": "Stuart St at Berkeley St",
    "lat": 42.34954403114251,
    "long": -71.0724207387284,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/stuart-st-at-berkeley-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1565815776,
    "oldId": "D32049",
    "hashId": "36a8fbc8-6069-4a29-bbfd-63a2d019c9a5",
    "newId": "36a8fbc8-6069-4a29-bbfd-63a2d019c9a5"
  },
  {
    "id": "416",
    "name": "Blossom St at Charles St",
    "lat": 42.36435589027983,
    "long": -71.0695936904267,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/blossom-st-at-charles-st_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1565818432,
    "oldId": "D32050",
    "hashId": "082262bd-086b-46c7-83ce-eed2b39142ff",
    "newId": "082262bd-086b-46c7-83ce-eed2b39142ff"
  },
  {
    "id": "417",
    "name": "Columbus Ave at W. Canton St",
    "lat": 42.34474224772802,
    "long": -71.07648162175792,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/columbus-ave-at-w-canton-st_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1565819738,
    "oldId": "C32077",
    "hashId": "ddcc8869-b8d4-483c-b9da-457fcebc8dc1",
    "newId": "ddcc8869-b8d4-483c-b9da-457fcebc8dc1"
  },
  {
    "id": "419",
    "name": "Central Square East Boston",
    "lat": 42.37544912547037,
    "long": -71.03918548682486,
    "photos": [
      {
        "date": "2020-07-12",
        "photo": "photos/central-square-east-boston_2020-07-12.jpg"
      }
    ],
    "firstSeen": 1566919387,
    "oldId": "A32041",
    "hashId": "9e710d48-51c1-460e-a027-3d16bde62b13",
    "newId": "9e710d48-51c1-460e-a027-3d16bde62b13"
  },
  {
    "id": "420",
    "name": "Charles St at Pinckney St",
    "lat": 42.35872530462163,
    "long": -71.07079532006537,
    "firstSeen": 1566417276,
    "oldId": "D32051",
    "hashId": "a758e44b-8f6b-498e-b943-1c4e965b00f4",
    "newId": "a758e44b-8f6b-498e-b943-1c4e965b00f4"
  },
  {
    "id": "421",
    "name": "Northbourne Rd at Hyde Park Ave",
    "lat": 42.29118034790426,
    "long": -71.117736660035,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/northbourne-rd-at-hyde-park-ave_2020-05-22.jpg"
      }
    ],
    "firstSeen": 1566524340,
    "oldId": "C32078",
    "hashId": "87b5a8b7-a330-4c51-ab32-7dafa292c88f",
    "newId": "87b5a8b7-a330-4c51-ab32-7dafa292c88f"
  },
  {
    "id": "422",
    "name": "American Legion Hwy at Cummins Hwy",
    "lat": 42.278811581565975,
    "long": -71.11687703062671,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/american-legion-hwy-at-canterbury-st_2020-05-22.jpg"
      }
    ],
    "firstSeen": 1566537710,
    "oldId": "C32079",
    "hashId": "d1c0b0c2-6e4c-424e-a946-a2a95a711633",
    "newId": "d1c0b0c2-6e4c-424e-a946-a2a95a711633"
  },
  {
    "id": "423",
    "name": "Mt. Hope St at Hyde Park Ave",
    "lat": 42.28484472443536,
    "long": -71.11874517062459,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/mt-hope-st-at-hyde-park-ave_2020-05-22.jpg"
      }
    ],
    "firstSeen": 1566677007,
    "oldId": "C32080",
    "hashId": "9f54207a-3308-43bd-b45c-9042b6389598",
    "newId": "9f54207a-3308-43bd-b45c-9042b6389598"
  },
  {
    "id": "424",
    "name": "Jamaica St at South St",
    "lat": 42.3060456272408,
    "long": -71.11570891001975,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/jamaica-st-at-south-st_2020-05-22.jpg"
      }
    ],
    "firstSeen": 1566941915,
    "oldId": "D32052",
    "hashId": "c5017e67-cada-4549-a52e-0f13dddd289d",
    "newId": "c5017e67-cada-4549-a52e-0f13dddd289d"
  },
  {
    "id": "425",
    "name": "The Dimock Center",
    "lat": 42.3193094317828,
    "long": -71.09639923859504,
    "photos": [
      {
        "date": "2020-08-01",
        "photo": "photos/the-dimock-center_2020-08-01.jpg"
      }
    ],
    "firstSeen": 1566934380,
    "oldId": "E32011",
    "hashId": "ec4e1168-25dc-4929-b17b-e4ae578dd888",
    "newId": "ec4e1168-25dc-4929-b17b-e4ae578dd888"
  },
  {
    "id": "426",
    "name": "Surface Rd at Summer St",
    "lat": 42.35294569782581,
    "long": -71.05656400803126,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/surface-rd-at-summer-st_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1567225957,
    "oldId": "A32042",
    "hashId": "2363c5a3-93cc-41a2-a767-90f1826c041f",
    "newId": "2363c5a3-93cc-41a2-a767-90f1826c041f"
  },
  {
    "id": "427",
    "name": "Washington St at Denton Terr",
    "lat": 42.280728147169015,
    "long": -71.13423757273267,
    "photos": [
      {
        "date": "2020-06-21",
        "photo": "photos/washington-st-at-denton-terr_2020-06-21.jpg"
      }
    ],
    "firstSeen": 1567128012,
    "oldId": "C32081",
    "hashId": "acb27827-d69a-4287-b132-3f529ca62ad2",
    "newId": "acb27827-d69a-4287-b132-3f529ca62ad2"
  },
  {
    "id": "428",
    "name": "Western Ave at Richardson St",
    "lat": 42.361787410661876,
    "long": -71.14393111327445,
    "photos": [
      {
        "date": "2020-05-17",
        "photo": "photos/western-ave-at-richardson-st_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1567112307,
    "oldId": "A32043",
    "hashId": "2130e322-42a2-44ef-bbec-6a4fbf179fd9",
    "newId": "2130e322-42a2-44ef-bbec-6a4fbf179fd9"
  },
  {
    "id": "430",
    "name": "Washington St at Walsh Playground",
    "lat": 42.277194696397906,
    "long": -71.06955613950048,
    "discovered": 1571916631381,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/washington-st-at-walsh-playground_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1567644372,
    "oldId": "C32083",
    "hashId": "12d463d2-ce50-43bd-81b1-6be4dbdbbc1b",
    "newId": "12d463d2-ce50-43bd-81b1-6be4dbdbbc1b"
  },
  {
    "id": "431",
    "name": "Washington St at Fuller St",
    "lat": 42.281986277339136,
    "long": -71.07147924620222,
    "discovered": 1571916631381,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/washington-st-at-fuller-st_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1567876415,
    "oldId": "C32084",
    "hashId": "9bfa4a32-5598-46ea-a1a1-17b4c6ed9d84",
    "newId": "9bfa4a32-5598-46ea-a1a1-17b4c6ed9d84"
  },
  {
    "id": "432",
    "name": "Centre St at W. Roxbury Post Office",
    "lat": 42.286331991127746,
    "long": -71.15344755369732,
    "discovered": 1571916631381,
    "photos": [
      {
        "date": "2020-06-21",
        "photo": "photos/centre-st-at-w-roxbury-post-office_2020-06-21.jpg"
      }
    ],
    "firstSeen": 1567723561,
    "oldId": "C32085",
    "hashId": "0cb6d635-6a7d-4d75-89b4-36b12616be12",
    "newId": "0cb6d635-6a7d-4d75-89b4-36b12616be12"
  },
  {
    "id": "433",
    "name": "Centre St at Parkway YMCA",
    "lat": 42.28277901074054,
    "long": -71.15728851416861,
    "discovered": 1571916631381,
    "photos": [
      {
        "date": "2020-06-21",
        "photo": "photos/centre-st-at-parkway-ymca_2020-06-21.jpg"
      }
    ],
    "firstSeen": 1568541133,
    "oldId": "C32086",
    "hashId": "676ca473-eed6-4971-aaf3-e199a7ca335b",
    "newId": "676ca473-eed6-4971-aaf3-e199a7ca335b"
  },
  {
    "id": "434",
    "name": "Spring St at Powell St",
    "lat": 42.277484442008216,
    "long": -71.16341467955863,
    "discovered": 1571916631381,
    "photos": [
      {
        "date": "2020-06-21",
        "photo": "photos/spring-st-at-powell-st_2020-06-21.jpg"
      }
    ],
    "firstSeen": 1568673925,
    "oldId": "C32087",
    "hashId": "90330e1a-dee5-4914-9d3f-3891aeca12ca",
    "newId": "90330e1a-dee5-4914-9d3f-3891aeca12ca"
  },
  {
    "id": "435",
    "name": "Central Ave at River St",
    "lat": 42.27094707029229,
    "long": -71.07337901405117,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-05-24",
        "photo": "photos/river-st-at-central-ave_2020-05-24.jpg"
      }
    ],
    "firstSeen": 1568141869,
    "oldId": "C32088",
    "hashId": "0b0467c1-0c90-4529-a556-fe4d57835bbb",
    "newId": "0b0467c1-0c90-4529-a556-fe4d57835bbb"
  },
  {
    "id": "436",
    "name": "Maverick St at Massport Path",
    "lat": 42.36774122306014,
    "long": -71.0333597474255,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-07-12",
        "photo": "photos/maverick-st-at-massport-path_2020-07-12.jpg"
      }
    ],
    "firstSeen": 1568232175,
    "oldId": "A32044",
    "hashId": "8d535c23-f186-4161-aa10-17e94be6d4ae",
    "newId": "8d535c23-f186-4161-aa10-17e94be6d4ae"
  },
  {
    "id": "437",
    "name": "Berkshire Street at Cambridge Street",
    "lat": 42.37207657915397,
    "long": -71.08995433905875,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-06-14",
        "photo": "photos/berkshire-st-at-cambridge-st_2020-06-14.jpg"
      }
    ],
    "firstSeen": 1568302329,
    "oldId": "M32067",
    "hashId": "9fef6df2-7f8f-43fc-a85b-7dd5453860f9",
    "newId": "9fef6df2-7f8f-43fc-a85b-7dd5453860f9"
  },
  {
    "id": "440",
    "name": "Boston Landing",
    "lat": 42.356560923089404,
    "long": -71.14167537549292,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-05-17",
        "photo": "photos/boston-landing_2020-05-17.jpg"
      }
    ],
    "firstSeen": 1571262171,
    "oldId": "A32045",
    "hashId": "20b0b405-54ba-4a80-b89c-0819772665db",
    "newId": "20b0b405-54ba-4a80-b89c-0819772665db"
  },
  {
    "id": "441",
    "name": "Sullivan Square",
    "lat": 42.384452256358436,
    "long": -71.07514854529654,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-05-03",
        "photo": "photos/sullivan-circle-mbta_2020-05-03.jpg"
      }
    ],
    "firstSeen": 1572912569,
    "oldId": "D32053",
    "hashId": "40ee059e-8530-4992-9114-c16b87d6832f",
    "newId": "40ee059e-8530-4992-9114-c16b87d6832f"
  },
  {
    "id": "442",
    "name": "Hyde Park Ave at Walk Hill St",
    "lat": 42.29606704649873,
    "long": -71.11601199963843,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-05-22",
        "photo": "photos/hyde-park-ave-at-walk-hill-st_2020-05-22.jpg"
      }
    ],
    "firstSeen": 1571356140,
    "oldId": "C32089",
    "hashId": "ff6ed364-8676-45a0-b97f-71025683849e",
    "newId": "ff6ed364-8676-45a0-b97f-71025683849e"
  },
  {
    "id": "443",
    "name": "Whittier St Health Center",
    "lat": 42.332862884862635,
    "long": -71.09218861916816,
    "discovered": 1571916631382,
    "photos": [
      {
        "date": "2020-07-24",
        "photo": "photos/whittier-st-health-center_2020-07-24.jpg"
      }
    ],
    "firstSeen": 1571333160,
    "oldId": "B32059",
    "hashId": "82b41ffb-e284-4e4d-b22a-0cad2bfd4bee",
    "newId": "82b41ffb-e284-4e4d-b22a-0cad2bfd4bee"
  },
  {
    "id": "445",
    "name": "Geiger Gibson Community Health Center",
    "lat": 42.31886468217537,
    "long": -71.04536797860419,
    "discovered": 1578755015509,
    "photos": [
      {
        "date": "2020-06-07",
        "photo": "photos/geiger-gibson-community-health-center_2020-06-07.jpg"
      }
    ],
    "firstSeen": 1572523931,
    "oldId": "C32090",
    "hashId": "7ec0c53c-876a-419c-a78b-4edd000bbc9a",
    "newId": "7ec0c53c-876a-419c-a78b-4edd000bbc9a"
  },
  {
    "id": "446",
    "name": "700 Commonwealth Ave.",
    "lat": 42.34960945333059,
    "long": -71.10391523698127,
    "discovered": 1578755015509,
    "photos": [
      {
        "date": "2020-05-31",
        "photo": "photos/700-commonwealth-ave_2020-05-31.jpg"
      }
    ],
    "firstSeen": 1572455584,
    "oldId": "B32060",
    "hashId": "a6e0db4f-fc9b-42af-bfe0-36cc0259b717",
    "newId": "a6e0db4f-fc9b-42af-bfe0-36cc0259b717"
  },
  {
    "id": "447",
    "name": "The Overlook at St. Gabriel's",
    "lat": 42.34679008347347,
    "long": -71.14549813806661,
    "discovered": 1592690778175,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/the-overlook-at-st-gabriels_2020-07-03.jpg"
      }
    ],
    "firstSeen": 1591444112,
    "oldId": "D32054",
    "hashId": "2ba0c5dd-c990-4434-8a84-5c3eca23f096",
    "newId": "2ba0c5dd-c990-4434-8a84-5c3eca23f096"
  },
  {
    "id": "448",
    "name": "Hale Park",
    "lat": 42.4206487,
    "long": -71.0437126,
    "discovered": 1592690778175,
    "photos": [
      {
        "date": "2020-06-20",
        "photo": "photos/hale-park_2020-06-20.jpg"
      }
    ],
    "firstSeen": 1591988301,
    "oldId": "V32012",
    "hashId": "228b8017-e4da-4722-9223-b8279a14b8ad",
    "newId": "228b8017-e4da-4722-9223-b8279a14b8ad"
  },
  {
    "id": "450",
    "name": "Beacon St at Englewood Ave",
    "lat": 42.337369153709254,
    "long": -71.14485977232107,
    "discovered": 1593815150850,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/beacon-st-at-englewood-ave_2020-07-03.jpg"
      }
    ],
    "oldId": "K32013",
    "isLegacy": true,
    "firstSeen": 1593624980
  },
  {
    "id": "451",
    "name": "Washington Sq",
    "lat": 42.33940659197945,
    "long": -71.13489642273635,
    "discovered": 1593815150850,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/washington-sq_2020-07-03.jpg"
      }
    ],
    "oldId": "K32014",
    "isLegacy": true,
    "firstSeen": 1593806058
  },
  {
    "id": "452",
    "name": "1200 Beacon St",
    "lat": 42.34414898749529,
    "long": -71.11467361450195,
    "discovered": 1593815150850,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/beacon-st-at-st-paul-st_2020-07-03.jpg"
      }
    ],
    "oldId": "K32015",
    "hashId": "a83f982f-a4f1-43e4-98e8-c8f4a0bb7290",
    "firstSeen": 1593716325,
    "newId": "a83f982f-a4f1-43e4-98e8-c8f4a0bb7290"
  },
  {
    "id": "453",
    "name": "Beacon St at Hawes St",
    "lat": 42.34496783328529,
    "long": -71.11040176903771,
    "discovered": 1593815150850,
    "photos": [
      {
        "date": "2020-07-03",
        "photo": "photos/beacon-st-at-hawes-st_2020-07-03.jpg"
      }
    ],
    "oldId": "K32016",
    "isLegacy": true,
    "firstSeen": 1593722549
  },
  {
    "id": "454",
    "name": "Boylston St at Dartmouth St",
    "lat": 42.35019319808638,
    "long": -71.07744187116623,
    "discovered": 1596464609909,
    "photos": [
      {
        "date": "2018-06-23",
        "photo": "photos/boylston-st-at-dartmouth-st_2018-06-23.jpg"
      }
    ],
    "firstSeen": 1383067380,
    "mergedLegacyStationInfo": {
      "id": 134,
      "name": "Boylston St at Dartmouth St",
      "lat": 42.350413,
      "long": -71.07655,
      "oldId": "D32027"
    },
    "hashId": "2fd22786-dfeb-4126-a906-4f01802087f3",
    "newId": "2fd22786-dfeb-4126-a906-4f01802087f3"
  },
  {
    "id": "455",
    "name": "Coolidge Sq.",
    "lat": 42.372076114722866,
    "long": -71.15683130688922,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/coolidge-sq_2020-08-08.jpg"
      }
    ],
    "discovered": 1596464609909,
    "hashId": "634b697d-0e64-4102-8849-abac7a7f8e35",
    "firstSeen": 1596382352,
    "newId": "634b697d-0e64-4102-8849-abac7a7f8e35"
  },
  {
    "id": "456",
    "name": "Graham and Parks School – Linnaean St at Walker St",
    "lat": 42.38294430172271,
    "long": -71.12449831038248,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/graham-and-parks-school-linnaean-st-at-walker-st_2020-08-08.jpg"
      }
    ],
    "discovered": 1596897018218,
    "hashId": "f606593b-3d07-40f2-bc6d-a0eb96588e44",
    "firstSeen": 1596654789,
    "newId": "f606593b-3d07-40f2-bc6d-a0eb96588e44"
  },
  {
    "id": "457",
    "name": "N. Beacon St at N. Beacon Ct",
    "lat": 42.36444157139109,
    "long": -71.18091373646166,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/n-beacon-st-at-n-beacon-ct_2020-08-08.jpg"
      }
    ],
    "discovered": 1596897018218,
    "hashId": "fa1f0fe1-355a-4ab4-9414-8aa168317295",
    "firstSeen": 1596646953,
    "isLegacy": true
  },
  {
    "id": "458",
    "name": "Watertown Sq",
    "lat": 42.365260317694485,
    "long": -71.18573324459247,
    "photos": [
      {
        "date": "2020-08-08",
        "photo": "photos/watertown-sq_2020-08-08.jpg"
      },
      {
        "date": "2021-01-02",
        "photo": "photos/watertown-sq_2021-01-02.jpg"
      }
    ],
    "discovered": 1596897018218,
    "hashId": "66763558-7a03-48a9-8665-6b4445b81f48",
    "firstSeen": 1596645589,
    "newId": "66763558-7a03-48a9-8665-6b4445b81f48"
  },
  {
    "id": "459",
    "name": "Canal St at Causeway St",
    "lat": 42.36530094929082,
    "long": -71.06092190993877,
    "photos": [
      {
        "date": "2020-09-06",
        "photo": "photos/canal-st-at-causeway-st_2020-09-06.jpg"
      }
    ],
    "discovered": 1599664086476,
    "hashId": "cf5f920f-b61d-4db0-b139-14aff04d577c",
    "firstSeen": 1597862780,
    "newId": "cf5f920f-b61d-4db0-b139-14aff04d577c"
  },
  {
    "id": "460",
    "name": "Broad St at Central St",
    "lat": 42.35849449039304,
    "long": -71.05426469875965,
    "photos": [
      {
        "date": "2020-09-06",
        "photo": "photos/broad-st-at-central-st_2020-09-06.jpg"
      }
    ],
    "discovered": 1599664086476,
    "hashId": "5afe91c4-8076-4f9f-b83b-cf233bda2331",
    "firstSeen": 1598384482,
    "newId": "5afe91c4-8076-4f9f-b83b-cf233bda2331"
  },
  {
    "id": "461",
    "name": "Railroad Lot and Minuteman Bikeway",
    "lat": 42.41606457115141,
    "long": -71.15336636682059,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/railroad-lot-and-minuteman-bikeway_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "81323081-749c-44cf-ac62-0e6c64305c32",
    "firstSeen": 1599767787,
    "newId": "81323081-749c-44cf-ac62-0e6c64305c32"
  },
  {
    "id": "462",
    "name": "Linwood St at Minuteman Bikeway",
    "lat": 42.40935395518158,
    "long": -71.14906518727366,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/linwood-st-at-minuteman-bikeway_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "e4d5271f-5f76-401b-b7f0-c8dce3c26f10",
    "firstSeen": 1599773795,
    "newId": "e4d5271f-5f76-401b-b7f0-c8dce3c26f10"
  },
  {
    "id": "463",
    "name": "Smith Pl at Wilson Rd",
    "lat": 42.39239749145834,
    "long": -71.15058331757609,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/smith-pl-at-wilson-rd_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "38706d8c-8d4b-49f7-b6ed-7688449db261",
    "firstSeen": 1599849865,
    "newId": "38706d8c-8d4b-49f7-b6ed-7688449db261"
  },
  {
    "id": "464",
    "name": "Mass Ave at Grafton St",
    "lat": 42.40726052447951,
    "long": -71.14382132425817,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/mass-ave-at-grafton-st_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "9c8366d8-3b58-4894-b281-09e22f6fe5f7",
    "firstSeen": 1600254006,
    "newId": "9c8366d8-3b58-4894-b281-09e22f6fe5f7"
  },
  {
    "id": "465",
    "name": "Broadway at Grafton St",
    "lat": 42.40994213011757,
    "long": -71.14009319811885,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/broadway-at-grafton-st_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "8e7c1984-dc81-43b0-a0d3-58c6de45225a",
    "firstSeen": 1600259148,
    "newId": "8e7c1984-dc81-43b0-a0d3-58c6de45225a"
  },
  {
    "id": "466",
    "name": "Centre St at Washington St",
    "lat": 42.35574334805774,
    "long": -71.18641942508475,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/centre-st-at-washington-st_2020-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "9f582e05-15e0-4a39-aef5-c719e0c78e26",
    "firstSeen": 1601735653,
    "newId": "9f582e05-15e0-4a39-aef5-c719e0c78e26"
  },
  {
    "id": "467",
    "name": "Washington St at Crafts St",
    "lat": 42.35389024396587,
    "long": -71.19943707257335,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/washington-st-at-crafts-st_2021-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "918335df-fc1a-45c2-9cfc-c5e2e93aecc0",
    "firstSeen": 1601582880,
    "newId": "918335df-fc1a-45c2-9cfc-c5e2e93aecc0"
  },
  {
    "id": "468",
    "name": "Nonantum Library",
    "lat": 42.36068455768156,
    "long": -71.20146199598821,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/nonantum-library_2021-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "ad2fb6f2-921f-440c-83de-08be76e35c2a",
    "firstSeen": 1601640669,
    "newId": "ad2fb6f2-921f-440c-83de-08be76e35c2a"
  },
  {
    "id": "469",
    "name": "Bacon St at Washington St",
    "lat": 42.35711920909866,
    "long": -71.1860453666327,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/bacon-st-at-washington-st_2020-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "01d29f8c-768d-4c4f-84ca-3de966075287",
    "firstSeen": 1601667456,
    "newId": "01d29f8c-768d-4c4f-84ca-3de966075287"
  },
  {
    "id": "470",
    "name": "Union St at Herrick Rd – Newton Centre Green Line",
    "lat": 42.32956868185623,
    "long": -71.19367271661758,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/union-st-at-herrick-rd-newton-centre-green-line_2020-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "fa568ea4-1be4-4992-93ca-9b1ca2ff52b4",
    "firstSeen": 1601569899,
    "isLegacy": true
  },
  {
    "id": "471",
    "name": "MIT Carleton St at Amherst St",
    "lat": 42.36054174140606,
    "long": -71.08669817447662,
    "photos": [
      {
        "date": "2020-12-28",
        "photo": "photos/mit-carleton-st-at-amherst-st_2020-12-28.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "f1cafe36-f800-41f6-b74d-73953cb6394c",
    "firstSeen": 1602094076,
    "newId": "f1cafe36-f800-41f6-b74d-73953cb6394c"
  },
  {
    "id": "472",
    "name": "MIT Hayward St at Amherst St",
    "lat": 42.36085937353302,
    "long": -71.08551858007559,
    "photos": [
      {
        "date": "2020-12-28",
        "photo": "photos/mit-hayward-st-at-amherst-st_2020-12-28.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "533620e8-00ff-494a-b288-bd67602bc98e",
    "firstSeen": 1602095479,
    "newId": "533620e8-00ff-494a-b288-bd67602bc98e"
  },
  {
    "id": "473",
    "name": "West Newton",
    "lat": 42.349600498642076,
    "long": -71.2262749671936,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/west-newton_2021-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "7dd0fc98-c1dd-4c93-9c1d-4bbdf5f93346",
    "firstSeen": 1602329983,
    "newId": "7dd0fc98-c1dd-4c93-9c1d-4bbdf5f93346"
  },
  {
    "id": "474",
    "name": "Watertown St at Albemarle Rd",
    "lat": 42.354104081396585,
    "long": -71.21382214128971,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/west-newton-armory_2021-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "77c1736c-a609-4453-94f7-8e4253a8193c",
    "firstSeen": 1602249357,
    "newId": "77c1736c-a609-4453-94f7-8e4253a8193c"
  },
  {
    "id": "475",
    "name": "Arsenal Yards",
    "lat": 42.36277388549605,
    "long": -71.15764854490408,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/arsenal-yards_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "68e5e79a-3c94-4848-b619-9c0829d7f687",
    "firstSeen": 1602183983,
    "newId": "68e5e79a-3c94-4848-b619-9c0829d7f687"
  },
  {
    "id": "476",
    "name": "Boylston St at Charles St",
    "lat": 42.3525760946701,
    "long": -71.06764405041758,
    "photos": [
      {
        "date": "2017-05-28",
        "photo": "photos/boylston-st-at-arlington-st_2017-05-28.jpg"
      },
      {
        "date": "2018-06-23",
        "photo": "photos/boylston-st-at-arlington-st_2018-06-23.jpg"
      }
    ],
    "discovered": 1605550855975,
    "firstSeen": 1311869700,
    "oldId": "D32007",
    "mergedLegacyStationInfo": {
      "id": 42,
      "name": "Boylston St at Arlington St",
      "lat": 42.35204262229712,
      "long": -71.07057809829712
    },
    "hashId": "1b092d74-4b2d-4804-b6d9-1e21dc886700",
    "isLegacy": true
  },
  {
    "id": "477",
    "name": "Marion St at White St",
    "lat": 42.38066930871317,
    "long": -71.03907315999093,
    "photos": [
      {
        "date": "2021-05-01",
        "photo": "photos/marion-st-at-white-st_2021-05-01.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "9b0aff89-5242-475e-b03b-87c3c3bf51bb",
    "firstSeen": 1602006629,
    "newId": "9b0aff89-5242-475e-b03b-87c3c3bf51bb"
  },
  {
    "id": "478",
    "name": "Gove St at Orleans St",
    "lat": 42.37057753242139,
    "long": -71.03558540113227,
    "photos": [
      {
        "date": "2021-01-10",
        "photo": "photos/gove-st-at-orleans-st_2021-01-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "c935cfb1-ef73-402e-9b86-83aaaac6534d",
    "firstSeen": 1602018728,
    "newId": "c935cfb1-ef73-402e-9b86-83aaaac6534d"
  },
  {
    "id": "479",
    "name": "Galileo Galilei Way at Main Street",
    "lat": 42.363003781560096,
    "long": -71.08974037706501,
    "photos": [
      {
        "date": "2020-12-28",
        "photo": "photos/galileo-galilei-way-at-main-st_2020-12-28.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "13918b3b-9ac5-4968-a74d-e40dd3bc952e",
    "firstSeen": 1602692746,
    "newId": "13918b3b-9ac5-4968-a74d-e40dd3bc952e"
  },
  {
    "id": "480",
    "name": "Thorndike Field at Minuteman Bikeway",
    "lat": 42.40016804853787,
    "long": -71.14456963094199,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/thorndike-field-at-minuteman-bikeway_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "af066ffd-b892-434f-8532-1982fc408780",
    "firstSeen": 1602798800,
    "newId": "af066ffd-b892-434f-8532-1982fc408780"
  },
  {
    "id": "481",
    "name": "645 Summer St",
    "lat": 42.341780891371904,
    "long": -71.03987016504107,
    "photos": [
      {
        "date": "2021-01-16",
        "photo": "photos/645-summer-st_2021-01-16.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "868ccc83-99e5-4faf-96eb-e1fae26b9741",
    "firstSeen": 1602795426,
    "newId": "868ccc83-99e5-4faf-96eb-e1fae26b9741"
  },
  {
    "id": "482",
    "name": "Kearins Playground",
    "lat": 42.406161241322444,
    "long": -71.06041654944418,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/kearins-playground_2021-04-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "e68655be-23d3-46e5-a509-25be677e7542",
    "firstSeen": 1603043705,
    "newId": "e68655be-23d3-46e5-a509-25be677e7542"
  },
  {
    "id": "483",
    "name": "Porzio Park",
    "lat": 42.364119035231525,
    "long": -71.02876782417297,
    "photos": [
      {
        "date": "2021-01-10",
        "photo": "photos/porzio-park_2021-01-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "3b158c7a-a362-43ae-af1f-121b78be32d7",
    "firstSeen": 1603447404,
    "newId": "3b158c7a-a362-43ae-af1f-121b78be32d7"
  },
  {
    "id": "484",
    "name": "Revere Public Library",
    "lat": 42.408088,
    "long": -71.008942,
    "photos": [
      {
        "date": "2021-01-10",
        "photo": "photos/revere-public-library_2021-01-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "7feae3ce-ddf2-4a45-a123-1209c8a9182b",
    "firstSeen": 1604530499,
    "newId": "7feae3ce-ddf2-4a45-a123-1209c8a9182b"
  },
  {
    "id": "485",
    "name": "Revere City Hall",
    "lat": 42.40741,
    "long": -71.0139,
    "photos": [
      {
        "date": "2021-01-10",
        "photo": "photos/revere-city-hall_2021-01-10.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "aa556ad4-0092-4687-96c2-8dbc69addb56",
    "firstSeen": 1603869345,
    "newId": "aa556ad4-0092-4687-96c2-8dbc69addb56"
  },
  {
    "id": "486",
    "name": "Langley Lot",
    "lat": 42.330528,
    "long": -71.192861,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/langley-lot_2020-01-02.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "9340e113-e0b2-48ac-b58a-8b6f001b0a94",
    "firstSeen": 1604195299,
    "newId": "9340e113-e0b2-48ac-b58a-8b6f001b0a94"
  },
  {
    "id": "487",
    "name": "Community Path at Lowell St",
    "lat": 42.392525,
    "long": -71.105587,
    "discovered": 1605550855975,
    "photos": [
      {
        "date": "2020-12-31",
        "photo": "photos/community-path-at-lowell-st_2020-12-31.jpg"
      }
    ],
    "hashId": "380de8b2-2728-4e4b-83f1-251bfbb5acf2",
    "firstSeen": 1603921292,
    "newId": "380de8b2-2728-4e4b-83f1-251bfbb5acf2"
  },
  {
    "id": "488",
    "name": "Martha Eliot Health Center",
    "lat": 42.324173,
    "long": -71.10196,
    "photos": [
      {
        "date": "2021-01-16",
        "photo": "photos/martha-eliot-health-center_2021-01-16.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "aefc33cd-40b9-48da-8b87-415428816bdb",
    "firstSeen": 1604343249,
    "newId": "aefc33cd-40b9-48da-8b87-415428816bdb"
  },
  {
    "id": "489",
    "name": "Day Sq",
    "lat": 42.379295,
    "long": -71.027733,
    "photos": [
      {
        "date": "2021-01-10",
        "photo": "photos/day-sq_2021-01-10.jpg"
      },
      {
        "date": "2021-05-01",
        "photo": "photos/day-sq_2021-05-01.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "f55cdf0a-3d7b-491c-bed2-7dc0ef2062d5",
    "firstSeen": 1604159148,
    "newId": "f55cdf0a-3d7b-491c-bed2-7dc0ef2062d5"
  },
  {
    "id": "490",
    "name": "Blue Hill Ave at Havelock St",
    "lat": 42.286012,
    "long": -71.090986,
    "photos": [
      {
        "date": "2021-04-07",
        "photo": "photos/blue-hill-ave-at-havelock-st_2021-04-07.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "065e0e5d-8a0d-49b7-9c1f-c8eaba51a8b4",
    "firstSeen": 1604584651,
    "newId": "065e0e5d-8a0d-49b7-9c1f-c8eaba51a8b4"
  },
  {
    "id": "491",
    "name": "Harrison Ave at E. Dedham St",
    "lat": 42.339194,
    "long": -71.06975,
    "photos": [
      {
        "date": "2021-01-16",
        "photo": "photos/harrison-ave-at-e-dedham-st_2021-01-16.jpg"
      }
    ],
    "discovered": 1605550855975,
    "hashId": "50cc3c7f-50ed-4c70-b483-c89f3f47c938",
    "firstSeen": 1605208203,
    "newId": "50cc3c7f-50ed-4c70-b483-c89f3f47c938"
  },
  {
    "hashId": "0f554e1d-9f0f-452d-9b4a-e00aa2c5911e",
    "id": "492",
    "name": "Harvard Stadium: N. Harvard St at Soldiers Field Rd",
    "lat": 42.368019,
    "long": -71.1242,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/harvard-stadium-n-harvard-st-at-soldiers-field-rd_2021-04-10.jpg"
      }
    ],
    "discovered": 1608825231848,
    "firstSeen": 1605640672,
    "newId": "0f554e1d-9f0f-452d-9b4a-e00aa2c5911e"
  },
  {
    "hashId": "efd724b2-31cb-4aec-b9d9-8ab79271630c",
    "id": "493",
    "name": "2 Hummingbird Lane at Olmsted Green",
    "lat": 42.28887,
    "long": -71.095003,
    "photos": [
      {
        "date": "2021-04-07",
        "photo": "photos/2-hummingbird-lane-at-olmsted-green_2021-04-07.jpg"
      }
    ],
    "discovered": 1608825231848,
    "firstSeen": 1608993693,
    "newId": "efd724b2-31cb-4aec-b9d9-8ab79271630c"
  },
  {
    "hashId": "518f6524-0e7f-454d-974e-e3ff9db1f795",
    "id": "494",
    "name": "Watertown Town Hall",
    "lat": 42.36759307632294,
    "long": -71.18751436471939,
    "photos": [
      {
        "date": "2021-01-02",
        "photo": "photos/watertown-town-hall_2021-01-02.jpg"
      }
    ],
    "discovered": 1608825231848,
    "firstSeen": 1607435868,
    "newId": "518f6524-0e7f-454d-974e-e3ff9db1f795"
  },
  {
    "hashId": "569f4952-5a25-4394-a419-f9c4d9cca6f3",
    "id": "495",
    "name": "Union Square East",
    "lat": 42.380078,
    "long": -71.090098,
    "photos": [
      {
        "date": "2020-12-31",
        "photo": "photos/union-square-east-temp-winter-station_2020-12-31.jpg"
      }
    ],
    "discovered": 1608916119712,
    "firstSeen": 1607552976,
    "isLegacy": true,
    "newId": "569f4952-5a25-4394-a419-f9c4d9cca6f3"
  },
  {
    "hashId": "603f7f65-e15c-44d7-9bce-581b5fda9a61",
    "id": "496",
    "name": "Trum Field @ Cedar St (Temp Winter Station)",
    "lat": 42.398444,
    "long": -71.107833,
    "photos": [
      {
        "date": "2020-12-31",
        "photo": "photos/trum-field-at-cedar-st-temp-winter-station_2020-12-31.jpg"
      }
    ],
    "discovered": 1608916119712,
    "firstSeen": 1607548319,
    "isLegacy": true
  },
  {
    "hashId": "d83f89ab-27f7-471e-a84b-e1a4d1582deb",
    "id": "497",
    "name": "Washington St @ New Washington St (Temp Winter Station)",
    "lat": 42.38103731531107,
    "long": -71.08709779349738,
    "photos": [
      {
        "date": "2020-12-31",
        "photo": "photos/washington-st-at-new-washington-st-temp-winter-station_2020-12-31.jpg"
      }
    ],
    "discovered": 1608916119712,
    "firstSeen": 1607546458,
    "isLegacy": true
  },
  {
    "hashId": "b2b39507-9abd-41af-a5b2-b6e2ed8c3c4a",
    "id": "498",
    "name": "Broadway Opposite Norwood Ave  (Temp Winter Station)",
    "lat": 42.396764,
    "long": -71.102376,
    "photos": [
      {
        "date": "2020-12-31",
        "photo": "photos/broadway-opposite-norwood-ave-temp-winter-station_2020-12-31.jpg"
      }
    ],
    "discovered": 1608916119712,
    "firstSeen": 1607576675,
    "isLegacy": true
  },
  {
    "hashId": "b32131a5-0795-450f-ac27-0b5f4b56551d",
    "id": "499",
    "name": "Conway Park @ Bleachery Ct (Temp Winter Station)",
    "lat": 42.3834575023727,
    "long": -71.10771059989929,
    "photos": [
      {
        "date": "2020-12-31",
        "photo": "photos/conway-park-at-bleachery-ct-temp-winter-station_2020-12-31.jpg"
      }
    ],
    "discovered": 1608916119712,
    "firstSeen": 1607571089,
    "isLegacy": true
  },
  {
    "hashId": "1ef5abb2-5af9-41f2-a0dd-1e44278ad9f1",
    "id": "502",
    "name": "160 Arsenal",
    "lat": 42.36466403441681,
    "long": -71.17569386959076,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/160-arsenal_2021-04-10.jpg"
      }
    ],
    "discovered": 1615743887554,
    "firstSeen": 1615047831,
    "newId": "1ef5abb2-5af9-41f2-a0dd-1e44278ad9f1"
  },
  {
    "hashId": "f35de2ab-979e-42d5-8cbd-dc479ed637e9",
    "id": "503",
    "name": "Cary Square",
    "lat": 42.398135645582464,
    "long": -71.030709722545,
    "photos": [
      {
        "date": "2021-04-03",
        "photo": "photos/cary-square_2021-04-03.jpg"
      }
    ],
    "discovered": 1615743887554,
    "firstSeen": 1614913878,
    "newId": "f35de2ab-979e-42d5-8cbd-dc479ed637e9"
  },
  {
    "hashId": "e49a5b83-2c9c-4610-a48b-89cfea172c8f",
    "id": "504",
    "name": "Bellingham Square",
    "lat": 42.3931961102951,
    "long": -71.03344559669495,
    "photos": [
      {
        "date": "2021-04-03",
        "photo": "photos/bellingham-square_2021-04-03.jpg"
      }
    ],
    "discovered": 1615743887554,
    "firstSeen": 1614815037,
    "newId": "e49a5b83-2c9c-4610-a48b-89cfea172c8f"
  },
  {
    "hashId": "f36e24cd-de4c-4a5e-adf4-cb97ccd2958d",
    "id": "505",
    "name": "Chelsea Square",
    "lat": 42.390226711033826,
    "long": -71.03857017704286,
    "photos": [
      {
        "date": "2021-04-03",
        "photo": "photos/chelsea-square_2021-04-03.jpg"
      }
    ],
    "discovered": 1615743887554,
    "firstSeen": 1614845388,
    "newId": "f36e24cd-de4c-4a5e-adf4-cb97ccd2958d"
  },
  {
    "hashId": "a5144f51-37b3-4404-8f9c-8e6d126d5ca6",
    "id": "506",
    "name": "Broadway at Gerrish Ave",
    "lat": 42.39453079292221,
    "long": -71.03085594484583,
    "photos": [
      {
        "date": "2021-04-03",
        "photo": "photos/broadway-at-gerrish-ave_2021-04-03.jpg"
      }
    ],
    "discovered": 1615743887554,
    "firstSeen": 1614997689,
    "newId": "a5144f51-37b3-4404-8f9c-8e6d126d5ca6"
  },
  {
    "hashId": "1a661bb7-7d14-49e9-ac3d-22bafec8a2f0",
    "id": "507",
    "name": "Chelsea Station",
    "lat": 42.39636527849676,
    "long": -71.03987179230899,
    "photos": [
      {
        "date": "2021-04-03",
        "photo": "photos/chelsea-station_2021-04-03.jpg"
      }
    ],
    "discovered": 1615743887554,
    "firstSeen": 1615151515,
    "newId": "1a661bb7-7d14-49e9-ac3d-22bafec8a2f0"
  },
  {
    "hashId": "199f3d59-e726-4549-b9c1-4dfc8a343fdb",
    "id": "508",
    "name": "855 Broadway",
    "lat": 42.398417726101584,
    "long": -71.02390923402707,
    "photos": [
      {
        "date": "2021-04-03",
        "photo": "photos/855-broadway_2021-04-03.jpg"
      }
    ],
    "discovered": 1617809221531,
    "firstSeen": 1616025613,
    "newId": "199f3d59-e726-4549-b9c1-4dfc8a343fdb"
  },
  {
    "hashId": "d5349097-b478-4b3f-9c46-d8fbcf38e3f4",
    "id": "509",
    "name": "Nichols Ave. at Watertown Greenway",
    "lat": 42.36762048745005,
    "long": -71.1565663665533,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/nichols-ave-at-watertown-greenway_2021-04-10.jpg"
      }
    ],
    "discovered": 1617809221531,
    "firstSeen": 1616877248,
    "newId": "d5349097-b478-4b3f-9c46-d8fbcf38e3f4"
  },
  {
    "hashId": "04d40632-aaf2-49f2-a709-95c2974a7838",
    "id": "510",
    "name": "Mass Ave at Broadway",
    "lat": 42.414202132001854,
    "long": -71.15051883389242,
    "photos": [
      {
        "date": "2021-04-10",
        "photo": "photos/mass-ave-at-broadway_2021-04-10.jpg"
      }
    ],
    "discovered": 1618099535176,
    "firstSeen": 1617926999,
    "newId": "04d40632-aaf2-49f2-a709-95c2974a7838"
  },
  {
    "hashId": "1206f5c8-7d1f-4c32-9d65-f3e80186fbde",
    "id": "511",
    "name": "Bellevue T Stop",
    "lat": 42.28611420958351,
    "long": -71.14636927843094,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/bellevue-t-stop_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1622687038,
    "newId": "1206f5c8-7d1f-4c32-9d65-f3e80186fbde"
  },
  {
    "hashId": "d7507124-4cab-4063-8445-a59a33a3d6e4",
    "id": "512",
    "name": "Knoll St at Centre St",
    "lat": 42.29376656473529,
    "long": -71.1365658044815,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/knoll-st-at-centre-st_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1622772983,
    "newId": "d7507124-4cab-4063-8445-a59a33a3d6e4"
  },
  {
    "hashId": "66b0f341-a44a-4fa8-b04c-89a38de2950f",
    "id": "513",
    "name": "Roseland St At Dorchester Ave",
    "lat": 42.2911685327193,
    "long": -71.06300890445709,
    "discovered": 1650214155461,
    "firstSeen": 1623449568,
    "newId": "66b0f341-a44a-4fa8-b04c-89a38de2950f"
  },
  {
    "hashId": "57716f8d-c9ee-4768-8872-71343ec3ffd8",
    "id": "514",
    "name": "Gilman Square T at Medford St",
    "lat": 42.3867355,
    "long": -71.0946444,
    "photos": [
      {
        "date": "2022-08-18",
        "photo": "photos/gilman-square-t-at-medford-st_2022-08-18.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1625305729,
    "newId": "57716f8d-c9ee-4768-8872-71343ec3ffd8"
  },
  {
    "hashId": "00802fe6-4268-4dc8-8f55-0dbbc1665ce6",
    "id": "515",
    "name": "955 Mass Ave",
    "lat": 42.36895180540729,
    "long": -71.10998779535294,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/955-mass-ave_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1623362344,
    "newId": "00802fe6-4268-4dc8-8f55-0dbbc1665ce6"
  },
  {
    "hashId": "5f726a7d-5c5d-48b1-9b13-06c77572a237",
    "id": "516",
    "name": "Salem Ferry Landing",
    "lat": 42.52189284110247,
    "long": -70.8806911110878,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/salem-ferry-landing_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1623880404,
    "newId": "5f726a7d-5c5d-48b1-9b13-06c77572a237"
  },
  {
    "hashId": "ef6ef93b-488f-496b-afbf-3aedfd967129",
    "id": "517",
    "name": "Salem Willows Park",
    "lat": 42.53466911383265,
    "long": -70.87021440267563,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/salem-willows-park_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1623891789,
    "newId": "ef6ef93b-488f-496b-afbf-3aedfd967129"
  },
  {
    "hashId": "57f5003b-6574-40b3-83d7-3b5dee1472e0",
    "id": "518",
    "name": "Shetland Park - Congress at Peabody St",
    "lat": 42.51836788262243,
    "long": -70.88952362537384,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/shetland-park-congress-at-peabody-st_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1623890588,
    "newId": "57f5003b-6574-40b3-83d7-3b5dee1472e0"
  },
  {
    "hashId": "ee18dbd6-05b8-4d38-a120-f84c3499a77e",
    "id": "519",
    "name": "Mayor Salvo Path at Mill St",
    "lat": 42.51803519796769,
    "long": -70.89570075273514,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/mayor-salvo-path-at-mill-st_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1623889743,
    "newId": "ee18dbd6-05b8-4d38-a120-f84c3499a77e"
  },
  {
    "hashId": "40e58976-02cc-4c7c-911f-7a6c97588bfa",
    "id": "520",
    "name": "Salem MBTA - Washington at Federal St",
    "lat": 42.52345239346618,
    "long": -70.89611113071442,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/salem-mbta-washington-at-federal-st_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1623880906,
    "newId": "40e58976-02cc-4c7c-911f-7a6c97588bfa"
  },
  {
    "hashId": "5f8ced09-665c-41e5-b67e-1e58fac21830",
    "id": "521",
    "name": "Lafayette at Leach St",
    "lat": 42.51142784358897,
    "long": -70.89183300733566,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/lafayette-at-leach-st_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1624041695,
    "newId": "5f8ced09-665c-41e5-b67e-1e58fac21830"
  },
  {
    "hashId": "c111ef1a-f276-415d-a95e-8e3c4e3a0c03",
    "id": "522",
    "name": "Salem State University - Bike Path at Loring Ave",
    "lat": 42.50182398896298,
    "long": -70.89478075504303,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/salem-state-university-bike-path-at-loring-ave_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1624063775,
    "newId": "c111ef1a-f276-415d-a95e-8e3c4e3a0c03"
  },
  {
    "hashId": "ccd6fdd4-b39e-46d7-b43c-7bacffb6bd87",
    "id": "523",
    "name": "Boston College T",
    "lat": 42.33987891612856,
    "long": -71.16708934307098,
    "discovered": 1650214155461,
    "firstSeen": 1624244679,
    "newId": "ccd6fdd4-b39e-46d7-b43c-7bacffb6bd87"
  },
  {
    "hashId": "3c837bc0-fd08-49c4-adf5-0e9a98e918a7",
    "id": "524",
    "name": "Newton Highlands T",
    "lat": 42.322065656952944,
    "long": -71.2063729763031,
    "discovered": 1650214155461,
    "firstSeen": 1624184857,
    "newId": "3c837bc0-fd08-49c4-adf5-0e9a98e918a7"
  },
  {
    "hashId": "3de6e5da-9702-4623-8683-26abd98012a8",
    "id": "525",
    "name": "California at Chapel",
    "lat": 42.365148708129546,
    "long": -71.20236039161682,
    "discovered": 1650214155461,
    "firstSeen": 1624480071,
    "newId": "3de6e5da-9702-4623-8683-26abd98012a8"
  },
  {
    "hashId": "dd8dee9d-d044-41d6-9371-8463cf9c671b",
    "id": "526",
    "name": "Beacon at Walnut",
    "lat": 42.330062324402995,
    "long": -71.20668411254883,
    "discovered": 1650214155461,
    "firstSeen": 1624573976,
    "newId": "dd8dee9d-d044-41d6-9371-8463cf9c671b"
  },
  {
    "hashId": "d8ce5f8b-2440-45e1-ab1c-23cb3bcbde84",
    "id": "527",
    "name": "Newton Library",
    "lat": 42.336203368830134,
    "long": -71.207794547081,
    "discovered": 1650214155461,
    "firstSeen": 1624731529,
    "newId": "d8ce5f8b-2440-45e1-ab1c-23cb3bcbde84"
  },
  {
    "hashId": "903b9b5d-4244-43e4-8441-0d17f9a994f7",
    "id": "528",
    "name": "Washington St at Walnut St",
    "lat": 42.351458116963016,
    "long": -71.20764970779419,
    "discovered": 1650214155461,
    "firstSeen": 1625426232,
    "newId": "903b9b5d-4244-43e4-8441-0d17f9a994f7"
  },
  {
    "hashId": "a3b94ed8-8301-4ec6-baf2-64ac493b5106",
    "id": "529",
    "name": "One Beacon St",
    "lat": 42.358477644348106,
    "long": -71.06135129928589,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/one-beacon-st_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1625781775,
    "newId": "a3b94ed8-8301-4ec6-baf2-64ac493b5106"
  },
  {
    "hashId": "0bc49982-74e9-4bbf-9991-e3d809d6319e",
    "id": "530",
    "name": "Foley St at Grand Union Blvd",
    "lat": 42.39301840035393,
    "long": -71.08071684837341,
    "photos": [
      {
        "date": "2022-08-18",
        "photo": "photos/foley-st-at-grand-union-blvd_2022-08-18.jpg"
      },
      {
        "date": "2023-08-27",
        "photo": "photos/foley-st-at-grand-union-blvd_2023-08-27.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1625789417,
    "newId": "0bc49982-74e9-4bbf-9991-e3d809d6319e"
  },
  {
    "hashId": "a439fff7-8630-464c-b277-efca6f6bc664",
    "id": "531",
    "name": "Harvard St and Stedman St",
    "lat": 42.3443517550051,
    "long": -71.12490355968475,
    "discovered": 1650214155461,
    "firstSeen": 1627080319,
    "newId": "a439fff7-8630-464c-b277-efca6f6bc664"
  },
  {
    "hashId": "940638c5-7cd0-4a11-899e-86af3868d400",
    "id": "532",
    "name": "Concord Ave at Spinelli Place",
    "photos": [
      {
        "date": "2023-04-08",
        "photo": "photos/concord-ave-at-spinelli-place_2023-04-08.jpg"
      }
    ],
    "lat": 42.390719,
    "long": -71.1538877,
    "discovered": 1650214155461,
    "firstSeen": 1627430291,
    "newId": "940638c5-7cd0-4a11-899e-86af3868d400"
  },
  {
    "hashId": "a9d13461-7f0f-47b0-9a9f7-8ec9e9792865",
    "id": "533",
    "name": "Danehy Park at New Street",
    "photos": [
      {
        "date": "2023-04-08",
        "photo": "photos/danehy-park-at-new-street_2023-04-08.jpg"
      }
    ],
    "lat": 42.389456,
    "long": -71.139019,
    "discovered": 1650214155461,
    "firstSeen": 1627436138,
    "newId": "a9d13461-7f0f-47b0-9a97-8ec9e9792865"
  },
  {
    "hashId": "d87db7bd-8961-43fb-8cd6-8163c421b6c8",
    "id": "534",
    "name": "Adams Branch Library",
    "lat": 42.286011,
    "long": -71.054689,
    "discovered": 1650214155461,
    "firstSeen": 1627695687,
    "newId": "d87db7bd-8961-43fb-8cd6-8163c421b6c8"
  },
  {
    "hashId": "6e340d8e-45db-4fd9-b6eb-b74ec73b0d3e",
    "id": "535",
    "name": "Auburndale",
    "lat": 42.34805541493346,
    "long": -71.2477594614029,
    "discovered": 1650214155461,
    "firstSeen": 1628263754,
    "newId": "6e340d8e-45db-4fd9-b6eb-b74ec73b0d3e"
  },
  {
    "hashId": "e519df41-c4d7-403a-9afa-a5dd7cdd2d03",
    "id": "536",
    "name": "Clinton St at North St",
    "lat": 42.3607028354884,
    "long": -71.05524927377701,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/clinton-st-at-north-st_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1628136075,
    "newId": "e519df41-c4d7-403a-9afa-a5dd7cdd2d03"
  },
  {
    "hashId": "f836bc28-36a7-4ca6-bba6-0d75333d84bb",
    "id": "538",
    "name": "Circuit Drive at American Legion Hwy",
    "lat": 42.29704126420948,
    "long": -71.09171926975249,
    "discovered": 1650214155461,
    "firstSeen": 1629847701,
    "newId": "f836bc28-36a7-4ca6-bba6-0d75333d84bb"
  },
  {
    "hashId": "2327cb08-fa9f-459b-9ab0-1a319b0f2f4c",
    "id": "539",
    "name": "Walnut Ave at School St",
    "lat": 42.31242399029812,
    "long": -71.09616905450821,
    "discovered": 1650214155461,
    "firstSeen": 1629928025,
    "newId": "2327cb08-fa9f-459b-9ab0-1a319b0f2f4c"
  },
  {
    "hashId": "18e98210-d257-414a-92e3-8347c2cac9a5",
    "id": "540",
    "name": "Sumner St at Shirley Ave",
    "lat": 42.408337058129746,
    "long": -70.99804848432541,
    "discovered": 1650214155461,
    "firstSeen": 1630634537,
    "newId": "18e98210-d257-414a-92e3-8347c2cac9a5"
  },
  {
    "hashId": "e335fa7c-7adf-4360-9bfa-76b18124a56f",
    "id": "541",
    "name": "Orr Sq (Shirley Ave)",
    "lat": 42.40715655698643,
    "long": -70.99272429943085,
    "discovered": 1650214155461,
    "firstSeen": 1629994993,
    "newId": "e335fa7c-7adf-4360-9bfa-76b18124a56f"
  },
  {
    "hashId": "a6d6d4b2-3666-43ad-a690-7f676bdcc3fd",
    "id": "542",
    "name": "Hyde Park Ave at Arlington St",
    "lat": 42.26286746177123,
    "long": -71.12163126468657,
    "discovered": 1650214155461,
    "firstSeen": 1630362116,
    "newId": "a6d6d4b2-3666-43ad-a690-7f676bdcc3fd"
  },
  {
    "hashId": "5d17f129-af8c-4f30-bc58-6a8279052772",
    "id": "543",
    "name": "American Legion Hwy at Hyde Park Ave",
    "lat": 42.27265394101898,
    "long": -71.119903922081,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/american-legion-hwy-at-hyde-park-ave_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1630443848,
    "newId": "5d17f129-af8c-4f30-bc58-6a8279052772"
  },
  {
    "hashId": "dede6d5f-7d62-496e-a8ca-d5a64afe39c1",
    "id": "544",
    "name": "Old Morse Park at Putnam Ave",
    "lat": 42.35766247889882,
    "long": -71.1084133386612,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/old-morse-park-at-putnam-ave_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1631305024,
    "newId": "dede6d5f-7d62-496e-a8ca-d5a64afe39c1"
  },
  {
    "hashId": "b6a8522d-296d-4c05-887d-842374520780",
    "id": "545",
    "name": "Morton St at Gallivan Blvd",
    "lat": 42.278747,
    "long": -71.080286,
    "discovered": 1650214155461,
    "firstSeen": 1631715704,
    "newId": "b6a8522d-296d-4c05-887d-842374520780"
  },
  {
    "hashId": "ed6c012e-6d6c-41e7-8e01-90749fd67575",
    "id": "546",
    "name": "Cleary Sq",
    "lat": 42.2556,
    "long": -71.12444,
    "discovered": 1650214155461,
    "firstSeen": 1632317927,
    "newId": "ed6c012e-6d6c-41e7-8e01-90749fd67575"
  },
  {
    "hashId": "be18593a-9e4c-4a13-8bb2-a5bac3d9b2b3",
    "id": "547",
    "name": "606 American Legion Hwy at Canterbury St",
    "lat": 42.28578,
    "long": -71.109725,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/606-american-legion-hwy-at-canterbury-st_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1633041438,
    "newId": "be18593a-9e4c-4a13-8bb2-a5bac3d9b2b3"
  },
  {
    "hashId": "b23674a7-15a8-46d2-8219-6668acecf24a",
    "id": "548",
    "name": "555 Metropolitan Ave",
    "lat": 42.2681,
    "long": -71.11924,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/555-metropolitan-avenue_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1635823071,
    "newId": "b23674a7-15a8-46d2-8219-6668acecf24a"
  },
  {
    "hashId": "3a6d94a7-9fee-41c5-b8cc-a8487c5e691b",
    "id": "549",
    "name": "Valenti Way at Haverhill St",
    "lat": 42.36473898691789,
    "long": -71.05934903025626,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/valenti-way-at-haverhill-st_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1633043559,
    "newId": "3a6d94a7-9fee-41c5-b8cc-a8487c5e691b"
  },
  {
    "hashId": "61168d8b-20b7-46c8-8f08-6ae1f100c4e0",
    "id": "550",
    "name": "Somerville High School & Central Library",
    "lat": 42.3864,
    "long": -71.09601,
    "photos": [
      {
        "date": "2022-08-18",
        "photo": "photos/somerville-high-school-and-central-library_2022-08-18.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1634023938,
    "newId": "61168d8b-20b7-46c8-8f08-6ae1f100c4e0"
  },
  {
    "hashId": "fe90d430-d33f-48b2-a5cd-bc161b220939",
    "id": "553",
    "name": "Cambridge Crossing at North First Street",
    "lat": 42.371141,
    "long": -71.076198,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/cambridge-crossing-at-north-first-street_2022-06-20.jpg"
      },
      {
        "date": "2023-01-29",
        "photo": "photos/cambridge-crossing-at-north-first-street_2023-01-29.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1635555789,
    "newId": "fe90d430-d33f-48b2-a5cd-bc161b220939"
  },
  {
    "hashId": "bd1755dc-f26c-420e-85b3-fd025d0b5445",
    "id": "554",
    "name": "Forsyth St at Huntington Ave",
    "lat": 42.339202,
    "long": -71.090511,
    "discovered": 1650214155461,
    "firstSeen": 1638395055,
    "newId": "bd1755dc-f26c-420e-85b3-fd025d0b5445"
  },
  {
    "hashId": "d1a5e406-718c-44b9-b9dd-e63605b888aa",
    "id": "555",
    "name": "Hyde Park Library",
    "lat": 42.2568376,
    "long": -71.122509,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/hyde-park-library_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1641044453,
    "newId": "d1a5e406-718c-44b9-b9dd-e63605b888aa"
  },
  {
    "hashId": "8b97606e-a849-4db8-ae9b-4009fe48d90b",
    "id": "556",
    "name": "Ross Playground",
    "lat": 42.2645666,
    "long": -71.1145523,
    "photos": [
      {
        "date": "2022-05-01",
        "photo": "photos/ross-playground_2022-05-01.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1646779520,
    "newId": "8b97606e-a849-4db8-ae9b-4009fe48d90b"
  },
  {
    "hashId": "dcd76e42-eebc-4432-9726-cdb3372b0b07",
    "id": "557",
    "name": "Galileo Galilei Way at Fulkerson St/Binney St",
    "lat": 42.3664204,
    "long": -71.0885418,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/galileo-galilei-way-at-fulkerson-st-binney-st_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1643338240,
    "newId": "dcd76e42-eebc-4432-9726-cdb3372b0b07"
  },
  {
    "hashId": "dd008e3c-ff17-4c82-abe0-502272a62425",
    "id": "558",
    "name": "St. Alphonsus St at Tremont St",
    "lat": 42.33329254719182,
    "long": -71.10124647617339,
    "discovered": 1650214155461,
    "firstSeen": 1645058202,
    "newId": "dd008e3c-ff17-4c82-abe0-502272a62425"
  },
  {
    "hashId": "c23a4c0d-7e43-4042-80e2-7c894d07763b",
    "id": "559",
    "name": "Centre St at Commonwealth Ave",
    "lat": 42.3362358,
    "long": -71.1934421,
    "discovered": 1650214155461,
    "firstSeen": 1647206229,
    "newId": "c23a4c0d-7e43-4042-80e2-7c894d07763b"
  },
  {
    "hashId": "ab6517ea-ed53-42eb-8881-657d362e56e8",
    "id": "560",
    "name": "Flat 9 at Whittier",
    "lat": 42.33420351352245,
    "long": -71.08767616843123,
    "discovered": 1650214155461,
    "firstSeen": 1646422417,
    "newId": "ab6517ea-ed53-42eb-8881-657d362e56e8"
  },
  {
    "hashId": "c1789d39-705f-4c03-9c14-4d6a3a4f4400",
    "id": "561",
    "name": "Pier 4 Blvd at Autumn Ln",
    "lat": 42.3502102,
    "long": -71.0446705,
    "photos": [
      {
        "date": "2022-06-20",
        "photo": "photos/pier-4-blvd-at-autumn-ln_2022-06-20.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1646285090,
    "newId": "c1789d39-705f-4c03-9c14-4d6a3a4f4400"
  },
  {
    "hashId": "f4a64c30-b97f-4ae5-b531-a11698b558d7",
    "id": "562",
    "name": "Hawthorne Boulevard",
    "lat": 42.5223714,
    "long": -70.8903331,
    "photos": [
      {
        "date": "2022-06-25",
        "photo": "photos/hawthorne-boulevard_2022-06-25.jpg"
      }
    ],
    "discovered": 1650214155461,
    "firstSeen": 1648672634,
    "newId": "f4a64c30-b97f-4ae5-b531-a11698b558d7"
  },
  {
    "hashId": "ca76adb6-4c84-4484-9ec3-b411b81ddbef",
    "id": "563",
    "name": "Addison St at Saratoga St",
    "lat": 42.38518146513944,
    "long": -71.0151367,
    "discovered": 1656091373729,
    "firstSeen": 1652576889,
    "newId": "ca76adb6-4c84-4484-9ec3-b411b81ddbef"
  },
  {
    "hashId": "ff938129-8179-455c-9ac9-9fcb9fc0b6d5",
    "id": "564",
    "name": "Raymond Park at Walden St",
    "photos": [
      {
        "date": "2023-04-08",
        "photo": "photos/raymond-park-at-walden-st_2023-04-08.jpg"
      }
    ],
    "lat": 42.38736953747185,
    "long": -71.12761001482795,
    "discovered": 1656091373729,
    "firstSeen": 1653628922,
    "newId": "ff938129-8179-455c-9ac9-9fcb9fc0b6d5"
  },
  {
    "hashId": "8a404180-7c2e-4dbe-9489-c478ed959282",
    "id": "565",
    "name": "Wellington MBTA",
    "lat": 42.4023682,
    "long": -71.07573182424781,
    "photos": [
      {
        "date": "2022-08-18",
        "photo": "photos/wellington-mbta_2022-08-18.jpg"
      }
    ],
    "discovered": 1656091373729,
    "newId": "8a404180-7c2e-4dbe-9489-c478ed959282"
  },
  {
    "hashId": "0208190e-851c-417d-8bc7-1b218db97fbf",
    "id": "566",
    "name": "Main Street/Albany Street/Technology Square",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/main-street-albany-street-technology-square_2023-01-29.jpg"
      }
    ],
    "lat": 42.3629016,
    "long": -71.0915669,
    "discovered": 1660920888601,
    "newId": "0208190e-851c-417d-8bc7-1b218db97fbf"
  },
  {
    "hashId": "9cc65d3d-cfcc-45ff-8f75-7264556b1cf9",
    "id": "567",
    "name": "Brighton Ave at Linden St",
    "lat": 42.3528376,
    "long": -71.1296752,
    "discovered": 1660920888601,
    "newId": "9cc65d3d-cfcc-45ff-8f75-7264556b1cf9"
  },
  {
    "hashId": "a9bc314b-a3fd-46e5-a63b-f005258bba5f",
    "id": "568",
    "name": "Shawmut Ave at Lenox St",
    "lat": 42.33584612997077,
    "long": -71.08038157224655,
    "discovered": 1660920888601,
    "newId": "a9bc314b-a3fd-46e5-a63b-f005258bba5f"
  },
  {
    "hashId": "e3f5d039-a49e-4b3b-ab4d-4efbef2068c6",
    "id": "569",
    "name": "Hood Park",
    "lat": 42.38004534693313,
    "long": -71.0730457305908,
    "photos": [
      {
        "date": "2022-08-18",
        "photo": "photos/hood-park_2022-08-18.jpg"
      }
    ],
    "discovered": 1660920888601,
    "newId": "e3f5d039-a49e-4b3b-ab4d-4efbef2068c6"
  },
  {
    "hashId": "12141b77-6e5f-444b-bace-2abf16db4fee",
    "id": "570",
    "name": "Salem State University - North Campus",
    "lat": 42.503332,
    "long": -70.891444,
    "discovered": 1676833927603,
    "newId": "12141b77-6e5f-444b-bace-2abf16db4fee"
  },
  {
    "hashId": "52485851-e554-49de-ab9f-f536806d2d34",
    "id": "571",
    "name": "Forest River Park",
    "lat": 42.506395,
    "long": -70.88693,
    "discovered": 1676833927603,
    "newId": "52485851-e554-49de-ab9f-f536806d2d34"
  },
  {
    "hashId": "54d86d05-b399-42c5-a63a-a97713afc5d4",
    "id": "572",
    "name": "Essex St at Dalton Parkway",
    "lat": 42.516223,
    "long": -70.905535,
    "discovered": 1676833927603,
    "newId": "54d86d05-b399-42c5-a63a-a97713afc5d4"
  },
  {
    "hashId": "6eaa7343-402d-41c9-9aac-8c664fdbdcf1",
    "id": "573",
    "name": "Salem MBTA - Lower Level",
    "lat": 42.523994,
    "long": -70.89684,
    "discovered": 1676833927603,
    "newId": "6eaa7343-402d-41c9-9aac-8c664fdbdcf1"
  },
  {
    "hashId": "13883905-f5c7-48a4-9344-4fbd75de70df",
    "id": "574",
    "name": "Community Life Center",
    "lat": 42.520136,
    "long": -70.906271,
    "discovered": 1676833927603,
    "newId": "13883905-f5c7-48a4-9344-4fbd75de70df"
  },
  {
    "hashId": "b44b7c3d-c054-4591-92a9-3b96d86050ab",
    "id": "575",
    "name": "Goodhue St at Grove St",
    "lat": 42.520897,
    "long": -70.910528,
    "discovered": 1676833927603,
    "newId": "b44b7c3d-c054-4591-92a9-3b96d86050ab"
  },
  {
    "hashId": "6cec3afa-554c-466f-8323-5c619fe0ddc6",
    "id": "576",
    "name": "Main St at Brooks Park",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/main-st-at-brooks-park_2023-01-29.jpg"
      }
    ],
    "lat": 42.411709,
    "long": -71.110444,
    "discovered": 1676833927603,
    "newId": "6cec3afa-554c-466f-8323-5c619fe0ddc6"
  },
  {
    "hashId": "6aebc664-1e3c-479f-8f65-2bae99215ed3",
    "id": "577",
    "name": "Medford Sq - Riverside Ave at River St",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/medford-sq-riverside-ave-at-river-st_2023-01-29.jpg"
      }
    ],
    "lat": 42.417724,
    "long": -71.108102,
    "discovered": 1676833927603,
    "newId": "6aebc664-1e3c-479f-8f65-2bae99215ed3"
  },
  {
    "hashId": "ee0fa23e-a6c2-474d-a6ef-bf581d63f1ae",
    "id": "578",
    "name": "Tufts Sq - Main St at Medford St",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/tufts-sq-main-st-at-medford-st_2023-01-29.jpg"
      }
    ],
    "lat": 42.401697,
    "long": -71.106128,
    "discovered": 1676833927603,
    "newId": "ee0fa23e-a6c2-474d-a6ef-bf581d63f1ae"
  },
  {
    "hashId": "42a3ecca-af9a-4556-90ba-7631546f96f8",
    "id": "579",
    "newId": "42a3ecca-af9a-4556-90ba-7631546f96f8",
    "name": "Harris Park",
    "photos": [
      {
        "date": "2023-08-27",
        "photo": "photos/harris-park_2023-08-27.jpg"
      }
    ],
    "lat": 42.41081837243052,
    "long": -71.08152866385353,
    "discovered": 1693098686141
  },
  {
    "hashId": "4d953f0f-13b5-4b3d-b2da-342003ff7cba",
    "id": "580",
    "newId": "4d953f0f-13b5-4b3d-b2da-342003ff7cba",
    "name": "Haines Square",
    "photos": [
      {
        "date": "2023-08-27",
        "photo": "photos/haines-square_2023-08-27.jpg"
      }
    ],
    "lat": 42.423331368452885,
    "long": -71.09124541293568,
    "discovered": 1693098686141
  },
  {
    "hashId": "f9524971-e9f9-4572-aebc-580e48de042f",
    "id": "582",
    "name": "Gore Street at Lambert Street",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/gore-street-at-lambert-street_2023-01-29.jpg"
      }
    ],
    "lat": 42.373079797934935,
    "long": -71.08634233665725,
    "discovered": 1676833927603,
    "newId": "f9524971-e9f9-4572-aebc-580e48de042f"
  },
  {
    "hashId": "2d32e355-faee-407b-9bb7-45d80ffb059a",
    "id": "583",
    "name": "Broad Canal Way at Third Street",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/broad-canal-way-at-third-street_2023-01-29.jpg"
      }
    ],
    "lat": 42.36304549626695,
    "long": -71.08287078916874,
    "discovered": 1676833927603,
    "newId": "2d32e355-faee-407b-9bb7-45d80ffb059a"
  },
  {
    "hashId": "f714390d-6874-43fa-a079-a07f83c79c7b",
    "id": "584",
    "name": "Malden Center T Station",
    "photos": [
      {
        "date": "2023-08-27",
        "photo": "photos/malden-center-t-station_2023-08-27.jpg"
      }
    ],
    "lat": 42.426541,
    "long": -71.073179,
    "discovered": 1676833927603,
    "newId": "f714390d-6874-43fa-a079-a07f83c79c7b"
  },
  {
    "hashId": "eee3aab6-bd98-4d1f-8887-9c4e2adce482",
    "id": "585",
    "name": "Northern Strand at Main St",
    "photos": [
      {
        "date": "2023-08-27",
        "photo": "photos/northern-strand-at-main-st_2023-08-27.jpg"
      }
    ],
    "lat": 42.42386,
    "long": -71.067793,
    "discovered": 1676833927603,
    "newId": "eee3aab6-bd98-4d1f-8887-9c4e2adce482"
  },
  {
    "hashId": "4f25f6ef-236b-43b4-8a92-68a24db0504b",
    "id": "586",
    "name": "Gramsdorf Playground",
    "lat": 42.408156,
    "long": -71.0429,
    "discovered": 1676833927603,
    "newId": "4f25f6ef-236b-43b4-8a92-68a24db0504b"
  },
  {
    "hashId": "e4678b17-face-42c3-9b5f-98418d31e3b6",
    "id": "587",
    "name": "Malden High School",
    "photos": [
      {
        "date": "2023-08-27",
        "photo": "photos/malden-high-school_2023-08-27.jpg"
      }
    ],
    "lat": 42.426537658440544,
    "long": -71.06393694877625,
    "discovered": 1676833927603,
    "newId": "e4678b17-face-42c3-9b5f-98418d31e3b6"
  },
  {
    "hashId": "13d4171b-6d93-490e-923f-f2a17d5c7613",
    "id": "588",
    "newId": "13d4171b-6d93-490e-923f-f2a17d5c7613",
    "name": "Bridge Street Neck",
    "lat": 42.52810051875824,
    "long": -70.88971138000488,
    "discovered": 1693098686141
  },
  {
    "hashId": "15ec5783-ca7a-4225-ba51-cd0a45ecc975",
    "id": "589",
    "name": "North St at Liberty Hill Ave",
    "lat": 42.52989964975891,
    "long": -70.90614259243011,
    "discovered": 1676833927603,
    "newId": "15ec5783-ca7a-4225-ba51-cd0a45ecc975"
  },
  {
    "hashId": "93911aa1-b5e4-48aa-a2b6-38fbcf613006",
    "id": "590",
    "name": "John Ahern Field at Kennedy-Longfellow School",
    "photos": [
      {
        "date": "2023-01-29",
        "photo": "photos/john-ahern-field-at-kennedy-longfellow-school_2023-01-29.jpg"
      }
    ],
    "lat": 42.36903566594827,
    "long": -71.08630959910442,
    "discovered": 1676833927603,
    "newId": "93911aa1-b5e4-48aa-a2b6-38fbcf613006"
  },
  {
    "hashId": "abefafdc-c772-4671-86fe-fc15a42adebb",
    "id": "592",
    "name": "87-101 Cambridgepark Drive",
    "photos": [
      {
        "date": "2023-04-08",
        "photo": "photos/87-101-cambridgepark-drive_2023-04-08.jpg"
      }
    ],
    "lat": 42.395221870310664,
    "long": -71.14421904097979,
    "discovered": 1676833927603,
    "newId": "abefafdc-c772-4671-86fe-fc15a42adebb"
  },
  {
    "hashId": "d81e16e2-a410-4a0b-b5ae-b72748fc9781",
    "id": "593",
    "name": "Boynton Yards at 101 South Street",
    "lat": 42.375447604963405,
    "long": -71.09117388747109,
    "discovered": 1676833927603,
    "newId": "d81e16e2-a410-4a0b-b5ae-b72748fc9781"
  },
  {
    "hashId": "4c128753-6fde-4d4c-896e-a1adf89ada98",
    "id": "594",
    "name": "Riverway at Brookline Ave",
    "lat": 42.33661005730433,
    "long": -71.11004680395126,
    "discovered": 1678554063763,
    "newId": "4c128753-6fde-4d4c-896e-a1adf89ada98"
  },
  {
    "hashId": "c1ae49bd-2a20-4684-886b-ead8dd627cfd",
    "id": "595",
    "newId": "c1ae49bd-2a20-4684-886b-ead8dd627cfd",
    "name": "Lenox St at Kendall St",
    "lat": 42.33759485033856,
    "long": -71.08255058528357,
    "discovered": 1693098686141
  },
  {
    "hashId": "910f1452-dbeb-4e69-b226-71b2d7e85715",
    "id": "596",
    "newId": "910f1452-dbeb-4e69-b226-71b2d7e85715",
    "name": "Hormel Stadium",
    "photos": [
      {
        "date": "2023-08-27",
        "photo": "photos/hormel-stadium_2023-08-27.jpg"
      }
    ],
    "lat": 42.40721644126376,
    "long": -71.09651505947113,
    "discovered": 1693098686141
  },
  {
    "hashId": "986068c4-d11a-465a-b8d0-bd0960c57f51",
    "id": "597",
    "newId": "986068c4-d11a-465a-b8d0-bd0960c57f51",
    "name": "Mass Ave/Lafayette Square",
    "lat": 42.36356498989399,
    "long": -71.10044184048091,
    "discovered": 1693098686141
  },
  {
    "hashId": "888780ee-bf2f-474e-9622-6bc725aa1b00",
    "id": "598",
    "newId": "888780ee-bf2f-474e-9622-6bc725aa1b00",
    "name": "Vinnin Square",
    "lat": 42.48628784971989,
    "long": -70.90166732668875,
    "discovered": 1693098686141
  },
  {
    "hashId": "a51b429f-bf06-425a-b3fe-b3155aa6468c",
    "id": "599",
    "newId": "a51b429f-bf06-425a-b3fe-b3155aa6468c",
    "name": "Derby Street",
    "lat": 42.520096183311445,
    "long": -70.8913904428482,
    "discovered": 1693098686141
  },
  {
    "hashId": "7e1dae55-4d3d-4a41-b969-2aeca70e0e69",
    "id": "600",
    "newId": "7e1dae55-4d3d-4a41-b969-2aeca70e0e69",
    "name": "Bremen St at Marion St",
    "lat": 42.37410622047334,
    "long": -71.03276252757496,
    "discovered": 1693098686141
  },
  {
    "hashId": "287aedf5-0361-47e9-9de0-14ae3b75cc0d",
    "id": "601",
    "newId": "287aedf5-0361-47e9-9de0-14ae3b75cc0d",
    "name": "Lewis Mall Wharf",
    "lat": 42.365678711213775,
    "long": -71.04030132293701,
    "discovered": 1693098686141
  },
  {
    "hashId": "9721c80e-fc8f-474f-8918-b68c041d2836",
    "id": "602",
    "newId": "9721c80e-fc8f-474f-8918-b68c041d2836",
    "name": "Morgan Ave at North First St",
    "lat": 42.37197725963389,
    "long": -71.07585847383234,
    "discovered": 1693098686141
  },
  {
    "hashId": "800bde2c-51df-497c-ac2d-bc3a8c00c164",
    "id": "603",
    "newId": "800bde2c-51df-497c-ac2d-bc3a8c00c164",
    "name": "Church St",
    "lat": 42.37433925370521,
    "long": -71.11966431146357,
    "discovered": 1693098686141
  }
];
