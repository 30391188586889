const INF = 10000;

/*
 * Given three collinear points p, q, r,
 * the function checks if point q lies
 * on line segment 'pr'
 */
const onSegment = (p, q, r) => {
  if (q.x <= Math.max(p.x, r.x) &&
    q.x >= Math.min(p.x, r.x) &&
    q.y <= Math.max(p.y, r.y) &&
    q.y >= Math.min(p.y, r.y)) {
    return true;
  }

  return false;
};

/* 
 * To find orientation of ordered triplet (p, q, r).
 * The function returns following values
 * 0 --> p, q and r are collinear
 * 1 --> Clockwise
 * 2 --> Counterclockwise
 */
const orientation = (p, q, r) => {
  let val = (q.y - p.y) * (r.x - q.x)
    - (q.x - p.x) * (r.y - q.y);

  if (val == 0) {
    return 0; // collinear
  }

  return (val > 0) ? 1 : 2; // clock or counterclock wise
};

/*
 * Returns true if line segment 'p1q1' and 'p2q2' intersect.
 */
const doIntersect = (p1, q1, p2, q2) => {
  // Find the four orientations needed for
  // general and special cases
  let o1 = orientation(p1, q1, p2);
  let o2 = orientation(p1, q1, q2);
  let o3 = orientation(p2, q2, p1);
  let o4 = orientation(p2, q2, q1);

  // General case
  if (o1 != o2 && o3 != o4) {
    return true;
  }

  // Special Cases
  // p1, q1 and p2 are collinear and
  // p2 lies on segment p1q1
  if (o1 == 0 && onSegment(p1, p2, q1)) {
    return true;
  }

  // p1, q1 and p2 are collinear and
  // q2 lies on segment p1q1
  if (o2 == 0 && onSegment(p1, q2, q1)) {
    return true;
  }

  // p2, q2 and p1 are collinear and
  // p1 lies on segment p2q2
  if (o3 == 0 && onSegment(p2, p1, q2)) {
    return true;
  }

  // p2, q2 and q1 are collinear and
  // q1 lies on segment p2q2
  if (o4 == 0 && onSegment(p2, q1, q2)) {
    return true;
  }

  // Doesn't fall in any of the above cases
  return false;
};

/*
 * Returns true if the point p lies inside the polygon[] with n vertices
 */
const isInside = (polygon, n, p) => {
  // There must be at least 3 vertices in polygon[]
  if (n < 3) {
    return false;
  }

  // Create a point for line segment from p to infinite
  let extreme = {
    x: INF,
    y: p.y
  };

  // Count intersections of the above line
  // with sides of polygon
  let count = 0, i = 0;
  do {
    let next = (i + 1) % n;

    // Check if the line segment from 'p' to
    // 'extreme' intersects with the line
    // segment from 'polygon[i]' to 'polygon[next]'
    if (doIntersect(polygon[i], polygon[next], p, extreme)) {
      // If the point 'p' is collinear with line
      // segment 'i-next', then check if it lies
      // on segment. If it lies, return true, otherwise false
      if (orientation(polygon[i], p, polygon[next]) == 0) {
        return onSegment(polygon[i], p,
          polygon[next]);
      }

      count++;
    }
    i = next;
  } while (i != 0);

  // Return true if count is odd, false otherwise
  return (count % 2 == 1); // Same as (count%2 == 1)
};

export const runNeighborhoodCalculations = (stations, neighborhoods, visitedStations, trips) => {

  // figure out which neighborhood each station is in
  const newStations = stations.map(station => {
    const stationLocation = {
      x: station.long,
      y: station.lat
    };

    const containingNeighborhood = neighborhoods.find(neighborhood => {
      return isInside(neighborhood.polygon, neighborhood.polygon.length, stationLocation);
    });

    return {
      ...station,
      neighborhoodId: containingNeighborhood ? containingNeighborhood.id : null
    };
  });

  // add station and photo data to neighborhoods
  const newNeighborhoods = neighborhoods.map(neighborhood => {
    const stationsInNeighborhood = [];
    let numVisitedStationsInNeighborhood = 0;
    let numUnvisitedStationsInNeighborhood = 0;
    let numViableStationsInNeighborhood = 0;

    // count the stations in the neighborhood
    const numStationsInNeighborhood = newStations.reduce((result, station) => {
      const isStationInNeighborhood = station.neighborhoodId === neighborhood.id;

      if (isStationInNeighborhood) {
        stationsInNeighborhood.push(station.id);

        if (visitedStations[station.id]) {
          numVisitedStationsInNeighborhood++;
        }
        if (!visitedStations[station.id] && !station.isInactive) {
          numUnvisitedStationsInNeighborhood++;
        }
        if (visitedStations[station.id] || !station.isInactive) {
          numViableStationsInNeighborhood++;
        }
      };

      return result + (isStationInNeighborhood ? 1 : 0);
    }, 0);

    return {
      ...neighborhood,
      numStations: numStationsInNeighborhood,
      numUnviableStations: numStationsInNeighborhood - numViableStationsInNeighborhood,
      numUnvisitedStations: numUnvisitedStationsInNeighborhood,
      numVisitedStations: numVisitedStationsInNeighborhood,
      progress: numStationsInNeighborhood ? Math.round((numVisitedStationsInNeighborhood / numViableStationsInNeighborhood) * 100) : 0,
      stationIds: stationsInNeighborhood
    };
  });

  const newNeighborhoodsWithPhotos = updateNeighborhoodDataWithTripPhotos(newNeighborhoods, trips);

  return {
    stations: newStations,
    neighborhoods: newNeighborhoodsWithPhotos
  };
};

export const updateNeighborhoodDataWithTripPhotos = (neighborhoods, trips) => {
  return neighborhoods.map(neighborhood => {
    const photosInNeighborhood = [];

    // compile the photos taken in each neighborhood
    trips.forEach(trip => {
      if (trip.photos) {
        trip.photos.forEach(photo => {
          const photoLocation = {
            x: photo.lng,
            y: photo.lat
          };

          const isPhotoInNeighborhood = isInside(neighborhood.polygon, neighborhood.polygon.length, photoLocation);

          if (isPhotoInNeighborhood) {
            photosInNeighborhood.push(photo);
          }
        });
      }
    });

    photosInNeighborhood.sort((a, b) => -a.date.localeCompare(b.date));

    return {
      ...neighborhood,
      numPhotos: photosInNeighborhood.length,
      photos: photosInNeighborhood
    };
  });
};
