import React from 'react';
import './AreaDetail.css';

const AreaDetail = ({
  areStationsVisitedNew,
  isLongTitle,
  isStatusSubtitle,
  numBikes,
  numDocks,
  numStationsRemaining,
  numStationsTotal,
  numStationsUnviable,
  numStationsVisited,
  onSelectPhoto,
  photos,
  primaryPhoto,
  statistics,
  subtitle,
  title,
}) => {
  return (
    <div className="AreaDetail">
      {primaryPhoto != null && (
        <div className="AreaDetail-primaryPhoto" style={{ backgroundImage: `url(${primaryPhoto})` }} />
      )}
      <div className="AreaDetail-details">
        <h4 className={`AreaDetail-title${isLongTitle ? ' AreaDetail-title--long' : ''}`}>
          {title}
        </h4>
        {!!subtitle && (
          <p className={`AreaDetail-subtitle${isStatusSubtitle ? ' AreaDetail-subtitle--status' : ''}`}>
            {subtitle}
          </p>
        )}
        <div className="AreaDetail-statistics">
          {numStationsRemaining != null && numStationsTotal != null && (
            <div className="AreaDetail-statistic">
              <span className="AreaDetail-statisticUnvisited">
                {` ${numStationsRemaining} ${numStationsRemaining === 1 ? 'station' : 'stations'} remaining`}
              </span>
              {` / ${numStationsTotal} total`}
            </div>
          )}
          {numStationsRemaining == null && numStationsVisited != null && numStationsTotal != null && (
            <div className="AreaDetail-statistic">
              <span className="AreaDetail-statisticVisited">
                {areStationsVisitedNew
                  ? ` ${numStationsVisited} new ${numStationsVisited === 1 ? 'station' : 'stations'}`
                  : ` ${numStationsVisited} ${numStationsVisited === 1 ? 'station' : 'stations'} visited`
                }
              </span>
              {!!numStationsUnviable && (
                <span className="AreaDetail-statisticInactive">
                  {` / ${numStationsUnviable} inactive`}
                </span>
              )}
              {` / ${numStationsTotal} total`}
            </div>
          )}
          {!!statistics && statistics.length > 0 && statistics.map(statistic => (
            <div className="AreaDetail-statistic" key={statistic}>
              {statistic}
            </div>
          ))}
        </div>
        {numBikes != null && numDocks != null && (
          <div className="AreaDetail-status">
            <p className="AreaDetail-statusItem">
              <span className="AreaDetail-statusNumber">{numBikes}</span> {numBikes === 1 ? 'Bike' : 'Bikes'}
            </p>
            <p className="AreaDetail-statusItem">
              <span className="AreaDetail-statusNumber">{numDocks}</span> {numDocks === 1 ? 'Dock' : 'Docks'}
            </p>
          </div>
        )}
      </div>
      {!!photos && photos.length > 0 && (
        <div className="AreaDetail-photos">
          {photos.map(photo => (
            <button
              className="AreaDetail-photo"
              key={photo.thumb}
              onClick={() => onSelectPhoto(photo)}
              style={{ backgroundImage: `url(${photo.thumb})` }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AreaDetail;
