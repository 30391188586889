import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

const BackButton = ({label = 'Back', onClick}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<ArrowBack />}
    >
      {label}
    </Button>
  );
};

export default BackButton;
