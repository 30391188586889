import React from 'react';
import Button from '@mui/material/Button';
import Equalizer from '@mui/icons-material/Equalizer';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Room from '@mui/icons-material/Room';
import './NeighborhoodButton.css';

const NeighborhoodButton = React.forwardRef((
  {
    description,
    hideStations,
    name,
    numStations,
    numUnvisitedStations,
    numVisitedStations,
    onClick,
    photos,
    progress
  },
  ref
) => {
  const numPhotos = photos && photos.length;
  return (
    <Button
      className="NeighborhoodButton"
      onClick={onClick}
      ref={ref}
      size="medium"
    >
      <span className="NeighborhoodButton-name">
        {name}
      </span>
      {!!description && <span className="NeighborhoodButton-description">{description}</span>}
      <span className="NeighborhoodButton-stats">
        {!hideStations && (
          <span className="NeighborhoodButton-stat NeighborhoodButton-statStations">
            <Room />
            {progress !== 100 ? (
              <>
                <span className="NeighborhoodButton-stationsRemaining">
                  {`${numUnvisitedStations} ${numUnvisitedStations === 1 ? 'station' : 'stations'} remaining`}
                </span>
                {` / ${numStations} total`}
              </>
            ) : (
              <span className="NeighborhoodButton-complete">
                {`${numVisitedStations} ${numVisitedStations === 1 ? 'station' : 'stations'} visited`}
              </span>
            )}
          </span>
        )}
        <span className="NeighborhoodButton-stat NeighborhoodButton-statProgress">
          <Equalizer />
          {`${progress}% complete`}
        </span>
        {!!numPhotos && (
          <span className="NeighborhoodButton-stat NeighborhoodButton-statPhotos">
            <PhotoCamera />
            {numPhotos === 1 ? ` ${numPhotos} photo` : ` ${numPhotos} photos`}
          </span>
        )}
      </span>
    </Button>
  );
});

export default NeighborhoodButton;
