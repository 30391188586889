import React from 'react';
import moment from 'moment';

import AreaDetail from './AreaDetail';

const StationInfo = ({ station, trips }) => {
  const showBikeDockInfo = !station.isInactive && (typeof station.bikesAvailable != 'undefined' || typeof station.docksAvailable != 'undefined');
  const photo = station.photos && station.photos.length > 0 ? station.photos[station.photos.length - 1].photo : station.photo;

  const subtitle = station.isLegacy
    ? 'permanently removed'
    : station.isInactive
      ? 'inactive'
      : null;

  const statistics = (station.firstSeen || station.discovered)
    ? [
      `est. ${moment(station.firstSeen ? station.firstSeen * 1000 : station.discovered).format('MMM YYYY')}`
    ]
    : null;

  // make it easy to see id of stations for trip marking
  // console.log(`${station.name} (${station.id})`);

  return (
    <AreaDetail
      isLongTitle
      isStatusSubtitle
      numBikes={showBikeDockInfo ? station.bikesAvailable : null}
      numDocks={showBikeDockInfo ? station.docksAvailable : null}
      primaryPhoto={photo}
      subtitle={subtitle}
      statistics={statistics}
      title={station.name}
    />
  );
};

export default StationInfo;
