import React from 'react';
import Button from '@mui/material/Button';

const CancelButton = ({label = 'Cancel', onClick}) => {
  return (
    <Button
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default CancelButton;
